import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import UiContext from "../store/ui-context";
import useAPIRequest from "../custom_hooks/simple/useAPIRequest";
import calculateUnpayedAmount from "../utils/jelim/CalculateUnpayedAmount";

const title = "Payment";
const apiName = "masterdata/payment";

const PaymentDialog = (props) => {
  const { onConfirm, data } = props;
  const single = props.single ?? true;
  const uiContext = useContext(UiContext);

  const [number, setNumber] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [first, setFirst] = useState(false);
  const [listData, setListData] = useState([]);

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  useEffect(() => {
    if (props.visible) {
      const unpayed = calculateUnpayedAmount(data);
      setPaymentMethod(null);
      setNumber(unpayed);
      setFirst(true);
      reloadListData();
    }
  }, [props.visible]);

  const reloadListData = async () => {
    if (loading) return;

    await requestGet({
      apiUrl: `data`,
      params: {
        page: 1,
        take: 100,
        filter: "",
        order: "name",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {
        setListData(data);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });

    return;
  };

  const handleConfirmPayment = (event) => {
    event.preventDefault();

    if (isNaN(number)) {
      uiContext.showErrorMsg("Error", "Invalid amount");
      return;
    }

    if (!paymentMethod) {
      uiContext.showErrorMsg("Error", "No payment method selected");
      return;
    }

    let newNumber = number;
    if (paymentMethod.amount > 0) {
      const unpayed = calculateUnpayedAmount(data);
      newNumber = paymentMethod.amount;

      if (newNumber > unpayed) {
        uiContext.showErrorMsg(
          "Error",
          "Payment amount exceeds unpayed amount"
        );
        return;
      }
    }

    onConfirm({ payment: paymentMethod, amount: newNumber });

    props.onHide();
  };

  const handleChangePaymentMethod = (event) => {
    const paymentMethod = event.value;

    setPaymentMethod(paymentMethod);

    if (paymentMethod.amount > 0) {
      setNumber(paymentMethod.amount);
    }
  };

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] min-h-[33rem]`}
      // footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="col-start-center w-full gap-3">
        <div className="col-start-center w-full gap-2 pt-1">
          <InputText
            readOnly
            className="text-center w-100 min-h-[4rem] text-4xl"
            value={number.toLocaleString("id-ID")}
            onChange={(e) => setNumber(e.target.value)}
          />
          <Dropdown
            className="w-full text-center"
            options={listData}
            // placeholder="Select Payment Method"
            optionLabel="name"
            value={paymentMethod}
            onChange={handleChangePaymentMethod}
          />
        </div>
        <div className="col-start-center w-full gap-1 py-4">
          <div className="row-center-center w-full gap-1">
            <Button
              label="1"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(1);
                  setFirst(false);
                } else setNumber(number * 10 + 1);
              }}
            />
            <Button
              label="2"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(2);
                  setFirst(false);
                } else setNumber(number * 10 + 2);
              }}
            />
            <Button
              label="3"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(3);
                  setFirst(false);
                } else setNumber(number * 10 + 3);
              }}
            />
          </div>
          <div className="row-center-center w-full gap-1">
            <Button
              label="4"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(4);
                  setFirst(false);
                } else setNumber(number * 10 + 4);
              }}
            />
            <Button
              label="5"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(5);
                  setFirst(false);
                } else setNumber(number * 10 + 5);
              }}
            />
            <Button
              label="6"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(6);
                  setFirst(false);
                } else setNumber(number * 10 + 6);
              }}
            />
          </div>
          <div className="row-center-center w-full gap-1">
            <Button
              label="7"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(7);
                  setFirst(false);
                } else setNumber(number * 10 + 7);
              }}
            />
            <Button
              label="8"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(8);
                  setFirst(false);
                } else setNumber(number * 10 + 8);
              }}
            />
            <Button
              label="9"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(9);
                  setFirst(false);
                } else setNumber(number * 10 + 9);
              }}
            />
          </div>
          <div className="row-center-center w-full gap-1">
            <Button
              label="0"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(0);
                  setFirst(false);
                } else setNumber(number * 10);
              }}
            />
            <Button
              label="000"
              className="w-full min-h-[3rem]"
              text
              pt={{ label: { className: "!text-lg" } }}
              onClick={(event) => {
                event.preventDefault();
                if (first) {
                  setNumber(0);
                  setFirst(false);
                } else setNumber(number * 1000);
              }}
            />
            <Button
              label="CLEAR"
              className="w-full min-h-[3rem] bg-red-100"
              text
              pt={{ label: { className: "!text-md" } }}
              onClick={(event) => {
                event.preventDefault();
                setNumber(0);
              }}
            />
          </div>
        </div>
        <Button
          label="Confirm Payment"
          className="w-100 min-h-[3.2rem]"
          onClick={handleConfirmPayment}
        />
      </div>
    </Dialog>
  );
};

export default PaymentDialog;
