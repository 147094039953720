import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isToday,
  startOfMonth,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import EventCalendarItem from "./EventCalendarItem";
import { Button } from "primereact/button";
import EventCalendarHeaderItem from "./EventCalendarHeaderItem";
import EventCalendarEmptyItem from "./EventCalendarEmptyItem";
import { ProgressSpinner } from "primereact/progressspinner";

const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const EventCalendar = (props) => {
  const { events, onClick, onChangeMonth, loading, selectedMonth, title } =
    props;

  const [currentMonth, setCurrentMonth] = useState(selectedMonth);
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);

  useEffect(() => {
    if (!onChangeMonth) {
      setCurrentMonth(selectedMonth);
    }
  }, [selectedMonth]);

  const handleClickPreviousMonth = () => {
    const newDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() - 1)
    );
    setCurrentMonth(newDate);
    if (onChangeMonth) onChangeMonth(newDate);
  };

  const handleClickNextMonth = () => {
    const newDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() + 1)
    );
    setCurrentMonth(newDate);
    if (onChangeMonth) onChangeMonth(newDate);
  };

  const handleClickRefresh = () => {
    if (onChangeMonth) onChangeMonth(currentMonth);
  };

  const daysInMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  });

  const eventsByDate = useMemo(() => {
    return events.reduce((acc, event) => {
      const dateKey = format(event.date, "yyyy-MM-dd");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {});
  }, [events]);

  const startingDayIndex = getDay(firstDayOfMonth);

  let rows = [[], [], [], [], [], []];
  let currentRow = 0;

  // empty box (at start of month)
  for (let i = 0; i < startingDayIndex; i++) {
    rows[currentRow].push(<EventCalendarEmptyItem key={`empty-start-${i}`} />);
  }

  // daily box
  daysInMonth.map((day, index) => {
    const dateKey = format(day, "yyyy-MM-dd");
    const todaysEvents = eventsByDate[dateKey] || [];

    if (rows[currentRow].length === 7) {
      currentRow++;
    }

    // console.log("ROWS", rows);
    // console.log("CURRENT ROW", currentRow);

    rows[currentRow].push(
      <EventCalendarItem
        key={index}
        day={day}
        events={todaysEvents}
        onClick={onClick}
      />
    );
  });

  // empty box (at end of month)
  if (rows[currentRow].length < 7) {
    for (let i = rows[currentRow].length; i < 7; i++) {
      rows[currentRow].push(<EventCalendarEmptyItem key={`empty-end-${i}`} />);
    }
  }

  // remove rows that only contain empty boxes
  rows = rows.filter((row) => {
    return row.some((item) => {
      return item.type.name !== "EventCalendarEmptyItem";
    });
  });

  return (
    <div className="col-center-center w-full relative">
      <div className="col-start-start w-full">
        {/* header to select month */}
        <div className="row-start-center w-full gap-0 pb-2">
          {title && (
            <div className="pr-4">
              <span className="font-semibold text-sm text-slate-600">
                {title}
              </span>
            </div>
          )}
          {onChangeMonth && (
            <div className="row-start-center">
              <Button
                className="max-w-[2rem] max-h-[2rem]"
                icon="pi pi-refresh"
                rounded
                text
                onClick={handleClickRefresh}
              />
              <Button
                className="max-w-[2rem] max-h-[2rem]"
                icon="pi pi-chevron-left"
                rounded
                text
                onClick={handleClickPreviousMonth}
              />
              <div className="row-center-center">
                <Button
                  className="max-h-[2rem] w-[10rem] !text-slate-600"
                  label={format(currentMonth, "MMMM yyyy")}
                  text
                />
              </div>
              <Button
                className="max-w-[2rem] max-h-[2rem]"
                icon="pi pi-chevron-right"
                rounded
                text
                onClick={handleClickNextMonth}
              />
            </div>
          )}
          {!onChangeMonth && (
            <span className="font-semibold text-sm text-slate-600">
              ({format(currentMonth, "MMMM yyyy")})
            </span>
          )}
        </div>
        <div className="col-start-start w-full">
          <div className="row-start-center w-full">
            {WEEKDAYS.map((day, index) => {
              return (
                <EventCalendarHeaderItem key={day} day={day} index={index} />
              );
            })}
          </div>

          {rows.length >= 1 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[0]}
            </div>
          )}
          {rows.length >= 2 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[1]}
            </div>
          )}
          {rows.length >= 3 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[2]}
            </div>
          )}
          {rows.length >= 4 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[3]}
            </div>
          )}
          {rows.length >= 5 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[4]}
            </div>
          )}
          {rows.length >= 6 && (
            <div className="row-start-start w-full min-h-[5rem] !items-stretch">
              {rows[5]}
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="col-center-center absolute bg-slate-100/50 w-full h-full rounded">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};

export default EventCalendar;
