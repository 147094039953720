import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";

const ReportSectionSingle = ({
  title,
  description,
  url,
  class_color,
  on_click,
}) => {
  const navigate = useNavigate();

  const handleClickBukaReport = (event) => {
    event.preventDefault();
    if (on_click) {
      on_click(event);
    } else {
      navigate(url);
    }
  };

  return (
    <div className="col-start-start w-full">
      <Divider className="m-0" />
      <div className="col-start-start w-full py-3">
        <span className="font-semibold">{title}</span>
        {description && (
          <span className="text-sm text-slate-400 max-w-[27rem]">
            {description}
          </span>
        )}
        <Button
          className={`max-h-[2rem] !pl-0 ${class_color ?? ""}`}
          text
          label="Open Report"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={handleClickBukaReport}
        />
      </div>
    </div>
  );
};

export default ReportSectionSingle;
