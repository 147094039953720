import { format as dateFnsFormat, parse } from "date-fns";

const dateTextReformat = (mysqlDate, format) => {
  if (!mysqlDate) return "";
  let refinedDateString = mysqlDate;
  if (refinedDateString.length === 10) {
    refinedDateString += " 00:00:00";
  } else if (refinedDateString.length === 16) {
    refinedDateString += ":00";
  }
  const parsedDate = parse(
    refinedDateString,
    "yyyy-MM-dd HH:mm:ss",
    new Date()
  );
  return dateFnsFormat(parsedDate, format ?? "dd/MM/yyyy");
};

export default dateTextReformat;
