import classes from "./CRMProductEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";
// add_import_selector_dialog
import CRMProductCategorySelectorDialog from "../../../../dialogs/selectors/CRMProductCategorySelectorDialog";

import configs from "./CRMProductConfigs";

const title = configs.dialog.title;

const CRMProductEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    const newValue = { ...props.value };
    if (newValue.data_crm_product_galleries) {
      const newArray = newValue.data_crm_product_galleries.map((element) => {
        const object = { ...element };
        if (object.id <= 0) delete object.id;
        return object;
      });
      newValue.data_crm_product_galleries = newArray;
    }

    return { ...newValue };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "crm_product_category_id ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "crm_product_category_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <CRMProductCategorySelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      breakpoints={{ "2024px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid max-w-[25rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} row-start-start w-100`}>
          <div className="col-start-start w-100 gap-3">
            {/* {elements.example_fields} */}
            {/* add_fields */}
            {elements.crm_product_category_id}
            {elements.show_on_promotions}
            {elements.name}
            {elements.description}
            {elements.image}
            {elements.youtube}
          </div>
          {/* <Divider style={{ height: "29rem" }} layout="vertical" />
          <div className="col-start-start w-100 gap-3">
            {elements.data_crm_product_galleries}
          </div> */}
          <div className="col-start-start"></div>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CRMProductEditorDialog;
