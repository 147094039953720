import assignDataToMap from "../../utils/AssignDataToMap";
import generateEditorElement from "../../utils/GenerateEditorElement";
import validatorUtil from "../../utils/InputValidator";

const useCrudEditorControl = ({
  value,
  setValue,
  error,
  configs,
  customElements,
}) => {
  const handleInputChange = (field, newValue) => {
    const newMap = { ...value };
    if (!field.includes(".")) {
      newMap[field] = newValue;
    } else {
      assignDataToMap({ key: field, map: newMap, value: newValue });
    }
    setValue(newMap);
  };

  const handleMultipleInputChange = (mapsNewValue) => {
    const newMap = { ...value };
    for (const mapNewValue of mapsNewValue) {
      newMap[mapNewValue.field] = mapNewValue.newValue;
    }
    setValue(newMap);
  };

  const generateSelectorDialog = ({ field, componentFunction }) => {
    let fieldArr = [];
    if (field.includes(" ")) {
      fieldArr = field.split(" ");
    } else if (field.includes(",")) {
      fieldArr = field.split(",");
    } else {
      fieldArr.push(field);
    }
    for (const fieldSingle of fieldArr) {
      const config = configs.find((element) => element.field === fieldSingle);
      if (config) {
        config.selectorGenerator = componentFunction;
      }
    }
  };

  const validator = ({
    configs,
    value,
    onError,
    isEmpty,
    isEmptyMessage,
    mustEqual,
    mustEqualMessage,
  }) => {
    return validatorUtil({
      configs,
      value,
      onError,
      isEmpty,
      isEmptyMessage,
      mustEqual,
      mustEqualMessage,
    });
  };

  //auto generate element
  const defaultProps = {
    value: value,
    error: error,
    handleInputChange: handleInputChange,
    handleMultipleInputChange: handleMultipleInputChange,
  };

  const mapGeneratedElement = {};
  let firstElement = true;
  for (const config of configs) {
    let foundEl;
    for (const custEl of customElements) {
      const { field, element } = custEl;
      if (config.field === field) {
        foundEl = element;
      }
    }
    if (foundEl) {
      mapGeneratedElement[config.field] = foundEl({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
    } else {
      const generatedElement = generateEditorElement({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
      if (generatedElement) {
        mapGeneratedElement[config.field] = generatedElement;
      }
    }
    if (firstElement) {
      firstElement = false;
    }
  }

  return {
    // methods
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    // UIs
    elements: mapGeneratedElement,
    // utils
    validator,
  };
};

export default useCrudEditorControl;
