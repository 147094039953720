import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    sideMenuIsVisible: true,
    layoutDashboardTitle: "",
  },
  reducers: {
    toggleSideMenuVisibility(state) {
      state.sideMenuIsVisible = !state.sideMenuIsVisible;
    },
    setLayoutDashboardTitle(state, action) {
      state.layoutDashboardTitle = action.payload.title;
    },
  },
});

const uiActions = uiSlice.actions;

const toggleSideMenuVisibility = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleSideMenuVisibility());
  };
};

const setLayoutDashboardTitle = ({ title }) => {
  return (dispatch) => {
    dispatch(uiActions.setLayoutDashboardTitle({ title }));
  };
};

export { uiActions, toggleSideMenuVisibility, setLayoutDashboardTitle };

export default uiSlice;
