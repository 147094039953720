import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import { useContext, useEffect, useState } from "react";
import UiContext from "../../../../store/ui-context";
import RegistrationRewardSetting from "./RegistrationRewardSetting";
import { ConfirmDialog } from "primereact/confirmdialog";
import BirthdayRewardSetting from "./BirthdayRewardSetting";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { useDispatch } from "react-redux";
import GenerateCustomerFollowUpSetting from "./GenerateCustomerFollowUpSetting";

const title = "Application Setting";

const SettingPage = () => {
  const dispatch = useDispatch();
  const uiContext = useContext(UiContext);
  const [registerRewardSetting, setRegisterRewardSetting] = useState();
  const [birthdayRewardSetting, setBirthdayRewardSetting] = useState();

  const { loading, requestGet, requestPost } = useAPIRequest();

  const loadSetting = async () => {
    await requestGet({
      fullUrl: `/api/setting/data`,
      params: {
        filter: "id:>:0",
        page: 1,
        take: 100,
        order: "label",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {
        for (const setting of data) {
          if (setting.label === "registration_reward") {
            setRegisterRewardSetting(setting);
          } else if (setting.label === "birthday_reward") {
            setBirthdayRewardSetting(setting);
          }
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    loadSetting();
  }, []);

  return (
    <section className="row-start-start w-100 wrap p-4 gap-4">
      <ConfirmDialog
        pt={{
          acceptButton: { className: "p-button-danger" },
        }}
      />

      <div className="elevated-card-tight-vertical col-start-start gap-6 w-full !px-4">
        <GenerateCustomerFollowUpSetting />
        <RegistrationRewardSetting
          setting={registerRewardSetting}
          setSetting={setRegisterRewardSetting}
        />
        <BirthdayRewardSetting
          setting={birthdayRewardSetting}
          setSetting={setBirthdayRewardSetting}
        />
      </div>
    </section>
  );
};
export default SettingPage;
