import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FaUser, FaUserDoctor, FaUserNurse } from "react-icons/fa6";

const ListSalesDetailSingle = (props) => {
  const {
    loading,
    data_product = {},
    id = -1,
    quantity = 0,
    price = 0,
    sub_total = 0,
    grand_total = 0,
    data_doctor = undefined,
    data_therapist_1 = undefined,
    data_therapist_2 = undefined,
    data_nurse_1 = undefined,
    data_nurse_2 = undefined,
    discount_value = 0,
    discount_name = "",
    discount_total = 0,
    onClickDiscount,
    onClickDelete,
    onChangeQuantity,
    onClickDoctor,
    onClickNurse,
    onClickTherapist,
    editable = true,
  } = props;

  return (
    <div
      className={`col-end-center group relative w-full ${
        props.className ?? ""
      } `}
    >
      <div className="col-start-start w-full bg-slate-100 rounded-md px-2 py-2">
        <div className="row-between-start gap-2 w-full">
          <span className="leading-tight">{data_product.name}</span>
        </div>
        {(data_doctor ||
          data_nurse_1 ||
          data_nurse_2 ||
          data_therapist_1 ||
          data_therapist_2) && (
          <div className="row-start-center gap-1 py-2 flex-wrap">
            {/* Doctor section */}
            {data_doctor && (
              <div className="row-start-center gap-2 pr-2">
                <FaUserDoctor className="text-slate-500 text-sm" />
                <span className="text-xs text-slate-500 pt-1">
                  {data_doctor ? data_doctor.name : "-"}
                </span>
              </div>
            )}
            {/* Nurse section */}
            {(data_nurse_1 || data_nurse_2) && (
              <div className="row-start-center gap-2 pr-2">
                <FaUserNurse className="text-slate-500 text-sm" />
                <span className="text-xs text-slate-500  pt-1">
                  {data_nurse_1.name}
                </span>
                {data_nurse_2 && (
                  <span className="text-xs text-slate-500 pt-1">
                    {data_nurse_2.name}
                  </span>
                )}
              </div>
            )}
            {/* Therapist section */}
            {(data_therapist_1 || data_therapist_2) && (
              <div className="row-start-center gap-2">
                <FaUser className="text-slate-500 text-sm" />
                <span className="text-xs text-slate-500 pt-1">
                  {data_therapist_1.name}
                </span>
                {data_therapist_2 && (
                  <span className="text-xs text-slate-500 pt-1">
                    {data_therapist_2.name}
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        <div className="row-between-center gap-2 w-full">
          <span className="text-sm">{`${quantity.toLocaleString(
            "id-ID"
          )} x @ ${price.toLocaleString("id-ID")}`}</span>
          <span className="font-semibold">
            {sub_total.toLocaleString("id-ID")}
          </span>
        </div>
        {discount_value != 0 && discount_total != 0 && discount_name != "" && (
          <div className="row-between-center gap-2 w-full">
            <span className="text-sm font-semibold text-red-500">{`${discount_name}`}</span>
            <span className=" font-semibold text-red-500">
              {`(${discount_total.toLocaleString("id-ID")})`}
            </span>
          </div>
        )}
      </div>
      {/* <div className="absolute hidden group-hover:block group-hover:row-center-center pb-1"> */}
      {/* <div className="absolute group-hover:opacity-100 transition-opacity ease-in-out duration-300 group-hover:row-center-center pb-1"> */}
      {editable && (
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 group-hover:row-center-center pb-1">
          <div className="row-center-center bg-white py-1 px-1 rounded-full shadow gap-5">
            <div className="row-center-center gap-1">
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem]"
                rounded
                outlined
                icon="pi pi-minus"
                onClick={(e) => {
                  onChangeQuantity(quantity - 1 < 0 ? quantity : quantity - 1);
                }}
              />
              <InputText
                disabled={loading}
                className="max-w-[3rem] max-h-[2rem] text-center"
                value={quantity}
              />
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem]"
                rounded
                outlined
                icon="pi pi-plus"
                onClick={(e) => {
                  onChangeQuantity(quantity + 1);
                }}
              />
            </div>
            <div className="row-center-center gap-1">
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem]"
                rounded
                outlined
                severity={
                  discount_total === 0 && discount_value === 0
                    ? "primary"
                    : "danger"
                }
                icon="pi pi-tag"
                onClick={(e) => {
                  e.preventDefault();
                  onClickDiscount(props);
                }}
              />
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem] !p-[0.44rem]"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  onClickDoctor(props);
                }}
              >
                <FaUserDoctor className="min-w-[1rem]" />
              </Button>
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem] !p-[0.44rem]"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  onClickNurse(props);
                }}
              >
                <FaUserNurse className="min-w-[1rem]" />
              </Button>
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem] !p-[0.44rem]"
                rounded
                outlined
                severity="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  onClickTherapist(props);
                }}
              >
                <FaUser className="min-w-[1rem]" />
              </Button>
              <Button
                loading={loading}
                className="max-h-[2rem] max-w-[2rem]"
                rounded
                outlined
                severity="danger"
                icon="pi pi-trash"
                onClick={(e) => {
                  e.preventDefault();
                  onClickDelete(props);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListSalesDetailSingle;
