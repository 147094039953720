import { useEffect, useRef } from "react";
import classes from "./ZirvuFileManagerInputItem.module.css";
import {
  FolderSVG,
  PdfSVG,
  VideoSVG,
  AccessSVG,
  CssSVG,
  UnknownSVG,
  SqlSVG,
  ExcelSVG,
  PptSVG,
  HtmlSVG,
  JsSVG,
  WordSVG,
  TextSVG,
} from "./ZirvuFileSVGCollection";

const ZirvuFileManagerInputItem = (props, { className }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.addEventListener("contextmenu", function (event) {
      props.onRightClick(event);
      // console.log("KONTROL", event);
    });
    // console.log("REF", ref);
    // console.log("INIT ITM");
  }, []);

  const {
    id,
    agent_id,
    folder_id,
    name,
    path,
    type,
    url,
    extension,
    size,
    opacity,
    selected,
  } = props;

  const fileName = `${name}${
    extension && extension.length > 0 ? `.${extension}` : ""
  }`;

  const typeElement = (type) => {
    switch (type) {
      case "folder":
        return (
          <div style={{ width: "98px", height: "90px" }}> {FolderSVG} </div>
        );
      case "file":
        switch (extension) {
          case "png":
          case "jpg":
          case "jpeg":
          case "giff":
          case "bmp":
          case "tiff":
            return (
              <div
                className="col-center-center"
                style={{ width: "85px", height: "85px" }}
              >
                <img
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "100%",
                    boxShadow: "var(--shadow-box)",
                  }}
                  src={url}
                ></img>
              </div>
            );
          case "pdf":
            return (
              <div style={{ width: "85px", height: "85px" }}> {PdfSVG} </div>
            );
          case "mp3":
          case "mp4":
          case "mov":
          case "wmv":
          case "avi":
          case "mkv":
          case "3gp":
          case "webm":
          case "mpeg":
          case "mpeg-2":
          case "flv":
            return (
              <div style={{ width: "85px", height: "85px" }}> {VideoSVG} </div>
            );
          case "accdb":
          case "mdb":
            return (
              <div style={{ width: "85px", height: "85px" }}> {AccessSVG} </div>
            );
          case "css":
          case "scss":
            return (
              <div style={{ width: "85px", height: "85px" }}> {CssSVG} </div>
            );
          case "sql":
            return (
              <div style={{ width: "85px", height: "85px" }}> {SqlSVG} </div>
            );
          case "xls":
          case "xlsx":
          case "xlm":
            return (
              <div style={{ width: "85px", height: "85px" }}> {ExcelSVG} </div>
            );
          case "ppt":
          case "pptx":
            return (
              <div style={{ width: "85px", height: "85px" }}> {PptSVG} </div>
            );
          case "htm":
          case "html":
            return (
              <div style={{ width: "85px", height: "85px" }}> {HtmlSVG} </div>
            );
          case "js":
          case "ts":
            return (
              <div style={{ width: "85px", height: "85px" }}> {JsSVG} </div>
            );
          case "doc":
          case "docx":
            return (
              <div style={{ width: "85px", height: "85px" }}> {WordSVG} </div>
            );
          case "txt":
          case "text":
            return (
              <div style={{ width: "85px", height: "85px" }}> {TextSVG} </div>
            );
          default:
            return (
              <div style={{ width: "85px", height: "85px" }}>
                {" "}
                {UnknownSVG}{" "}
              </div>
            );
        }
    }
    return <span>{type}</span>;
  };

  return (
    <div
      ref={ref}
      className={`${
        selected ? classes.itemContainerSelected : classes.itemContainer
      } col-center-center`}
      style={{ opacity: opacity ?? 1 }}
      onClick={(event) => {
        // event.preventDefault();
        // if (window.getSelection().toString()) {
        //   // Remove the selected text
        //   window.getSelection().collapse();
        // }
        props.onClick(event);
      }}
    >
      <div className={`${classes.itemContainerImage} col-center-center p-1`}>
        {typeElement(type)}
      </div>
      <div className={`${classes.itemContainerLabel} col-start-center`}>
        <span>{fileName}</span>
      </div>
    </div>
  );
};

export default ZirvuFileManagerInputItem;
