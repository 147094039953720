import { useState } from "react";
import { useSelector } from "react-redux";
import { get, getOne, post, del } from "../../services/service-api";

const useAPIRequest = (props) => {
  let url;
  if (props && props.url) {
    url = props.url;
  }
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(false);

  const requestGet = async ({
    apiUrl,
    fullUrl,
    params,
    onSuccess,
    onError,
  }) => {
    const newParams = params ?? {};

    setLoading(true);
    try {
      const response = await get({
        token: token,
        url: fullUrl ?? `${url}/${apiUrl}`,
        params: newParams,
      });
      if (response) {
        const { success, message, data, pagination } = response.data;
        if (success) {
          onSuccess({ pagination, message, data });
        }
        setLoading(false);
        return response.data.data;
      }
    } catch (error) {
      console.log("ERROR", error);
      const errorResponse = error.response;
      if (errorResponse) {
        onError(errorResponse.data);
      } else {
        onError({ message: "Can't connect to server : No Connection" });
      }
      setLoading(false);
      return null;
    }
  };

  const requestGetOne = async ({ apiUrl, fullUrl, onSuccess, onError }) => {
    setLoading(true);
    try {
      const response = await getOne({
        token: token,
        url: fullUrl ?? `${url}/${apiUrl}`,
      });
      if (response) {
        const { success, message, data } = response.data;
        if (success) {
          onSuccess({ message, data });
        }
        setLoading(false);
        return response.data.data;
      }
    } catch (error) {
      // console.log("ERROR", error);
      const errorResponse = error.response;
      if (errorResponse) {
        onError(errorResponse.data);
      } else {
        onError({ message: "Can't connect to server : No Connection" });
      }
      setLoading(false);
      return null;
    }
  };

  const requestPost = async ({
    apiUrl,
    fullUrl,
    isForm,
    body,
    onSuccess,
    onError,
  }) => {
    setLoading(true);
    try {
      const response = await post({
        token: token,
        isForm: isForm,
        url: fullUrl ?? `${url}/${apiUrl}`,
        body: body,
      });
      if (response) {
        const { success, message, data } = response.data;
        if (success) {
          onSuccess({ message, data });
        }
        setLoading(false);
        return response.data.data;
      }
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse) {
        const { data, message } = errorResponse.data;
        onError({ message: message, data: data === null ? {} : data });
      } else {
        onError({ message: "Can't connect to server : No Connection" });
      }
      setLoading(false);
      return null;
    }
  };

  const requestDelete = async ({
    apiUrl,
    fullUrl,
    ids,
    onSuccess,
    onError,
  }) => {
    setLoading(true);
    try {
      const body = { data: ids };
      const response = await del({
        token: token,
        url: fullUrl ?? `${url}/${apiUrl}`,
        body: body,
      });
      if (response) {
        const { success, message, data } = response.data;
        if (success) {
          onSuccess({ message, data });
        }
        setLoading(false);
        return response.data.data;
      }
    } catch (error) {
      const errorResponse = error.response;
      if (errorResponse) {
        onError(errorResponse.data);
      } else {
        onError({ message: "Can't connect to server : No Connection" });
      }
      setLoading(false);
      return null;
    }
  };

  return { requestGet, requestGetOne, requestPost, requestDelete, loading };
};
export default useAPIRequest;
