import { subDays, addDays } from "date-fns";

const CRMProductConfigs = {
  master: {
    title: "CRM Product",
    apiName: "crm/crmproduct",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
      with_crm_product_category: 1,
      with_crm_product_galleries: 1,
    },
  },
  dialog: {
    title: "CRM Product",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Category",
      field: "crm_product_category_id",
      type: "object",
      object_name: "data_crm_product_category",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Home",
      field: "show_on_promotions",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Description",
      field: "description",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Image",
      field: "image",
      type: "zirvu_file_manager",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Youtube",
      field: "youtube",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    // {
    //   name: "Gallery",
    //   field: "data_crm_product_galleries",
    //   type: "list",
    //   columns: [
    //     { name: "Name", field: "name", type: "text_plain", editable: true },
    //     {
    //       name: "Image",
    //       field: "image",
    //       type: "zirvu_file_manager",
    //       editable: true,
    //     },
    //   ],
    //   width: "30rem",
    //   editor: true,
    // },
  ],
};

export default CRMProductConfigs;
