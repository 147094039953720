import React, { useEffect, useState } from "react";
import TableAutoComponent from "../components/complex/crud/TableAutoComponent";
import generateFilterConfigs from "../utils/FilterConfigGenerator";
import generateTableConfigs from "../utils/TableConfigGenerator";
import { format as dateFnsFormat, parse } from "date-fns";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";
import { Calendar } from "primereact/calendar";

const title = "Customer Package Info";
const apiName = "customer/customerfreeproduct";
const orderBy = "product_id";
const orderMethod = "ASC";
const additionalParams = {
  with_source_sales_item: 1,
  with_product: 1,
  with_usage_sales_item: 1,
};

const configs = [
  // add_configs
  {
    name: "Bought Date",
    field: "data_source_sales_item.data_sales.transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Bought Invoice",
    field: "data_source_sales_item.data_sales.number",
    type: "text_plain",
    table: true,
  },
  {
    name: "Expired Date",
    field: "expired_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Product",
    field: "data_product.name",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "Quantity",
    field: "quantity",
    align: "right",
    type: "number_plain",
    table: true,
  },
  {
    name: "Usage Date",
    field: "data_usage_sales_item.data_sales.transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Usage Invoice",
    field: "data_usage_sales_item.data_sales.number",
    type: "text_plain",
    table: true,
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const CustomerPackageHistoryDialog = (props) => {
  const { visible, data } = props;
  const single = props.single ?? true;
  let additionalFilter =
    `type:=:2` + (data && data.id ? `;customer_id:=:${data.id}` : "");

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    additionalFilter,
    visible: props.visible,
  });

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Close"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
      </div>
    </div>
  );

  const customColumns = [
    {
      field: "expired_date",
      component: (rowData) => {
        if (rowData && rowData.expired_date) {
          const expiredDate = parse(
            rowData.expired_date,
            "yyyy-MM-dd",
            new Date()
          );

          // count day to expired
          const today = new Date();
          const diffTime = Math.abs(expiredDate - today);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          const returnText =
            dateFnsFormat(expiredDate, "dd/MM/yyyy") + ` (${diffDays} days)`;

          return returnText;
        } else {
          return "";
        }
      },
    },
    {
      field: "data_usage_sales_item.data_sales.transaction_date",
      component: (rowData) => {
        if (rowData && rowData.usage_date) {
          if (
            rowData.data_usage_sales_item &&
            rowData.data_usage_sales_item.data_sales
          ) {
            const usageDate =
              rowData.data_usage_sales_item.data_sales.transaction_date;
            if (usageDate) {
              return dateFnsFormat(new Date(usageDate), "dd/MM/yyyy");
            } else {
              return "*Error*";
            }
          } else {
            return dateFnsFormat(new Date(rowData.usage_date), "dd/MM/yyyy");
          }
        } else {
          return "";
        }
      },
    },
    {
      field: "data_usage_sales_item.data_sales.number",
      component: (rowData) => {
        if (rowData && rowData.usage_date) {
          if (
            rowData.data_usage_sales_item &&
            rowData.data_usage_sales_item.data_sales
          ) {
            const number = rowData.data_usage_sales_item.data_sales.number;
            return number;
          } else {
            return "-";
          }
        } else {
          return "";
        }
      },
    },
  ];

  const handleLoadByDate = () => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;

    if (fromDate && !toDate) {
      toDate = fromDate;
    }

    if (fromDate && toDate) {
      const filterFromDate = `data_source_sales_item.data_sales.transaction_date:>=:${dateFnsFormat(
        fromDate,
        "yyyy-MM-dd"
      )}`;
      const filterToDate = `data_source_sales_item.data_sales.transaction_date:<=:${dateFnsFormat(
        toDate,
        "yyyy-MM-dd"
      )}`;
      additionalFilter =
        `type:=:2;${filterFromDate};${filterToDate}` +
        (data && data.id ? `;customer_id:=:${data.id}` : "");
      reloadListData({
        page: 1,
        take: paging.take ?? 10,
        filter: additionalFilter,
      });
    }
  };

  const handleLoadAll = () => {
    additionalFilter =
      `type:=:2` + (data && data.id ? `;customer_id:=:${data.id}` : "");
    reloadListData({
      page: 1,
      take: paging.take ?? 10,
      filter: additionalFilter,
    });
  };

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[75rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-4`}>
        <div className="row-start-end gap-2 w-full">
          <div className="col-start-start gap-1">
            <span className="font-semibold text-sm text-zinc-400">
              Bought Date
            </span>
            <Calendar
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.value)}
              selectionMode="range"
              readOnlyInput
              showIcon
            />
          </div>
          <Button
            className="w-[9rem] min-h-[2.6rem]"
            icon="pi pi-refresh"
            label="Load By Date"
            onClick={handleLoadByDate}
          />
          <Button
            className="w-[9rem] min-h-[2.6rem]"
            icon="pi pi-refresh"
            label="Load All"
            onClick={handleLoadAll}
          />
        </div>
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CustomerPackageHistoryDialog;
