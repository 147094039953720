import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import OutstandingSalesSingle from "./OutstandingSalesSingle";

const manualScrollAmount = 25;

const OutstandingSalesSection = (props) => {
  const { data, onChange, onNew, onRefresh, selected, setSelected, loading } =
    props;

  const scrollableRef = useRef(null);
  const [scrollIntervalId, setScrollIntervalId] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    onChange(selectedData);
  }, [selectedData]);

  const scroll = (amount) => {
    scrollableRef.current.scrollLeft += amount;
  };

  const startScroll = (amount) => {
    setScrollIntervalId(setInterval(() => scroll(amount), 100));
  };

  const stopScroll = () => {
    clearInterval(scrollIntervalId);
    setScrollIntervalId(null);
  };

  const handleScrollRight = () => {
    startScroll(manualScrollAmount);
  };

  const handleScrollLeft = () => {
    startScroll(-manualScrollAmount);
  };

  const handleMouseWheel = (event) => {
    scroll(-event.deltaY / 2);
  };

  return (
    <div
      className={`elevated-card-tight-vertical row-between-center w-full !px-4 gap-2`}
    >
      <div
        className={`row-between-center w-full gap-3`}
        onWheel={(event) => handleMouseWheel(event)}
      >
        <Button
          className="border-slate-200 min-h-[5rem] max-w-[2rem] "
          icon="pi pi-chevron-left"
          outlined
          loading={loading}
          onMouseDown={() => handleScrollLeft()}
          onMouseUp={() => stopScroll()}
        />
        <div
          ref={scrollableRef}
          className="horizontal-scrollbar-wrapper row-start-center flex-grow overflow-x-auto w-[10rem] pt-1 pb-2 gap-2"
        >
          {data &&
            data.length > 0 &&
            data.map((invoice, index) => {
              return (
                <OutstandingSalesSingle
                  key={invoice.id}
                  {...invoice}
                  selected={selected.id === invoice.id}
                  onClick={(event) => {
                    setSelected({ ...selected, id: invoice.id });
                    setSelectedData(invoice);
                  }}
                />
              );
            })}
        </div>
        <Button
          className="border-slate-200 min-h-[5rem] max-w-[2rem] "
          icon="pi pi-chevron-right"
          outlined
          loading={loading}
          onMouseDown={() => handleScrollRight()}
          onMouseUp={() => stopScroll()}
        />
      </div>
      <div className="col-center-center gap-1">
        <Button
          className="max-h-[2.3rem]"
          outlined
          loading={loading}
          icon="pi pi-plus"
          onClick={(event) => {
            setSelected(0);
            setSelectedData(null);
            onNew();
          }}
        />
        <Button
          className="max-h-[2.3rem]"
          loading={loading}
          outlined
          icon="pi pi-refresh"
          onClick={(event) => {
            event.preventDefault();
            onRefresh();
          }}
        />
      </div>
    </div>
  );
};

export default OutstandingSalesSection;
