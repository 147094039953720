import classes from "./EditorElementComponent.module.css";
import { format as dateFnsFormat, parse } from "date-fns";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { useEffect, useRef, useState } from "react";
import extractDataFromMap from "../../../utils/ExtractDataFromMap";
import TableAutoComponent from "./TableAutoComponent";
import { Tag } from "primereact/tag";
import { FileUpload } from "primereact/fileupload";
// import { process.env.REACT_APP_HOST_URL } from "../../../configs/constants";
import ZirvuFileManagerButton from "../../../components/zirvu-file-manager/ZirvuFileManagerInput";
import generateRandomMinusId from "../../../utils/GenerateRandomMinusId";
import generateDefaultValue from "../../../utils/GenerateDefaultValue";

const EditorElementTextPlain = (props, { className }) => {
  const { field, name, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <InputText
        id={field}
        autoFocus={props.autoFocus}
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.target.value)}
        className={`${props.error[field] && classes.errorInput}`}
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementTextLong = (props, { className }) => {
  const { field, name, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <InputTextarea
        autoResize
        id={field}
        autoFocus={props.autoFocus}
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.target.value)}
        className={`w-full ${props.error[field] && classes.errorInput}`}
        rows={5}
        cols={30}
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementTextPassword = (props, { className }) => {
  const { field, name, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <Password
        id={field}
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.target.value)}
        toggleMask
        className={`${classes.passwordInput} ${
          props.error[field] ? classes.errorInput : ""
        }`}
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementTextDropDown = (props, { className }) => {
  const { field, name, options, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <Dropdown
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.value)}
        options={options}
        optionLabel="name"
        className={`w-100`}
        style={
          props.error[field]
            ? {
                backgroundColor: "var(--color-error-light)",
              }
            : {}
        }
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementTextRadioButton = (props, { className }) => {
  const { field, name, options, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className="col-start-start gap-2">
        {options.map((option) => {
          return (
            <div
              key={option.value}
              className={`${classes.radioButton} row-start-center gap-2`}
            >
              <RadioButton
                disabled={disabled}
                inputId={option.value}
                name={option.name}
                value={option.value}
                onChange={(e) => props.handleInputChange(field, e.value)}
                checked={value === option.value}
              />
              <label htmlFor={option.value} className="ml-2">
                {option.name}
              </label>
            </div>
          );
        })}
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementNumberPlain = (props, { className }) => {
  const {
    field,
    name,
    min_fraction_digits,
    max_fraction_digits,
    min,
    max,
    disabled = false,
  } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? 0;
  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <InputNumber
        id={field}
        autoFocus={props.autoFocus}
        value={value}
        disabled={disabled}
        locale="id-ID"
        showButtons
        buttonLayout="horizontal"
        minFractionDigits={min_fraction_digits ? min_fraction_digits : 0}
        maxFractionDigits={max_fraction_digits ? max_fraction_digits : 0}
        min={min ? min : -999999999999}
        max={max ? max : 999999999999}
        onChange={(e) => {
          props.handleInputChange(field, e.value);
        }}
        className={`${classes.numericButton} ${
          props.error[field] && classes.errorInput
        }`}
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementNumberDropDown = (props, { className }) => {
  const { field, name, options, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? 0;
  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <Dropdown
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.value)}
        options={options}
        optionLabel="name"
        className="w-100"
        style={
          props.error[field]
            ? {
                backgroundColor: "var(--color-error-light)",
              }
            : {}
        }
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};
const EditorElementNumberRadiobutton = (props, { className }) => {
  const { field, name, options, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? 0;
  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className="col-start-start gap-2">
        {options.map((option) => {
          return (
            <div
              key={option.value}
              className={`${classes.radioButton} row-start-center gap-2`}
            >
              <RadioButton
                disabled={disabled}
                inputId={option.value}
                name={option.name}
                value={option.value}
                onChange={(e) => props.handleInputChange(field, e.value)}
                checked={value === option.value}
              />
              <label htmlFor={option.value} className="ml-2">
                {option.name}
              </label>
            </div>
          );
        })}
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementBooleanPlain = (props, { className }) => {
  const {
    field,
    name,
    label_true,
    label_false,
    disabled = false,
  } = props.config;
  const labelTrue = label_true ?? "Ya";
  const labelFalse = label_false ?? "Tidak";
  const value = extractDataFromMap({ key: field, map: props.value }) ?? false;
  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className="row-start-center gap-2">
        <InputSwitch
          disabled={disabled}
          className={`${props.error[field] && classes.errorInput}`}
          id={field}
          autoFocus={props.autoFocus}
          checked={value > 0 ? true : false}
          onChange={(e) => {
            props.handleInputChange(field, e.value ? 1 : 0);
          }}
        />
        {value > 0 ? labelTrue : labelFalse}
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementDate = (props, { className }) => {
  const {
    field,
    name,
    prime_format,
    min,
    max,
    show_time,
    time,
    month,
    year,
    disabled = false,
  } = props.config;
  const propValue = extractDataFromMap({ key: field, map: props.value });

  let value = null;
  if (propValue && propValue !== null) {
    if (propValue.length === 10) {
      value = parse(propValue, "yyyy-MM-dd", new Date());
    } else if (propValue.length === 16) {
      value = parse(propValue, "yyyy-MM-dd HH:mm", new Date());
    } else {
      value = parse(propValue, "yyyy-MM-dd HH:mm:ss", new Date());
    }
  }

  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <Calendar
        id={field}
        disabled={disabled}
        autoFocus={props.autoFocus}
        value={value ?? null}
        showIcon
        showButtonBar
        minDate={min}
        maxDate={max}
        dateFormat={prime_format ?? "dd/mm/yy"}
        showTime={show_time}
        hourFormat="24"
        timeOnly={time}
        view={month ? "month" : year ? "year" : "date"}
        onChange={(e) => {
          const jsDate = e.value;
          if (jsDate && jsDate !== null) {
            const formattedDate = dateFnsFormat(jsDate, "yyyy-MM-dd HH:mm:ss");
            props.handleInputChange(field, formattedDate);
          } else {
            props.handleInputChange(field, null);
          }
        }}
        className={`${
          props.error[field] && classes.errorInput
        } dateInput w-100`}
        locale="id"
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementDateRange = (props, { className }) => {
  const {
    field,
    name,
    prime_format,
    min,
    max,
    show_time,
    time,
    month,
    year,
    disabled = false,
  } = props.config;
  const propValue = extractDataFromMap({ key: field, map: props.value });

  let value = propValue;

  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <Calendar
        id={field}
        disabled={disabled}
        // autoFocus={props.autoFocus}
        value={value ?? null}
        showIcon
        showButtonBar
        selectionMode="range"
        minDate={min}
        maxDate={max}
        dateFormat={prime_format ?? "dd/mm/yy"}
        showTime={show_time}
        hourFormat="24"
        timeOnly={time}
        view={month ? "month" : year ? "year" : "date"}
        onChange={(e) => {
          props.handleInputChange(field, e.value);
        }}
        className={`${
          props.error[field] && classes.errorInput
        } dateInput w-100`}
        locale="id"
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementObject = (props) => {
  const {
    field,
    name,
    object_name,
    object_field,
    selectorGenerator,
    disabled = false,
    nullable = false,
  } = props.config;

  const [showSelector, setShowSelector] = useState(false);
  const [buttonText, setButtonText] = useState("-");

  useEffect(() => {
    let value = null;
    if (props.value[object_name]) {
      if (props.value[object_name][object_field]) {
        value = props.value[object_name][object_field];
      }
    }
    setButtonText(value ?? "-");
  }, [props.value[object_name]]);

  let selectorDialog;
  if (selectorGenerator) {
    selectorDialog = selectorGenerator({
      visible: showSelector,
      setVisible: setShowSelector,
      onConfirm: (e, newValue) => {
        e.preventDefault();
        setShowSelector(false);
        if (newValue && Array.isArray(newValue) && newValue.length > 0) {
          const first = newValue[0];

          props.handleMultipleInputChange([
            { field: object_name, newValue: first },
            { field: field, newValue: first.id },
          ]);
          props.value[object_name] = first;
          setButtonText(first[object_field]);
        } else {
          props.handleInputChange(field, null);
          props.value[object_name] = null;
          setButtonText("-");
        }
      },
    });
  }

  return (
    <div className={`${classes.wrapper} col-start-start w-100 gap-1`}>
      {selectorDialog}
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className="row-start-center w-100 gap-1">
        <div
          className={`${classes.browseButtonContainer} ${
            props.error[field] && classes.errorInput
          } row-start-center gap-2 w-100`}
        >
          <Button
            disabled={disabled}
            className={`${classes.browseButton} grow`}
            outlined
            onClick={(e) => {
              e.preventDefault();
              setShowSelector(true);
            }}
          >
            <div className="row-between-center w-100">
              {buttonText} <i className="pi pi-search" />
            </div>
          </Button>
        </div>
        {nullable && (
          <Button
            severity="primary"
            outlined
            size="small"
            icon="pi pi-times"
            onClick={(event) => {
              event.preventDefault();
              props.handleInputChange(field, null);
              props.value[object_name] = null;
              setButtonText("-");
            }}
          />
        )}
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementObjectDropdownLocal = (props, { className }) => {
  const {
    field,
    name,
    label_field,
    source,
    default_value,
    disabled = false,
  } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  const options = extractDataFromMap({ key: source, map: props.value }) ?? "";
  const selectedOption = options.find(
    (el) => el[label_field] === value[label_field]
  );
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>

      <Dropdown
        value={selectedOption ?? null}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.value)}
        options={options}
        optionLabel={label_field}
        className={`w-100`}
        style={
          props.error[field]
            ? {
                backgroundColor: "var(--color-error-light)",
              }
            : {}
        }
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementObjectDropdownAPI = (props, { className }) => {
  const { field, name, options, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value }) ?? "";
  return (
    <div
      className={`${classes.wrapper} ${
        disabled ? classes.disabled : ""
      } col-start-start w-100 gap-1`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>

      {/* <Dropdown
        value={value}
        disabled={disabled}
        onChange={(e) => props.handleInputChange(field, e.value)}
        options={options}
        optionLabel="name"
        className={`w-100`}
        style={
          props.error[field]
            ? {
                backgroundColor: "var(--color-error-light)",
              }
            : {}
        }
      /> */}
      <Button
        label="JANCUK"
        onClick={(e) => {
          console.log("COKCOKCOKC");
        }}
      ></Button>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementList = (props) => {
  const { field, name, columns, width, disabled = false } = props.config;

  const [selectedRows, setSelectedRows] = useState({});

  const handleSelectionChange = (event) => {
    const values = event.value;
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  const generateNewValue = () => {
    const defaultValue = generateDefaultValue({ tableConfigs: columns });
    defaultValue.id = generateRandomMinusId();
    return defaultValue;
  };

  const handleAdd = (e, newValue) => {
    e.preventDefault();

    if (newValue) {
      if (!props.value[field]) {
        props.value[field] = [];
      }
      // let emptyFound = false;
      // for (const el of props.value[field]) {
      //   if (Object.keys(el).length === 1) {
      //     emptyFound = true;
      //     break;
      //   }
      // }
      // if (!emptyFound)
      props.handleInputChange(field, [...props.value[field], newValue]);
    }
  };

  const handleDelete = (e) => {
    if (selectedRows.length <= 0) return;

    const accept = () => {
      const listSelectedId = selectedRows.map((el) => el.id);
      const newListData = props.value[field].filter(
        (el) => !listSelectedId.includes(el.id)
      );
      props.handleInputChange(field, [...newListData]);
    };

    const reject = () => {};

    confirmPopup({
      target: e.currentTarget,
      message: "Hapus data terseleksi?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  return (
    <div className={`${classes.wrapper} col-start-start w-100 gap-1 `}>
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className={`col-start-start w-100 gap-2`}>
        <ConfirmPopup />
        <div className="row-start-center gap-2">
          <Button
            disabled={disabled}
            label="Tambah"
            icon="pi pi-plus"
            outlined
            onClick={(e) => {
              e.preventDefault();
              handleAdd(e, generateNewValue());
            }}
          />{" "}
          <Button
            disabled={disabled}
            label="Hapus"
            icon="pi pi-trash"
            severity="danger"
            outlined
            onClick={(e) => handleDelete(e)}
          />{" "}
        </div>
        <TableAutoComponent
          minWidth={width}
          selectedRows={selectedRows}
          onSelectionChange={handleSelectionChange}
          configs={columns}
          loading={false}
          value={props.value[field]}
          onEditRow={async (row) => {}}
          onDeleteRow={(row) => {}}
          customColumns={[]}
        />
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementListObject = (props) => {
  const {
    field,
    name,
    columns,
    width,
    selectorGenerator,
    disabled = false,
  } = props.config;
  // const value = extractDataFromMap({ key: field, map: props.value });

  const [showSelector, setShowSelector] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});

  const handleSelectionChange = (event) => {
    // console.log("Handle Selection change", event);
    const values = event;
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleAdd = (e, newValue) => {
    e.preventDefault();
    setShowSelector(false);

    if (newValue && Array.isArray(newValue) && newValue.length > 0) {
      if (!props.value[field]) {
        props.value[field] = [];
      }
      const listOldId = props.value[field].map((el) => el.id);
      const newValues = [];
      for (const v of newValue) {
        if (!listOldId.includes(v.id)) {
          newValues.push(v);
        }
      }
      if (newValue.length > 0) {
        props.handleInputChange(field, [...props.value[field], ...newValues]);
      }
    }
  };

  const handleDelete = (e) => {
    if (selectedRows.length <= 0) return;

    const accept = () => {
      const listSelectedId = selectedRows.map((el) => el.id);
      const newListData = props.value[field].filter(
        (el) => !listSelectedId.includes(el.id)
      );
      props.handleInputChange(field, [...newListData]);
    };

    const reject = () => {};

    confirmPopup({
      target: e.currentTarget,
      message: "Hapus data terseleksi?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  let selectorDialog;
  if (selectorGenerator) {
    selectorDialog = selectorGenerator({
      visible: showSelector,
      setVisible: setShowSelector,
      onConfirm: (e, newValue) => handleAdd(e, newValue),
    });
  }

  return (
    <div className={`${classes.wrapper} col-start-start w-100 gap-1 `}>
      {selectorDialog}
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <div className={`col-start-start w-100 gap-2`}>
        <ConfirmPopup />
        <div className="row-start-center gap-2">
          <Button
            disabled={disabled}
            label="Tambah"
            icon="pi pi-plus"
            outlined
            onClick={(e) => {
              e.preventDefault();
              setShowSelector(true);
            }}
          />{" "}
          <Button
            disabled={disabled}
            label="Hapus"
            icon="pi pi-trash"
            severity="danger"
            outlined
            onClick={(e) => handleDelete(e)}
          />{" "}
        </div>
        <TableAutoComponent
          minWidth={width}
          // selectedRows={selectedRows}
          onSelectionChange={handleSelectionChange}
          configs={columns}
          loading={false}
          // value={listDatas}
          value={props.value[field]}
          // value={value}
          onEditRow={async (row) => {}}
          onDeleteRow={(row) => {}}
          customColumns={[]}
        />
      </div>
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementImage = (props, { className }) => {
  const { field, name, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value });

  const fileUploadRef = useRef(null);
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const onTemplateSelect = (e) => {
    const files = e.files;

    if (files && files.length > 0) {
      const file = files[0];
      props.handleInputChange(field, file);
    }
  };

  const onTemplateUpload = (e) => {};

  const onTemplateRemove = (file, callback) => {
    callback();
  };

  const onTemplateClear = () => {
    props.handleInputChange(field, null);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div
        className={`${className} ${classes.uploadHeader} row-between-start p-2`}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="row-center-center gap-3">
        <img
          alt={file.name}
          role="presentation"
          src={file.objectURL}
          width={100}
        />
        <div className="col-center-center gap-3">
          <small style={{ textAlign: "center" }}>{file.name}</small>
          <Tag
            value={props.formatSize}
            severity="warning"
            className="px-3 py-2"
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    if (valueState && valueState !== null) {
      return (
        <div className="col-center-center">
          <img
            alt={valueState}
            role="presentation"
            src={`${process.env.REACT_APP_HOST_URL}/${valueState}`}
            width={"150px"}
          />
        </div>
      );
    } else {
      return (
        <div className="col-center-center">
          <i
            className="pi pi-image mt-1 p-1"
            style={{
              fontSize: "5em",
              borderRadius: "50%",
              backgroundColor: "var(--surface-b)",
              color: "var(--surface-d)",
            }}
          ></i>
          <span
            style={{
              fontSize: "1.2em",
              color: "var(--text-color-secondary)",
            }}
            className="my-1"
          >
            Drag and Drop Image Here
          </span>
        </div>
      );
    }
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
    width: "100px",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <FileUpload
        disabled={disabled}
        className={`${classes.uploadInput} w-100`}
        ref={fileUploadRef}
        name="demo[]"
        url="/api/upload"
        multiple
        accept="image/*"
        maxFileSize={1000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />{" "}
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
  );
};

const EditorElementZirvuFileManager = (props, { className }) => {
  const { field, name, disabled = false } = props.config;
  const value = extractDataFromMap({ key: field, map: props.value });

  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  return (
    <div
      className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    >
      <label htmlFor={field} className="font-bold">
        {name}
      </label>
      <ZirvuFileManagerButton
        disabled={disabled}
        width="100%"
        height="200px"
        value={valueState}
        onSelect={(event, newValue) => {
          if (newValue && newValue.url !== null) {
            props.handleInputChange(field, newValue.url);
          } else {
            props.handleInputChange(field, null);
          }
        }}
      />
      {props.error[field] && (
        <small className="p-error">{props.error[field]}</small>
      )}
    </div>
    // <div
    //   className={`${classes.wrapper} col-start-start w-100 gap-1 ${className}`}
    // >
    //   <label htmlFor={field} className="font-bold">
    //     {name}
    //   </label>
    //   <FileUpload
    //     className={`${classes.uploadInput} w-100`}
    //     ref={fileUploadRef}
    //     name="demo[]"
    //     url="/api/upload"
    //     multiple
    //     accept="image/*"
    //     maxFileSize={1000000}
    //     onUpload={onTemplateUpload}
    //     onSelect={onTemplateSelect}
    //     onError={onTemplateClear}
    //     onClear={onTemplateClear}
    //     headerTemplate={headerTemplate}
    //     itemTemplate={itemTemplate}
    //     emptyTemplate={emptyTemplate}
    //     chooseOptions={chooseOptions}
    //     uploadOptions={uploadOptions}
    //     cancelOptions={cancelOptions}
    //   />{" "}
    //   {props.error[field] && (
    //     <small className="p-error">{props.error[field]}</small>
    //   )}
    // </div>
  );
};

export {
  EditorElementTextPlain,
  EditorElementTextLong,
  EditorElementTextPassword,
  EditorElementTextDropDown,
  EditorElementTextRadioButton,
  EditorElementNumberPlain,
  EditorElementNumberDropDown,
  EditorElementNumberRadiobutton,
  EditorElementBooleanPlain,
  EditorElementDate,
  EditorElementDateRange,
  EditorElementObject,
  EditorElementObjectDropdownLocal,
  EditorElementObjectDropdownAPI,
  EditorElementList,
  EditorElementListObject,
  EditorElementImage,
  EditorElementZirvuFileManager,
};
