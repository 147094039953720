import ReportSectionSingle from "./ReportSectionSingle";
import { Divider } from "primereact/divider";

const ReportSection = ({ title, details }) => {
  return (
    <div className="elevated-card-tight-vertical col-start-start gap-4 w-full">
      <div className="row-start-center gap-4 w-full">
        <span className="w-1 h-7 bg-[var(--color-primary)]"></span>
        <h1 className="text-xl font-semibold tracking-wide">
          {title.toUpperCase()}
        </h1>{" "}
      </div>
      <div className="col-start-start w-full">
        {details.map((item) => {
          return <ReportSectionSingle {...item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default ReportSection;
