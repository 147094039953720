import { Button } from "primereact/button";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { useContext, useEffect, useState } from "react";
import UiContext from "../../../../store/ui-context";
import { confirmDialog } from "primereact/confirmdialog";

const GenerateCustomerFollowUpSetting = (props) => {
  const uiContext = useContext(UiContext);
  const { loading, requestGet, requestPost } = useAPIRequest();

  const generateFollowUpData = async () => {
    await requestGet({
      fullUrl: `/api/customer/customerfollowup/generate`,
      params: {},
      onSuccess: ({ pagination, message, data }) => {
        uiContext.showSuccessMsg("Success", message);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  return (
    <>
      <div className="col-start-start w-[25rem] hover:bg-indigo-50 p-3 gap-4">
        <div className="col-start-start">
          <h1 className="font-bold text-slate-600">Customer Follow Up</h1>
          <h2 className="font-semibold text-sm text-slate-600">
            Generate follow up form for this month
          </h2>
          <h2 className="font-semibold text-sm text-slate-600">
            (monthly procedure)
          </h2>
        </div>

        <Button
          className="!rounded-none !text-sm !h-[2.1rem]"
          loading={loading}
          label="Generate Follow Up"
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={() => generateFollowUpData()}
        />
      </div>
    </>
  );
};
export default GenerateCustomerFollowUpSetting;
