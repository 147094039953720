import { Toast } from "primereact/toast";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Paginator } from "primereact/paginator";

import { format, parse } from "date-fns";
import configs from "./SalesJournalConfig";
import useMDArsipPageControl from "../../../../custom_hooks/complex/useMDArsipPageControl";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import PrintSalesReceiptDialog from "../../../../dialogs/PrintSalesReceiptDialog";
import UiContext from "../../../../store/ui-context";
import SalesPreviewDialog from "./SalesPreviewDialog";
import CustomerSelectorDialog from "../../../../dialogs/selectors/CustomerSelectorDialog";
import SalesChangeDateDialog from "./SalesChangeDateDialog";
import { id } from "date-fns/locale";

// const customColumns = [];
const customFilters = [];

export const generateTabLabel = (row) => {
  return `Nomor NPB: ${row.nomor_npb}`;
};

const newTab = false;

const additionalParams = configs.master.additionalParams;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const apiName = configs.master.apiName;
const title = configs.master.title;
const additionalFilter = configs.master.additionalFilter;

const columns = generateTableConfigs(configs.columns);

const SalesJournalPage = (props) => {
  const { addNewEditor } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const currentUrl = location.pathname;

  const uiContext = useContext(UiContext);

  const [visiblePrintReceiptDialog, setVisiblePrintReceiptDialog] =
    useState(false);
  const [visibleSalesPreviewDialog, setVisibleSalesPreviewDialog] =
    useState(false);
  const [visibleChangeDateDialog, setVisibleChangeDateDialog] = useState(false);
  const [selectedSales, setSelectedSales] = useState({});

  const {
    // UIs
    toast,
    // states
    paging,
    loading,
    listData,
    setListData,
    selectedRows,
    filter,
    setFilter,
    //methods
    elements,
    generateSelectorDialog,
    handleReload,
    handleOnPage,
    // handleAddNew,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    requestGet,
    requestPost,
    requestDelete,
    requestGetOne,
    handleFilterChange,
    handleMultipleFilterChange,
  } = useMDArsipPageControl({
    title,
    apiName,
    additionalParams,
    orderBy,
    orderMethod,
    addNewEditor,
    generateTabLabel,
    configFilters: configs.filters,
    customFilters,
    additionalFilter,
  });

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  generateSelectorDialog({
    field: "customer_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <CustomerSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const refundSales = async ({ id }) => {
    return await requestPost({
      fullUrl: "api/sales/sales/refund",
      body: { sales_id: id },
      onSuccess: ({ data }) => {
        if (data) {
          uiContext.showSuccessMsg("Success", "Sales refunded");
          setListData((prev) => {
            return prev.filter((row) => row.id !== id);
          });
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handlePreview = async (row) => {
    await loadSales({ id: row.id });
    setVisibleSalesPreviewDialog(true);
  };

  const handlePrintReceipt = async (row) => {
    await loadSales({ id: row.id });
    setVisiblePrintReceiptDialog(true);
  };

  const handleRefundSales = async (row) => {
    confirmDialog({
      message: "Are you sure you want refund this sales?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        refundSales({ id: row.id });
      },
    });
  };

  const handleClickRemoveCustomerFilter = (event) => {
    event.preventDefault();
    // handleMultipleFilterChange({ customer_id: "", data_customer: undefined });
    const newFilter = { ...filter };
    newFilter["customer_id"] = "";
    newFilter["data_customer"] = { id: 0, name: "-" };
    setFilter(newFilter);
  };

  const customColumns = [
    {
      field: "preview",
      component: (rowData) => {
        return (
          <div className="row-center-center gap-2">
            <Button
              icon="pi pi-undo"
              rounded
              outlined
              severity="danger"
              aria-label="Search"
              onClick={(e) => handleRefundSales(rowData)}
            />
            <Button
              icon="pi pi-eye"
              rounded
              outlined
              aria-label="Search"
              onClick={(e) => handlePreview(rowData)}
            />
            <Button
              icon="pi pi-print"
              rounded
              outlined
              aria-label="Search"
              onClick={(e) => handlePrintReceipt(rowData)}
            />
          </div>
        );
      },
    },
    {
      field: "number",
      component: (rowData) => {
        const { number, transaction_date } = rowData;
        return (
          <div className="col-start-start">
            <span className="">{number}</span>
            <span className="text-sm text-left text-slate-400 font-semibold">
              {transaction_date}
            </span>
          </div>
        );
      },
    },
    {
      field: "data_customer.name",
      component: (rowData) => {
        const { data_customer } = rowData;
        let _name = "";
        let _code = "";

        if (data_customer) {
          const { name, code } = data_customer;
          _name = name ?? "";
          _code = code ?? "";
        }

        return (
          <div className="col-start-start">
            <span className="">{_name}</span>
            <span className="text-sm text-left text-slate-400 font-semibold">
              {_code}
            </span>
          </div>
        );
      },
    },
  ];

  const loadSales = async ({ id }) => {
    await requestGetOne({
      fullUrl: `/api/sales/sales/one/${id}`,

      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setSelectedSales(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handleClickChangeDate = () => {
    setVisibleChangeDateDialog(true);
  };

  const handleChangeDateConfirm = async (date) => {
    if (date) {
      const newDateMysql = format(date, "yyyy-MM-dd HH:mm:ss");

      return await requestPost({
        fullUrl: "api/sales/sales/changetransactiondate",
        body: { id: selectedSales.id, transaction_date: newDateMysql },
        onSuccess: ({ data }) => {
          if (data) {
            uiContext.showSuccessMsg("Success", "Date changed");
            setVisibleChangeDateDialog(false);
            const newSelectedSales = {
              ...selectedSales,
              transaction_date: data.transaction_date,
            };
            setSelectedSales(newSelectedSales);
            setListData((prev) => {
              return prev.map((row) => {
                if (row.id === selectedSales.id) {
                  return newSelectedSales;
                }
                return row;
              });
            });
          }
        },
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
      });
    }
  };

  return (
    <div className={`col-start-start px-4 gap-4 w-100`}>
      <Toast ref={toast} />
      <ConfirmDialog />

      <SalesPreviewDialog
        data={selectedSales}
        onClickChangeDate={handleClickChangeDate}
        visible={visibleSalesPreviewDialog}
        onHide={(e) => {
          setVisibleSalesPreviewDialog(false);
        }}
      />

      <SalesChangeDateDialog
        data={selectedSales}
        onConfirm={handleChangeDateConfirm}
        visible={visibleChangeDateDialog}
        loading={loading}
        onHide={(e) => {
          setVisibleChangeDateDialog(false);
        }}
      />

      <PrintSalesReceiptDialog
        visible={visiblePrintReceiptDialog}
        onHide={(e) => {
          setVisiblePrintReceiptDialog(false);
        }}
        data={selectedSales}
      />

      <div></div>
      <div className="elevated-card w-100 gap-3 row-between-center">
        <div className="row-start-center gap-3">
          <div className="row-start-center">{elements.transaction_date}</div>
          <div className="row-start-end w-[18rem] gap-1">
            {elements.customer_id}
            <Button
              className="max-h-[2.4rem]"
              icon="pi pi-times"
              onClick={handleClickRemoveCustomerFilter}
            />
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={handleReload}
            rounded
            outlined
          />
        </div>
      </div>
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton={false}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={columns}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>
    </div>
  );
};

export default SalesJournalPage;
