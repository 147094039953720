import React, { useEffect, useState } from "react";
import TableAutoComponent from "../components/complex/crud/TableAutoComponent";
import FilterComponent from "../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../utils/FilterConfigGenerator";
import generateTableConfigs from "../utils/TableConfigGenerator";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";
import { Calendar } from "primereact/calendar";
import { format as dateFnsFormat, parse } from "date-fns";

const title = "Customer Point History";
const apiName = "customer/pointtransactionhistory";
const orderBy = "id";
const orderMethod = "ASC";
const additionalParams = {
  // with_sales: 1,
};
const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const customColumns = [];

const configs = [
  // add_configs
  {
    name: "Date",
    field: "transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Description",
    field: "description",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "+ / -",
    field: "movement",
    type: "number_plain",
    align: "right",
    table: true,
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const CustomerPointHistoryDialog = (props) => {
  const single = props.single ?? true;
  const { data } = props;
  let additionalFilter = "";

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
  });

  const customColumns = [
    {
      field: "movement",
      component: (rowData) => {
        if (rowData) {
          const type = rowData.type;
          const point = rowData.point;
          return (point * type).toLocaleString("id-ID", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          });
        } else {
          return "";
        }
      },
    },
  ];

  const handleLoadByDate = () => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;
    if (fromDate && !toDate) {
      toDate = fromDate;
    }
    if (fromDate && toDate && data.id) {
      const filterFromDate = `transaction_date:>=:${dateFnsFormat(
        fromDate,
        "yyyy-MM-dd"
      )}`;
      const filterToDate = `transaction_date:<=:${dateFnsFormat(
        toDate,
        "yyyy-MM-dd"
      )}`;
      additionalFilter = `customer_id:=:${data.id};${filterFromDate};${filterToDate}`;
      reloadListData({
        page: 1,
        take: paging.take ?? 10,
        filter: additionalFilter,
      });
    }
  };

  useEffect(() => {
    if (props.visible) {
      handleLoadByDate();
    }
  }, [props.visible]);

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Close"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[64rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-4`}>
        <div className="row-start-end gap-2 w-full">
          <div className="col-start-start">
            <span className="font-semibold text-sm text-zinc-400">
              Transaction Date
            </span>
            <Calendar
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.value)}
              selectionMode="range"
              readOnlyInput
              showIcon
            />
          </div>
          <Button
            className="w-[8rem] min-h-[2.6rem]"
            icon="pi pi-refresh"
            label="Load Data"
            onClick={handleLoadByDate}
          />
        </div>
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CustomerPointHistoryDialog;
