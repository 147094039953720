const EventCalendarEmptyItem = (props) => {
  return (
    <div className="border p-0 text-center w-full bg-gray-50 ">
      <div className="w-full p-1 h-full">
        <div className="bg-slate-200 w-full h-full"></div>
      </div>
    </div>
  );
};
export default EventCalendarEmptyItem;
