import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaQrcode } from "react-icons/fa6";
import { format as dateFnsFormat, parse } from "date-fns";
import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";
import UiContext from "../store/ui-context";
const CryptoJS = require("crypto-js");

const title = "Redeem Point";

const apiName = "customer/customerfreeproduct";
const orderBy = "id";
const orderMethod = "ASC";
const additionalParams = { with_source_sales_item: 1, with_product: 1 };

const customColumns = [];

const configs = [];

const RedeemPointDialog = (props) => {
  const uiContext = useContext(UiContext);
  const refInput = useRef(null);
  const { visible, data, onHide, onConfirm } = props;

  const single = props.single ?? true;
  let additionalFilter =
    `type:=:1;usage_date:=:Null;expired_date:>=:${dateFnsFormat(
      new Date(),
      "yyyy-MM-dd"
    )}` + (data && data.id ? `;customer_id:=:${data.id}` : "");

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    additionalFilter,
    visible: props.visible,
  });
  const [qrCode, setQrCode] = useState("");
  const [mapQrData, setMapQrData] = useState({});

  useEffect(() => {
    const newMap = {};
    for (const el of listData) {
      const encoded = encode(`${el.id}`);
      newMap[encoded] = el;
    }
    setMapQrData(newMap);
  }, [listData]);

  useEffect(() => {
    if (visible) {
      setQrCode("");
    }
  }, [visible]);

  const encode = (input) => {
    const hash = CryptoJS.SHA256("J3L1m" + input);
    const text = hash.toString();
    return text.substring(text.length - 8);
  };

  const confirm = (event) => {
    const barcode = qrCode.trim();
    const customerFreeProduct = mapQrData[barcode];

    // console.log("CUSTOMER FREE PRODUCT", mapQrData);

    if (customerFreeProduct) {
      onConfirm(event, [customerFreeProduct]);
    } else {
      uiContext.showErrorMsg("Error", "Free Product not found or already used");
      setQrCode("");
      refInput.current.focus();
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      confirm(event);
    }
  };

  const handleClickInsert = (event) => {
    event.preventDefault();
    confirm(event);
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Insert"
          icon="pi pi-save"
          loading={loading}
          onClick={handleClickInsert}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "30rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="row-center-center w-100 gap-4 pt-2">
          {/* <span className="font-semibold text-lg">Scan QR</span> */}
          <i className="pi pi-qrcode text-7xl"> </i>
          <InputText
            ref={refInput}
            autoFocus
            className="text-center text-4xl w-[18rem] h-[5rem]"
            value={qrCode}
            onChange={(e) => setQrCode(e.target.value)}
            onKeyDown={handleEnter}
          />
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default RedeemPointDialog;
