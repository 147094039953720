import { createContext, useState } from "react";

const UiContext = createContext({
  toastRef: null,
  setToastRef: function (toastRef) {},
  showSuccessMsg: function (title, message) {},
  showErrorMsg: function (titile, message) {},
});

const UiContextProvider = (props) => {
  const [tRef, setTRef] = useState(null);

  const setToastRefHandler = (toastRef) => {
    setTRef(toastRef);
  };

  const showSuccessMsgHandler = (title, message) => {
    if (tRef) {
      tRef.current.show({
        severity: "success",
        summary: title,
        detail: message,
        life: 3000,
      });
    }
  };

  const showErrorMsgHandler = (title, message) => {
    if (tRef) {
      tRef.current.show({
        severity: "error",
        summary: title,
        detail: message,
        life: 3000,
      });
    }
  };

  const context = {
    toastRef: tRef,
    setToastRef: setToastRefHandler,
    showSuccessMsg: showSuccessMsgHandler,
    showErrorMsg: showErrorMsgHandler,
  };

  return (
    <UiContext.Provider value={context}>{props.children}</UiContext.Provider>
  );
};

export { UiContextProvider };

export default UiContext;
