// function to calculate the subtotal discount
// type: 1 = nominal, 2 = percentage, 3 = by quantity (value * quantity)

const calculateItemDiscount = ({ type, value, subtotal, quantity }) => {
  let discount = 0;

  if (type === 1) {
    discount = value;
  } else if (type === 2) {
    discount = (value / 100) * subtotal;
  } else if (type === 3) {
    discount = value * quantity;
  }

  return discount;
};
export default calculateItemDiscount;
