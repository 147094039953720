import axios from "axios";
// import { process.env.REACT_APP_HOST_URL_FILE_MANAGER } from "../../configs/constants";

const generateFetcher = (token) => {
  let axiosInstance;
  if (token) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_HOST_URL_FILE_MANAGER,
      headers: {
        // "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_HOST_URL_FILE_MANAGER,
    });
  }
  return axiosInstance;
};

const convertBodyToFormData = (jsonBody) => {
  const formData = new FormData();
  for (const key in jsonBody) {
    const value = jsonBody[key];
    if (value instanceof File) {
      formData.append(key, value);
    } else if (value instanceof Map || value instanceof Object) {
      for (const secondaryKey in value) {
        const newKey = `${key}[${secondaryKey}]`;
        const newValue = value[secondaryKey];
        formData.append(newKey, newValue);
      }
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

const post = async ({ token, url, isForm, body }) => {
  const fetcher = generateFetcher(token);
  if (!isForm) {
    const response = await fetcher.post(url, body);
    return response;
  } else {
    const formData = convertBodyToFormData(body);
    const response = await fetcher.post(url, formData, {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  }
};

const get = async ({ token, url, params }) => {
  const newParams = { ...params, page: 1, take: 10000 };
  const fetcher = generateFetcher(token);
  const response = await fetcher.get(url, { params: newParams });
  return response;
};

const getOne = async ({ token, url }) => {
  const fetcher = generateFetcher(token);
  const response = await fetcher.get(url);
  return response;
};

const del = async ({ token, url, body }) => {
  const fetcher = generateFetcher(token);
  const response = await fetcher.delete(url, { data: body });
  return response;
};

export { post, get, getOne, del };
