import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import React, { useContext, useEffect, useState } from "react";
import { format, parse, set } from "date-fns";
import { Button } from "primereact/button";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import UiContext from "../../../../store/ui-context";
import { TabView, TabPanel } from "primereact/tabview";
import CustomerSelectorDialog from "../../../../dialogs/selectors/CustomerSelectorDialog";
import ListFreeProduct from "./ListFreeProduct";
import ProfileData from "./ProfileData";
import PointHistory from "./PointHistory";
import SalesHistory from "./SalesHistory";
import PackageHistory from "./PackageHistory";
import FreeProductEditorDialog from "./FreeProductEditorDialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useParams } from "react-router-dom";
import RewardHistory from "./RewardHistory";

const title = "Customer Data";

const CustomerDataPage = (props) => {
  let { id } = useParams();
  const uiContext = useContext(UiContext);
  const dispatch = useDispatch();

  const [visibleCustomerSelector, setVisibleCustomerSelector] = useState(false);
  const [visibleFreeProductEditor, setVisibleFreeProductEditor] =
    useState(false);
  const [freeProductEditorData, setFreeProductEditorData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [listFreeProduct, setListFreeProduct] = useState([]);
  const [listPointHistory, setListPointHistory] = useState([]);
  const [listSalesHistory, setListSalesHistory] = useState([]);
  const [listFreeProductHistory, setListFreeProductHistory] = useState([]);

  const { requestPost, requestDelete, requestGetOne, loading, requestGet } =
    useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  useEffect(() => {
    if (id && id > 0) {
      loadCustomer(id);
    }
  }, [id]);

  const loadCustomer = (id) => {
    if (id > 0) {
      requestGetOne({
        fullUrl: `api/customer/customer/one/${id}`,
        onSuccess: ({ message, data }) => {
          setSelectedCustomer(data);
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    }
  };

  const handleOnChangeCustomer = (newCustomer) => {
    setSelectedCustomer(newCustomer);
    setListFreeProduct([]);
    setListPointHistory([]);
    setListSalesHistory([]);
    setListFreeProductHistory([]);
  };

  const handleLoadFreeProducts = async ({ showExpired }) => {
    setListFreeProduct([]);
    if (selectedCustomer && selectedCustomer.id) {
      const id = selectedCustomer.id;
      const expiredTxt = format(new Date(), "yyyy-MM-dd");
      const res = await requestGet({
        fullUrl: "api/customer/customerfreeproduct/data",
        params: {
          page: 1,
          take: 1000,
          filter: `customer_id:=:${id}${
            showExpired ? "" : `;expired_date:>=:${expiredTxt}`
          };usage_date:=:Null`,
          with_product: 1,
        },
        onSuccess: ({ pagination, message, data }) => {
          // change field in list data, expired_date to Date object
          const newData = data.map((item) => {
            return {
              ...item,
              expired_date: parse(item.expired_date, "yyyy-MM-dd", new Date()),
            };
          });
          // sort newData by data_product.name ASC on first level and expired_date ASC on second level
          newData.sort((a, b) => {
            if (a.data_product.name < b.data_product.name) {
              return -1;
            }
            if (a.data_product.name > b.data_product.name) {
              return 1;
            }
            if (a.expired_date < b.expired_date) {
              return -1;
            }
            if (a.expired_date > b.expired_date) {
              return 1;
            }
            return 0;
          });

          setListFreeProduct(newData);
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    }
  };

  const handleLoadPointHistory = async ({ fromDate, toDate }) => {
    setListPointHistory([]);
    if (selectedCustomer && selectedCustomer.id) {
      const filter = `customer_id:=:${
        selectedCustomer.id
      };transaction_date:>=:${format(
        fromDate,
        "yyyy-MM-dd"
      )};transaction_date:<=:${format(toDate, "yyyy-MM-dd")}`;
      const res = await requestGet({
        fullUrl: "api/customer/pointtransactionhistory/data",
        params: {
          page: 1,
          take: 1000,
          filter,
          order: "transaction_date",
          order_method: "DESC",
        },
        onSuccess: ({ pagination, message, data }) => {
          setListPointHistory(data);
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    }
  };

  const handleLoadSalesHistory = async ({ fromDate, toDate }) => {
    setListSalesHistory([]);
    if (selectedCustomer && selectedCustomer.id) {
      const filter = `data_sales.customer_id:=:${
        selectedCustomer.id
      };data_sales.transaction_date:>=:${format(
        fromDate,
        "yyyy-MM-dd"
      )};data_sales.transaction_date:<=:${format(toDate, "yyyy-MM-dd")}`;

      const res = await requestGet({
        fullUrl: "api/sales/salesitem/data",
        params: {
          page: 1,
          take: 1000,
          filter,
          order: "created_at",
          order_method: "DESC",
          with_sales: 1,
          with_product: 1,
          with_doctor: 1,
          with_nurse_1: 1,
          with_nurse_2: 1,
          with_nurse_3: 1,
          with_therapist_1: 1,
          with_therapist_2: 1,
          with_therapist_3: 1,
        },
        onSuccess: ({ pagination, message, data }) => {
          // sort data by data_sales.transaction_date DESC
          data.sort((a, b) => {
            if (a.data_sales.transaction_date < b.data_sales.transaction_date) {
              return 1;
            }
            if (a.data_sales.transaction_date > b.data_sales.transaction_date) {
              return -1;
            }
            return 0;
          });
          setListSalesHistory(data);
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    }
  };

  const handleLoadPackageHistory = async ({ fromDate, toDate }) => {
    setListFreeProductHistory([]);
    if (selectedCustomer && selectedCustomer.id) {
      let filter = "";
      if (fromDate && toDate) {
        filter = `customer_id:=:${
          selectedCustomer.id
        };data_source_sales_item.data_sales.transaction_date:>=:${format(
          fromDate,
          "yyyy-MM-dd"
        )};data_source_sales_item.data_sales.transaction_date:<=:${format(
          toDate,
          "yyyy-MM-dd"
        )}`;
      } else {
        filter = `customer_id:=:${selectedCustomer.id}`;
      }
      const res = await requestGet({
        fullUrl: "api/customer/customerfreeproduct/data",
        params: {
          page: 1,
          take: 1000,
          filter,
          order: "created_at",
          order_method: "DESC",
          with_source_sales_item: 1,
          with_product: 1,
          with_usage_sales_item: 1,
        },
        onSuccess: ({ pagination, message, data }) => {
          const newData = sortData(data);
          setListFreeProductHistory(newData);
        },
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
      });
    }
  };

  const sortData = (data) => {
    // if data_source_sales_item.data_sales.transaction_date exist,
    // sort by data_source_sales_item.data_sales.transaction_date DESC on first level, and expired_date DESC on second level
    // else sort by created_at DESC on first level, and expired_date DESC on second level
    return data.sort((a, b) => {
      if (
        a.data_source_sales_item &&
        b.data_source_sales_item &&
        a.data_source_sales_item.data_sales &&
        b.data_source_sales_item.data_sales
      ) {
        if (
          a.data_source_sales_item.data_sales.transaction_date <
          b.data_source_sales_item.data_sales.transaction_date
        ) {
          return 1;
        }
        if (
          a.data_source_sales_item.data_sales.transaction_date >
          b.data_source_sales_item.data_sales.transaction_date
        ) {
          return -1;
        }
      }
      if (a.created_at < b.created_at) {
        return 1;
      }
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.expired_date < b.expired_date) {
        return 1;
      }
      if (a.expired_date > b.expired_date) {
        return -1;
      }
      return 0;
    });
  };

  const handleEditFreeProductProcedure = (data) => {
    setFreeProductEditorData(data);
    setVisibleFreeProductEditor(true);
  };

  const handleAddFreeProductProcedure = () => {
    setFreeProductEditorData(null);
    setVisibleFreeProductEditor(true);
  };

  const handleSaveFreeProduct = async (data) => {
    const body = {
      customer_id: selectedCustomer.id,
      product_id: data.data_product.id,
      quantity: data.quantity,
      expired_date: format(data.expired_date, "yyyy-MM-dd"),
      description: data.description,
      type: data.type ?? 1,
    };
    if (data.id && data.id > 0) {
      body["id"] = data.id;
    }

    return requestPost({
      fullUrl: "api/customer/customerfreeproduct/save",
      body,
      onSuccess: ({ pagination, message, data }) => {
        // convert expired date to Date object
        data.expired_date = parse(data.expired_date, "yyyy-MM-dd", new Date());
        uiContext.showSuccessMsg("Success", message);
        setVisibleFreeProductEditor(false);
        // check if data already exist by comparing id
        const index = listFreeProduct.findIndex((item) => item.id === data.id);
        if (index >= 0) {
          const newList = [...listFreeProduct];
          newList[index] = data;
          setListFreeProduct(newList);
        } else {
          setListFreeProduct([data, ...listFreeProduct]);
        }
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleRemoveFreeProduct = async (data) => {
    const idToDelete = data.id;
    return await requestDelete({
      fullUrl: "api/customer/customerfreeproduct/delete",
      ids: [idToDelete],
      onSuccess: ({ data }) => {
        uiContext.showSuccessMsg(
          "Success",
          "Free product removed successfully"
        );
        setVisibleFreeProductEditor(false);
        // remove from listFreeProduct where id is equal to data.id
        const newList = listFreeProduct.filter(
          (item) => item.id !== idToDelete
        );
        setListFreeProduct(newList);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const handleRemoveFreeProductProcedure = (data) => {
    confirmDialog({
      acceptClassName: "p-button-danger",
      message: "You are about to remove this free product, continue?",
      header: "Remove",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        handleRemoveFreeProduct(data);
      },
    });
  };

  return (
    <div className={`col-start-start px-4 gap-4 w-100`}>
      <ConfirmDialog />
      <CustomerSelectorDialog
        visible={visibleCustomerSelector}
        onHide={() => {
          setVisibleCustomerSelector(false);
        }}
        onConfirm={(e, data) => {
          if (data && data.length > 0) {
            handleOnChangeCustomer(data[0]);
            setVisibleCustomerSelector(false);
          }
        }}
      />
      <FreeProductEditorDialog
        visible={visibleFreeProductEditor}
        onHide={() => {
          setVisibleFreeProductEditor(false);
        }}
        loading={loading}
        data={freeProductEditorData}
        onConfirm={handleSaveFreeProduct}
        onRemove={handleRemoveFreeProductProcedure}
      />
      <div></div>
      <div className="elevated-card-tight-vertical col-start-start w-full gap-4">
        <div className="row-start-center w-full gap-4">
          <Button
            icon="pi pi-search"
            label="Change"
            loading={loading}
            onClick={(e) => {
              e.preventDefault();
              setVisibleCustomerSelector(true);
            }}
          />
          {selectedCustomer && selectedCustomer.code && (
            <div className="row-start-center gap-1">
              <i className="pi pi-hashtag  text-slate-500" />
              <span className="font-semibold text-lg text-slate-500">
                {selectedCustomer ? `(${selectedCustomer.code})` : ""}
              </span>
            </div>
          )}
          <span className="font-semibold text-lg text-slate-500">
            {selectedCustomer ? selectedCustomer.name : "-"}
          </span>
        </div>
        <ProfileData data={selectedCustomer} />
      </div>
      <div className="row-start-start gap-4 w-full">
        <div className="elevated-card-tight-vertical col-start-start gap-4 w-full">
          <ListFreeProduct
            title={"REWARD & REDEEM"}
            data={listFreeProduct.filter((item) => item.type !== 2)}
            loading={loading}
            onLoad={() => handleLoadFreeProducts({ showExpired: false })}
            onLoadAll={() => handleLoadFreeProducts({ showExpired: true })}
            onAdd={handleAddFreeProductProcedure}
            onEdit={handleEditFreeProductProcedure}
          />
        </div>
        <div className="elevated-card-tight-vertical col-start-start gap-4 w-full">
          <ListFreeProduct
            title={"CUSTOMER PACKAGE"}
            data={listFreeProduct.filter((item) => item.type === 2)}
            loading={loading}
            onLoad={() => handleLoadFreeProducts({ showExpired: false })}
            onLoadAll={() => handleLoadFreeProducts({ showExpired: true })}
            // onAdd={handleAddFreeProductProcedure}
            onEdit={handleEditFreeProductProcedure}
          />
        </div>
      </div>
      <div className="elevated-card-tight-vertical row-start-start w-full">
        <TabView className="w-full">
          <TabPanel leftIcon="pi pi-history mr-2" header="Point History">
            <PointHistory
              data={listPointHistory}
              onLoad={handleLoadPointHistory}
              loading={loading}
            />
          </TabPanel>
          <TabPanel leftIcon="pi pi-history mr-2" header="Sales History">
            <SalesHistory
              data={listSalesHistory}
              onLoad={handleLoadSalesHistory}
              loading={loading}
            />
          </TabPanel>
          <TabPanel leftIcon="pi pi-history mr-2" header="Package History">
            <PackageHistory
              data={listFreeProductHistory.filter((item) => item.type === 2)}
              onLoad={handleLoadPackageHistory}
              loading={loading}
            />
          </TabPanel>
          <TabPanel leftIcon="pi pi-history mr-2" header="Reward History">
            <RewardHistory
              data={listFreeProductHistory.filter((item) => item.type !== 2)}
              onLoad={handleLoadPackageHistory}
              loading={loading}
            />
          </TabPanel>
        </TabView>
      </div>
      <div></div>
    </div>
  );
};

export default CustomerDataPage;
