import {
  TableAutoEditorElementTextPlain,
  TableAutoEditorElementTextPassword,
  TableAutoEditorElementTextDropDown,
  TableAutoEditorElementNumberPlain,
  TableAutoEditorElementNumberDropDown,
  TableAutoEditorElementBooleanPlain,
  TableAutoEditorElementDate,
} from "../components/complex/crud/TableAutoEditorElementComponent";

const generateTableAutoEditorElement = ({ config }) => {
  switch (config.type) {
    case "text_plain":
      return TableAutoEditorElementTextPlain({ config });

    case "text_password":
      return TableAutoEditorElementTextPassword({ config });

    case "text_dropdown":
      return TableAutoEditorElementTextDropDown({ config });

    case "number_plain":
      return TableAutoEditorElementNumberPlain({ config });

    case "number_dropdown":
      return TableAutoEditorElementNumberDropDown({ config });

    case "boolean_plain":
      return TableAutoEditorElementBooleanPlain({ config });

    case "date":
      return TableAutoEditorElementDate({ config });

    case "date_time":
      config.time = true;
      return TableAutoEditorElementDate({ config });

    case "date_month":
      config.month = true;
      return TableAutoEditorElementDate({ config });

    case "date_year":
      config.year = true;
      return TableAutoEditorElementDate({ config });
  }

  return undefined;
};

export default generateTableAutoEditorElement;
