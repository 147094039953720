const isCashier = (type) => {
  return type === 8;
};
const isSuperAdmin = (type) => {
  return type === 1;
};
const isAdmin = (type) => {
  return type === 4;
};
const isDoctor = (type) => {
  return type === 5;
};
const isNurse = (type) => {
  return type === 10;
};
const isTherapist = (type) => {
  return type === 9;
};
const isBC = (type) => {
  return type === 3;
};
const isOwner = (type) => {
  return type === 2;
};

export {
  isCashier,
  isSuperAdmin,
  isAdmin,
  isDoctor,
  isNurse,
  isTherapist,
  isBC,
  isOwner,
};
