import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaQrcode } from "react-icons/fa6";
import { format as dateFnsFormat, parse } from "date-fns";
import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";
import UiContext from "../store/ui-context";
import ListDisplaySingle from "../pages/dashboard/transaksi/sales/sales-components/ListDisplaySingle";
const CryptoJS = require("crypto-js");

const title = "Display Selector";

const CustomDisplaySelector = (props) => {
  const { visible, data, onHide, onConfirm } = props;

  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  const handleConfirm = (event) => {};

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end pt-4">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Confirm"
          icon="pi pi-save"
          // loading={loading}
          onClick={handleConfirm}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "65rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="row-center-center w-100 gap-4 pt-2 flex-wrap">
          {data.map((display, index) => (
            <ListDisplaySingle
              width="12"
              key={display.id}
              {...display}
              onClick={(event) => {
                onConfirm(display);
              }}
            />
          ))}
        </div>
        {/* {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )} */}
      </div>
    </Dialog>
  );
};

export default CustomDisplaySelector;
