import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { checkToken } from "../../store/auth-slice";
import { logout } from "../../store/auth-slice";

let didInit = false;

const ProtectedRouteUser = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const permissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    if (!didInit) {
      didInit = true;

      if (user) {
        dispatch(checkToken());
      }
    }
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!permissions.includes("view userpage")) {
    dispatch(logout());
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRouteUser;
