import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { useState } from "react";

const FilterComponentDate = (props) => {
  const { onChangeFilterValue, onEnter, field, or, and } = props;

  const operators = [
    { icon: "pi pi-chevron-left", value: "less-than" },
    { icon: "pi pi-ellipsis-h", value: "between" },
    { icon: "pi pi-chevron-right", value: "greater-than" },
  ];

  const [operator, setOperator] = useState(operators[1].value);
  const [value, setValue] = useState(new Date());

  const genereateFilterValue = (v) => {
    if (operator === "between") {
      return v;
    } else if (Array.isArray(v)) return v;
    return [v];
  };

  const handleChangeOperator = (event) => {
    if (event.value && event.value != null) {
      setOperator(event.value);
    } else {
      setOperator(operators[1].value);
    }

    const filterValue = genereateFilterValue(value);
    if (or) {
      onChangeFilterValue({ value: filterValue, operator: event.value });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: filterValue,
        operator: event.value,
      });
    }
  };

  const handleInputChange = (event) => {
    setValue(event.value);
    const filterValue = genereateFilterValue(event.value);
    if (or) {
      onChangeFilterValue({ value: filterValue, operator });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: filterValue,
        operator: operator,
      });
    }
  };

  return (
    <div className="row-start-center w-full">
      <SelectButton
        className="min-w-[8rem]"
        value={operator}
        onChange={(e) => handleChangeOperator(e)}
        itemTemplate={(option) => <i className={option.icon}></i>}
        optionLabel="value"
        options={operators}
      />
      <Calendar
        className="grow"
        showIcon
        value={value}
        onChange={(e) => handleInputChange(e)}
        selectionMode={operator === "between" ? "range" : "single"}
        readOnlyInput
      />
    </div>
  );
};

export default FilterComponentDate;
