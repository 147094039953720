const StockInConfigs = {
  master: {
    title: "Stock In",
    labelNew: "*New Stock In*",
    apiName: "stock/stockin",
    orderBy: "id",
    orderMethod: "DESC",
    additionalParams: {
      with_warehouse: 1,
      with_stock_in_details: 1,
    },
  },
  detail: {
    detail_field: "data_stock_in_details",
    columns: [
      // add_arsip_configs
      {
        name: "Stock In",
        field: "stock_in_id",
        type: "object",
        object_name: "data_stock_in_id",
        object_field: "number",
        editor: false,
        table: false,
        filter: false,
      },
      {
        name: "Product",
        field: "product_id",
        type: "object",
        object_name: "data_product",
        object_field: "name",
        editor: true,
        table: true,
        filter: true,
      },
      {
        name: "Quantity",
        field: "quantity",
        type: "number_plain",
        editor: true,
        align: "right",
        table: true,
      },
      {
        name: "Conversion",
        field: "conversion",
        type: "number_plain",
        table: false,
        filter: false,
        editor: false,
      },
    ],
  },
  filters: [
    {
      name: "Transaction Date",
      field: "transaction_date",
      type: "date_range",
      format: "dd/MM/yyyy",
      prime_format: "dd/mm/yy",
      default_value: [new Date(), new Date()],
      range: true,
    },
  ],
  columns: [
    // add_configs
    {
      name: "Warehouse",
      field: "warehouse_id",
      type: "object",
      object_name: "data_warehouse",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Number",
      field: "number",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Transaction Date",
      field: "transaction_date",
      type: "date",
      align: "left",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
  ],
};

export default StockInConfigs;
