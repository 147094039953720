import { Button } from "primereact/button";
import React, { Fragment } from "react";
import { Divider } from "primereact/divider";
import { format } from "date-fns";

const ListFreeProduct = (props) => {
  const { data, loading, onLoad, onLoadAll, onAdd, onEdit, title } = props;

  const CustomerFreeProductElement = (props) => {
    const { data } = props;
    const { data_product } = data;

    return (
      <div
        className="row-start-center rounded gap-2 cursor-pointer hover:bg-slate-50 w-full p-2 active:translate-y-1"
        onClick={(e) => {
          if (onEdit) onEdit(data);
        }}
      >
        <div className="row-center-center bg-emerald-400 rounded-full w-[2.2rem] h-[2.2rem]">
          <span className="text-white text-xs font-bold">
            {data.quantity} x
          </span>
        </div>
        <div className="col-start-start rounded">
          <span className="font-semibold text-slate-600">
            {data_product.name}
          </span>
          <div className="row-start-start gap-2">
            <span className="text-xs font-semibold text-slate-400">
              Expired On:
            </span>
            <span className="text-xs font-bold text-red-400">
              {data.expired_date
                ? format(data.expired_date, "dd/MM/yyyy")
                : "-"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-start-start gap-4 w-full">
      <div className="row-start-center w-full gap-2">
        {onAdd && (
          <Button
            className="max-w-[1.8rem] max-h-[1.8rem]"
            rounded
            loading={loading}
            icon="pi pi-plus"
            onClick={(e) => {
              if (onAdd) onAdd();
            }}
          />
        )}
        <Button
          className="max-w-[1.8rem] max-h-[1.8rem]"
          rounded
          loading={loading}
          icon="pi pi-refresh"
          onClick={(e) => {
            if (onLoad) onLoad();
          }}
        />
        <Button
          className="max-w-[1.8rem] max-h-[1.8rem]"
          rounded
          loading={loading}
          icon="pi pi-sync"
          onClick={(e) => {
            if (onLoadAll) onLoadAll();
          }}
        />
        <span className="font-bold text-sm text-slate-500">{title}</span>
      </div>
      <div className="col-start-start w-full gap-2">
        {data.map((freeProduct, index) => {
          return (
            <Fragment key={freeProduct.id}>
              <CustomerFreeProductElement data={freeProduct} />
              {index < data.length - 1 && <Divider className="!m-0" />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ListFreeProduct;
