import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { Paginator } from "primereact/paginator";

import configs from "./StockInConfigs";
import useMDArsipPageControl from "../../../../custom_hooks/complex/useMDArsipPageControl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateTextReformat from "../../../../utils/DateTextReformat";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";

const customColumns = [];
const customFilters = [];

export const generateTabLabel = (row) => {
  return `Nomor Nota: ${row.nomor_nota}`;
};

const newTab = false;

const additionalParams = configs.master.additionalParams;
const orderBy = configs.master.orderBy;
const orderMethod = configs.master.orderMethod;
const apiName = configs.master.apiName;
const title = configs.master.title;
const columns = generateTableConfigs(configs.columns);

const apiUrl = "stock/stockin";

const StockInArsipPage = (props) => {
  const { addNewEditor } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname;

  const {
    // UIs
    toast,
    // states
    paging,
    loading,
    listData,
    selectedRows,
    //methods
    elements,
    generateSelectorDialog,
    handleReload,
    handleOnPage,
    // handleAddNew,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
  } = useMDArsipPageControl({
    title,
    apiName,
    additionalParams,
    orderBy,
    orderMethod,
    addNewEditor,
    generateTabLabel,
    configFilters: configs.filters,
    customFilters,
    // additionalFilter,
  });

  const permissions = useSelector((state) => state.auth.permissions);

  const customColumns = [];

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const handleAddNew = () => {
    if (newTab) {
      const url = `${process.env.REACT_APP_MY_URL}/${apiUrl}/new`;
      window.open(url, "_blank").focus();
    } else {
      navigate(`/${apiUrl}/new`);
    }
  };

  const handleCustomEdit = (row) => {
    if (newTab) {
      const url = `${process.env.REACT_APP_MY_URL}/${apiUrl}/${row.id}`;
      window.open(url, "_blank").focus();
    } else {
      navigate(`/${apiUrl}/${row.id}`);
    }
  };

  return (
    <div className={`col-start-start px-4 gap-4 w-100`}>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div></div>
      <div className="elevated-card w-100 gap-3 row-between-center">
        <div className="row-start-center gap-3 wrap">
          {elements.transaction_date}
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-trash"
            size="small"
            severity="danger"
            onClick={handleDeleteMultiple}
            rounded
            outlined
          />
          <Button
            icon="pi pi-plus"
            size="small"
            severity="success"
            onClick={handleAddNew}
            rounded
            outlined
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={handleReload}
            rounded
            outlined
          />
        </div>
      </div>
      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            showControlButton
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={columns}
            loading={loading}
            value={listData}
            onEditRow={async (row) => handleCustomEdit(row)}
            onDeleteRow={(row) => handleDelete(row)}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>
    </div>
  );
};

export default StockInArsipPage;
