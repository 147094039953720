import classes from "./ButtonIcon.module.css";
import React from "react";
import { Button } from "primereact/button";

const ButtonIcon = (props, { className }) => {
  const width = props.width ?? "30px";

  return (
    <Button
      className={`${classes.wrapper} ${className} row-center-center`}
      style={{ width: width }}
      rounded
      text
    >
      <div
        className={`${classes.iconWrapper} row-center-center`}
        style={{
          width: width,
        }}
      >
        {props.icon}
      </div>
    </Button>
  );
};

export default ButtonIcon;
