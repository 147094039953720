import OutstandingSalesSection from "./sales-components/OutstandingSalesSection";
import ListDisplaySection from "./sales-components/ListDisplaySection";
import SearchProductSection from "./sales-components/SearchProductSection";
import ListProductSection from "./sales-components/ListProductSection";
import CustomerSection from "./sales-components/CustomerSection";
import InsertSection from "./sales-components/InsertSection";
import ListSalesDetailSection from "./sales-components/ListSalesDetailSection";
import InvoiceControlSection from "./sales-components/InvoiceControlSection";
import InvoiceSummarySection from "./sales-components/InvoiceSummarySection";
import PaymentControlSection from "./sales-components/PaymentControlSection";

import useSalesPage from "./useSalesPage";
import { confirmDialog } from "primereact/confirmdialog";
import { useContext, useState } from "react";
import ChangeDialog from "../../../../dialogs/ChangeDialog";
import PrintSalesReceiptDialog from "../../../../dialogs/PrintSalesReceiptDialog";
import UiContext from "../../../../store/ui-context";

const SalesPage = () => {
  const uiContext = useContext(UiContext);

  const [visiblePrintReceiptDialog, setVisiblePrintReceiptDialog] =
    useState(false);
  const [visibleChangeDialog, setVisibleChangeDialog] = useState(false);
  const [change, setChange] = useState(0);

  const {
    // states
    outstandingInvoices,
    setOutstandingInvoices,
    displays,
    setDisplays,
    sales,
    setSales,
    products,
    setProducts,
    newProducts,
    setNewProducts,
    loading,
    loadingOutstanding,
    loadingProduct,
    // functions
    getSales,
    getOutstandingInvoice,
    getDisplay,
    getProduct,
    reloadListProduct,
    reloadListPenjualan,
    changeSalesItemQuantity,
    removeSalesItem,
    addSalesItemByProduct,
    save,
    updateTotalValue,
    saveAndChangeToNewCustomer,
    changeCustomer,
    newSalesProcedure,
    changeSalesItemDiscount,
    changeSalesItemDoctor,
    changeSalesItemNurse,
    changeSalesItemTherapist,
    addPayment,
    removePayment,
    removeDiscount,
    addSalesDiscount,
    voidSales,
    onChangeNote,
    addSalesItemByCustomerPackage,
    addCustomerRedeem,
  } = useSalesPage();

  const handleDisplayChange = (display) => {
    if (display) {
      reloadListProduct(display);
    }
  };

  const handleChangeQuantity = ({ quantity, row }) => {
    changeSalesItemQuantity({ quantity, salesItem: row });
  };

  const handleSalesChange = (sales) => {
    if (sales) {
      reloadListPenjualan(sales);
    }
  };

  const handleSalesDelete = (salesItem) => {
    confirmDialog({
      message: "You are about to remove a product, continue?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        removeSalesItem(salesItem);
      },
    });
  };

  const handleSelectProduct = (product) => {
    addSalesItemByProduct(product);
  };

  const handleNewOutStanding = () => {
    newSalesProcedure();
  };

  const handleChangeCustomer = (newCustomer) => {
    changeCustomer(newCustomer);
    // setSales({ ...sales, data_customer: newCustomer });
  };

  const handleSave = () => {
    save();
  };

  const handleRefreshOutstanding = () => {
    getOutstandingInvoice();
  };

  const handleChangeItemDiscount = ({ discount, salesItem }) => {
    changeSalesItemDiscount({ discount, salesItem });
  };

  const handleChangeDoctor = ({ doctor, salesItem }) => {
    changeSalesItemDoctor({ doctor, salesItem });
  };

  const handleChangeNurse = ({ nurse, salesItem }) => {
    changeSalesItemNurse({ nurse, salesItem });
  };

  const handleChangeTherapist = ({ therapist, salesItem }) => {
    changeSalesItemTherapist({ therapist, salesItem });
  };

  const handlePay = async ({ payment, amount }) => {
    setChange(0);
    const change = await addPayment({ payment, amount });
    if (change >= 0) {
      setChange(change);
      setVisiblePrintReceiptDialog(true);
      setVisibleChangeDialog(true);
    }
  };

  const handleReload = () => {
    getSales({ id: sales.id });
  };

  const handleProductFilter = (filter) => {
    getProduct({ filterText: filter });
  };

  const handleRemovePayment = ({ salesPayment }) => {
    confirmDialog({
      message: "You are about to remove a payment, continue?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        removePayment({ salesPayment });
      },
    });
  };

  const handleRemoveDiscount = ({ salesDiscount }) => {
    confirmDialog({
      message: "You are about to remove a discount, continue?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        removeDiscount({ salesDiscount });
      },
    });
  };

  const handleChangeNote = ({ note }) => {
    onChangeNote({ note });
  };

  const handleChangeSalesDiscount = ({ discount }) => {
    addSalesDiscount({ discount });
    // changeSalesDiscount({ discount });
  };

  const handleVoidSalesInvoice = async () => {
    const success = await voidSales();
    if (success) {
      uiContext.showSuccessMsg("Success", "Sales successfully voided");
    } else {
      uiContext.showErrorMsg("Error", "Failed to void sales");
    }
  };

  const handleAddCustomerPackage = (listCustomerFreeProduct) => {
    if (listCustomerFreeProduct && listCustomerFreeProduct.length > 0) {
      addSalesItemByCustomerPackage({
        customerFreeProduct: listCustomerFreeProduct[0],
      });
    }
  };

  const handleAddRedeem = (listCustomerFreeProduct) => {
    if (listCustomerFreeProduct && listCustomerFreeProduct.length > 0) {
      addSalesItemByCustomerPackage({
        customerFreeProduct: listCustomerFreeProduct[0],
      });
    }
  };

  return (
    <div
      className={`row-start-start p-4 gap-4 w-100`}
      style={{ minHeight: "calc(100vh - var(--height-nav-bar))" }}
    >
      <PrintSalesReceiptDialog
        visible={visiblePrintReceiptDialog}
        onHide={(e) => {
          getOutstandingInvoice();
          newSalesProcedure();
          setVisiblePrintReceiptDialog(false);
        }}
        data={sales}
        change={change}
      />

      <ChangeDialog
        visible={visibleChangeDialog}
        onHide={(e) => setVisibleChangeDialog(false)}
        value={change}
      />

      <div
        className={`col-start-start gap-4 h-full grow`}
        style={{
          minHeight: "calc(100vh - 2 * var(--height-nav-bar) - 1.2rem)",
        }}
      >
        <OutstandingSalesSection
          data={outstandingInvoices}
          selected={sales}
          setSelected={setSales}
          onChange={handleSalesChange}
          onNew={handleNewOutStanding}
          onRefresh={handleRefreshOutstanding}
          loading={loadingOutstanding}
        />
        <div
          className={`elevated-card-tight-vertical col-start-center w-full !px-4 gap-5 grow`}
          style={{ height: "100%" }}
        >
          <ListDisplaySection
            data={displays}
            onChange={handleDisplayChange}
            loading={loadingProduct}
          >
            <SearchProductSection
              loading={loadingProduct}
              onFilter={handleProductFilter}
            />
          </ListDisplaySection>
          <ListProductSection
            data={products}
            onChange={handleSelectProduct}
            loading={loading}
          />
        </div>
      </div>
      <div className={`col-start-start gap-4 h-full min-w-[24rem] w-[24rem]`}>
        <div className={`elevated-card-tight-vertical col-start-start w-full`}>
          <CustomerSection
            sales={sales}
            data={sales && sales.data_customer ? sales.data_customer : {}}
            width={"w-full"}
            onChangeCustomer={handleChangeCustomer}
            onSaveNewCustomer={saveAndChangeToNewCustomer}
            loading={loading}
          />
        </div>

        <div
          className={`elevated-card-tight-vertical col-start-start gap-2 w-full h-full !px-4`}
        >
          <InsertSection
            data={sales}
            width={"w-full"}
            loading={loading}
            onAddCustomerPackage={handleAddCustomerPackage}
            onAddRedeem={handleAddRedeem}
          />
          <ListSalesDetailSection
            data={sales.data_sales_items}
            width={"w-full"}
            onChange={handleSalesChange}
            onDelete={handleSalesDelete}
            onChangeQuantity={handleChangeQuantity}
            loading={loading}
            onChangeDiscount={handleChangeItemDiscount}
            onChangeDoctor={handleChangeDoctor}
            onChangeNurse={handleChangeNurse}
            onChangeTherapist={handleChangeTherapist}
          />
          <InvoiceControlSection
            data={sales}
            width={"w-full"}
            loading={loading}
            onVoidSales={handleVoidSalesInvoice}
            onChangeSalesDiscount={handleChangeSalesDiscount}
            onChangeNote={handleChangeNote}
          />
          {sales.notes ? (
            <div className="row-center-center w-full text-sm text-gray-500 font-semibold">
              Note: {sales.notes}
            </div>
          ) : null}
          <InvoiceSummarySection
            data={sales}
            width={"w-full"}
            loading={loading}
            onRemovePayment={handleRemovePayment}
            onRemoveDiscount={handleRemoveDiscount}
          />
          <PaymentControlSection
            data={sales}
            width={"w-full"}
            loading={loading}
            onConfirm={handleSave}
            onReload={handleReload}
            onPay={handlePay}
          />
        </div>
      </div>
    </div>
  );
};

export default SalesPage;
