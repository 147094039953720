import classes from "./CustomerEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";
// add_import_selector_dialog
import BranchSelectorDialog from "../../../../dialogs/selectors/BranchSelectorDialog";

import configs from "./CustomerConfigs";
import BCSelectorDialog from "../../../../dialogs/selectors/BCSelectorDialog";

const title = configs.dialog.title;

const CustomerEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    const newValue = { ...props.value };
    if (newValue.data_user) {
      const password = newValue.data_user.password.trim();
      if (password.length <= 0) {
        delete newValue.data_user.password;
        delete newValue.data_user.cpassword;
      }
    }
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "branch_id email phone_1 join_date birth_date",
      mustEqual: ["data_user.password data_user.cpassword"],
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "branch_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <BranchSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "bc_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <BCSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{
        width: "65rem",
      }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} row-start-start w-100`}>
          <div className="col-start-start w-1/2 gap-3">
            <div className={`row-start-start w-100`}>
              <div className="col-start-start w-100 gap-3">
                {elements.branch_id}
                {elements.bc_id}
                {elements.image}
              </div>
              <Divider className="h-[23.5rem]" layout="vertical" />
              <div className="col-start-start w-100 gap-3">
                {elements.code}
                {elements.name}
                {elements["data_user.username"]}
                {elements["data_user.password"]}
                {elements["data_user.cpassword"]}
              </div>
            </div>
            {elements.address}
          </div>

          <Divider className="h-[28.5rem]" layout="vertical" />
          <div className="col-start-start w-1/4 gap-3">
            {elements.email}
            {elements.phone_1}
            {elements.phone_2}
            {elements.join_date}
            {elements.birth_date}
            {elements.notes}
          </div>
          <Divider className="h-[28.5rem]" layout="vertical" />
          <div className="col-start-start w-1/4 gap-3">
            {elements.occupation}
            {elements.reference}
            {elements.medical_history}
            {elements.skin_concern}
            {elements.gender}
            {elements.martial_status}
          </div>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CustomerEditorDialog;
