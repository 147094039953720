import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

const title = "Change Sales Date";

const SalesChangeDateDialog = (props) => {
  const { data, onConfirm, visible, onHide, loading } = props;

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (props.visible) {
      setDate(new Date(data.transaction_date));
    }
  }, [props.visible]);

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[24rem]`}
      onHide={props.onHide}
    >
      <div className="row-start-start w-full gap-3 pt-3 pb-3">
        <div className="row-start-center w-full gap-1">
          <Calendar
            className="w-[14rem]"
            value={date}
            showIcon
            showButtonBar
            onChange={(e) => setDate(e.value)}
            dateFormat={"dd/mm/yy"}
            showTime={true}
            hourFormat="24"
          />
          <Button
            className="w-[6rem]"
            label="Save"
            icon="pi pi-save"
            loading={loading}
            onClick={() => {
              onConfirm(date);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SalesChangeDateDialog;
