import React, { useEffect } from "react";
import TableAutoComponent from "../components/complex/crud/TableAutoComponent";
import FilterComponent from "../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../utils/FilterConfigGenerator";
import generateTableConfigs from "../utils/TableConfigGenerator";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Tag } from "primereact/tag";

import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";

const title = "Total Discount";
const apiName = "masterdata/discount";
const orderBy = "id";
const orderMethod = "ASC";
const additionalFilter = "discount_category:=:2";
const additionalParams = {};

const customColumns = [
  {
    field: "type",
    component: (rowData) => {
      if (rowData.type === 1)
        return <Tag icon="pi pi-money-bill" severity="info" value="Value" />;
      if (rowData.type === 2)
        return (
          <Tag icon="pi pi-percentage" severity="info" value="Percentage" />
        );
      if (rowData.type === 3)
        return <Tag icon="pi pi-tag" severity="info" value="Unit" />;

      return "";
    },
  },
];

const configs = [
  // add_configs
  {
    name: "Type",
    field: "type",
    type: "text_plain",
    table: true,
  },
  {
    name: "Name",
    field: "name",
    type: "text_plain",
    table: true,
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const AddTotalDiscountDialog = (props) => {
  const single = props.single ?? true;

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    additionalFilter,
    visible: props.visible,
  });

  // // for testing only
  // useEffect(() => {
  //   setListData([
  //     {
  //       id: 1,
  //       name: "Discount 10%",
  //       type: 2,
  //       value: 10,
  //     },
  //     {
  //       id: 2,
  //       name: "Discount 100K",
  //       type: 1,
  //       value: 100000,
  //     },
  //     {
  //       id: 3,
  //       name: "Discount Unit",
  //       type: 3,
  //       value: 10000,
  //     },
  //   ]);
  // }, []);
  // // end for testing only

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Pilih"
          icon="pi pi-check"
          onClick={(e) => {
            const refinedValue = refineValue(selectedRows);
            props.onConfirm(e, refinedValue);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[35rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-3`}>
        <TableAutoComponent
          minWidth="30rem"
          singleMode={single}
          selection={selectedRows}
          onSelectionChange={handleSelectionChange}
          configs={tableConfigs}
          loading={loading}
          value={listData}
          customColumns={customColumns}
        />
      </div>
    </Dialog>
  );
};

export default AddTotalDiscountDialog;
