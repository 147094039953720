import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../store/ui-slice";
import ReportSection from "./ReportSection";
import ReportDailySales from "./reportsales/ReportDailySales";

const title = "Laporan";

const ReportMainPage = () => {
  const dispatch = useDispatch();

  const [showReportDailySales, setShowReportDailySales] = useState(false);

  const dataSales = {
    id: "0",
    title: "Sales",
    details: [
      {
        id: "0-1",
        title: "Summary Sales Report (POS)",
        description: "Summarize daily sales activity",
        url: "/report/zzzz",
        on_click: (e) => setShowReportDailySales(true),
        // class_color: "text-red-300",
      },
    ],
  };

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  return (
    <section className="row-start-start w-100 wrap p-4 gap-4">
      <ReportDailySales
        visible={showReportDailySales}
        onHide={(e) => setShowReportDailySales(false)}
      />

      <div className="col-start-start gap-5 grow">
        <ReportSection {...dataSales} key={dataSales.id} />
      </div>
      {/* <div className="col-start-start gap-5 grow"></div> */}
    </section>
  );
};

export default ReportMainPage;
