import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import calculateUnpayedAmount from "../../../../../utils/jelim/CalculateUnpayedAmount";
import calculateSubtotalDiscount from "../../../../../utils/jelim/CalculateSubtotalDiscount";

const InvoiceSummarySection = (props) => {
  const {
    width,
    data = {},
    onRemovePayment,
    onRemoveDiscount,
    loading,
  } = props;
  const {
    sub_total = 0,
    grand_total = 0,
    discount_total = 0,
    data_sales_discounts = [],
    data_sales_payments = [],
  } = data;
  const [unpayed, setUnpayed] = useState(0);

  const handleRemovePayment = (salesPayment) => {
    onRemovePayment({ salesPayment });
  };

  useEffect(() => {
    setUnpayed(calculateUnpayedAmount(data));
  }, [data, data_sales_payments, grand_total]);

  return (
    <div
      className={`col-start-center ${width} gap-1 bg-slate-100 rounded-md px-2 py-2`}
    >
      <div className={`row-between-center w-100`}>
        <span className="font-semibold text-slate-500">Sub Total</span>
        <span className="font-semibold">
          {sub_total.toLocaleString("id-ID")}
        </span>
      </div>
      <div className={`col-center-center w-100`}>
        {data_sales_discounts.map((discount, index) => {
          return (
            <div key={discount.id} className={`row-between-center w-100`}>
              <div className="row-start-center gap-1 pl-2">
                <Button
                  loading={loading}
                  className="max-h-[1rem] max-w-[1rem] !text-sm"
                  icon="pi pi-times"
                  text
                  onClick={(event) => {
                    event.preventDefault();
                    onRemoveDiscount({ salesDiscount: discount });
                  }}
                />
                <span className="font-semibold text-red-500">
                  {discount.name}
                </span>
              </div>

              <span className="font-semibold text-red-500">
                (
                {calculateSubtotalDiscount({
                  type: discount.type,
                  value: discount.value,
                  subtotal: sub_total,
                }).toLocaleString("id-ID")}
                )
              </span>
            </div>
          );
        })}
      </div>
      <div className={`row-between-center w-100 pt-1 pb-1`}>
        <span className="font-semibold text-lg text-slate-500">
          Grand Total
        </span>
        <span className="font-semibold text-lg">
          {grand_total.toLocaleString("id-ID")}
        </span>
      </div>
      {/* payments */}
      {
        <div className="col-start-start w-100">
          {data_sales_payments &&
            data_sales_payments.length > 0 &&
            data_sales_payments.map((payment, index) => {
              return (
                <div key={payment.id} className={`row-between-center w-100`}>
                  <div className="row-start-center gap-1 pl-2">
                    <Button
                      loading={loading}
                      className="max-h-[1rem] max-w-[1rem] !text-sm"
                      icon="pi pi-times"
                      text
                      onClick={(event) => {
                        event.preventDefault();
                        handleRemovePayment(payment);
                      }}
                    />
                    <span className="font-semibold text-blue-500">
                      {payment && payment.data_payment
                        ? payment.data_payment.name
                        : "-"}
                    </span>
                  </div>
                  <span className="font-semibold text-blue-500">
                    {payment.amount.toLocaleString("id-ID")}
                  </span>
                </div>
              );
            })}
        </div>
      }
      <div className={`row-between-center w-100 pt-1 pb-1`}>
        <span className="font-semibold text-md text-slate-500">Unpayed</span>
        <span className="font-semibold text-md text-slate-500">
          {unpayed.toLocaleString("id-ID")}
        </span>
      </div>
    </div>
  );
};

export default InvoiceSummarySection;
