import { subDays, addDays } from "date-fns";

const CustomerConfigs = {
  master: {
    title: "Customer",
    apiName: "customer/customer",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
      with_branch: 1,
      with_user: 1,
      with_bc: 1,
    },
  },
  dialog: {
    title: "Customer",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Branch",
      field: "branch_id",
      type: "object",
      object_name: "data_branch",
      object_field: "name",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "BC",
      field: "bc_id",
      type: "object",
      object_name: "data_bc",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Image",
      field: "image",
      default_value: null,
      type: "zirvu_file_manager",
      table: false,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Email",
      field: "email",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Occupation",
      field: "occupation",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Phone 1",
      field: "phone_1",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Phone 2",
      field: "phone_2",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Join Date",
      field: "join_date",
      type: "date",
      align: "center",
      table: false,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
    {
      name: "Birth Date",
      field: "birth_date",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
    },
    {
      name: "Notes",
      field: "notes",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Reference",
      field: "reference",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Medical History",
      field: "medical_history",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Skin Concern",
      field: "skin_concern",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Gender",
      field: "gender",
      type: "text_dropdown",
      default_value: "female",
      options: [
        { name: "Girl", value: "female" },
        { name: "Boy", value: "male" },
      ],
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Martial Status",
      field: "martial_status",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "User Name",
      field: "data_user.username",
      type: "text_plain",
      editor: true,
    },
    {
      name: "Password",
      field: "data_user.password",
      default_value: "",
      default_value_on_edit: true,
      type: "text_password",
      editor: true,
    },
    {
      name: "Konfirmasi Password",
      field: "data_user.cpassword",
      default_value: "",
      default_value_on_edit: true,
      type: "text_password",
      editor: true,
    },
    {
      name: "Address",
      field: "address",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
  ],
};

export default CustomerConfigs;
