const generateEditorConfigs = (tableConfigs) => {
  const editorConfigs = [];
  for (const config of tableConfigs) {
    // const { name, field, editor, type, options } = config;
    const { editor } = config;
    if (editor) {
      editorConfigs.push({
        ...config,
        // name: name,
        // field: field,
        // type: type,
        // options: options,
      });
    }
  }
  return editorConfigs;
};

export default generateEditorConfigs;
