import { Button } from "primereact/button";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { useContext, useEffect, useState } from "react";
import UiContext from "../../../../store/ui-context";
import { confirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";

const BirthdayRewardSetting = (props) => {
  const { setting, setSetting } = props;

  const uiContext = useContext(UiContext);
  const [rewards, setRewards] = useState([]);
  const [editingLevelId, setEditingLevelId] = useState(-1);
  const [showSelectorDialog, setShowSelectorDialog] = useState(false);
  const { loading, requestGet, requestPost } = useAPIRequest();

  useEffect(() => {
    if (setting) {
      loadBirthDayRewards(setting);
    }
  }, [setting]);

  const loadBirthDayRewards = async (setting) => {
    if (setting) {
      const listValue = JSON.parse(setting.value_long_text);
      const listProductId = [];
      for (const value of listValue) {
        for (const reward of value.rewards) {
          listProductId.push(reward.id);
        }
      }
      const filter = listProductId.map((id) => `id:=:${id}`).join("||");
      await requestGet({
        fullUrl: `/api/masterdata/product/data`,
        params: {
          filter,
          page: 1,
          take: 100,
          order: "name",
          order_method: "ASC",
        },
        onSuccess: ({ pagination, message, data }) => {
          setSetting(setting);
          const mapProduct = [];
          for (const product of data) {
            mapProduct[product.id] = product;
          }
          for (const value of listValue) {
            const newRewards = [];
            for (const reward of value.rewards) {
              newRewards.push(mapProduct[reward.id]);
            }
            value.rewards = newRewards;
          }
          setRewards(listValue);
        },
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
      });
    }
  };

  const saveReward = async (rewards) => {
    const rewardsTxt = JSON.stringify(rewards);
    const newSetting = { ...setting, value_long_text: rewardsTxt };
    return requestPost({
      fullUrl: `/api/setting/save`,
      body: newSetting,
      onSuccess: ({ pagination, message, data }) => {
        setSetting(data);
        setRewards(rewards);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteReward = (levelId, product) => {
    const newSetting = JSON.parse(JSON.stringify(rewards));
    const rewardLevel = newSetting.find((e) => e.level_id === levelId);
    const newRewards = rewardLevel.rewards.filter(
      (reward) => reward.id !== product.id
    );
    rewardLevel.rewards = newRewards;

    saveReward(newSetting);
  };

  const handleClickAddReward = async (e, levelId) => {
    setEditingLevelId(levelId);
    setShowSelectorDialog(true);
  };

  const handleConfirmAddReward = async (e, data) => {
    if (data && data.length > 0) {
      const product = data[0];
      const newRewards = JSON.parse(JSON.stringify(rewards));
      const rewardLevel = newRewards.find((e) => e.level_id === editingLevelId);
      if (rewardLevel) {
        rewardLevel.rewards.push(product);
      }
      const res = await saveReward(newRewards);
      if (res) {
        setShowSelectorDialog(false);
      }
    }
  };

  const handleClickRemoveReward = (e, level_id, product) => {
    confirmDialog({
      message: `Are you sure you want to remove "${product.name}"?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        deleteReward(level_id, product);
      },
    });
  };

  return (
    <>
      <ProductSelectorDialog
        visible={showSelectorDialog}
        onHide={() => setShowSelectorDialog(false)}
        onConfirm={handleConfirmAddReward}
      />
      <div className="col-start-start w-[25rem] hover:bg-indigo-50 p-3">
        <h1 className="font-bold text-slate-600">Birthday Reward</h1>
        <h2 className="font-semibold text-sm text-slate-600">
          Upcoming birthday reward for customers
        </h2>
        <div className="col-start-start w-full py-2">
          {rewards.map((level, index) => (
            <div
              key={`level-${level.level_id}-${index}`}
              className="col-start-start w-full pb-4 gap-1"
            >
              <Divider className="!m-0 !mb-1" />
              <span className="font-semibold text-slate-500 w-full">
                {level.name}
              </span>
              <div className="col-start-start w-full">
                {level.rewards.map((reward, rewardIndex) => (
                  <div
                    key={`reward-${rewardIndex}`}
                    className="row-between-center gap-4 w-full px-2 py-1 hover:bg-indigo-100 cursor-default"
                  >
                    <span className="text-sm font-semibold text-slate-500">
                      {reward.name}
                    </span>
                    <Button
                      icon="pi pi-times"
                      text
                      rounded
                      className="p-button-secondary !p-0 !w-[1.5rem] !h-[1.5rem]"
                      pt={{
                        icon: { className: "!text-[0.7rem]" },
                      }}
                      onClick={(e) =>
                        handleClickRemoveReward(e, level.level_id, reward)
                      }
                    />
                  </div>
                ))}
              </div>
              <Button
                className="!rounded-none !text-sm !h-[2.1rem] w-[12rem] text-left"
                loading={loading}
                label={`Add ${level.name} Reward`}
                icon="pi pi-plus"
                pt={{
                  icon: { className: "!text-[0.7rem]" },
                }}
                onClick={(e) => handleClickAddReward(e, level.level_id)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default BirthdayRewardSetting;
