import { Button } from "primereact/button";

const ListProductSingle = (props) => {
  const { name, price, product_type, onClick } = props;
  return (
    <div className={`col-start-center gap-2`}>
      <Button
        className={`leading-snug w-[14rem] min-h-[6rem] bg-slate-50 hover:bg-slate-200 !p-4`}
        label={name}
        raised
        text
        severity="secondary"
        onClick={onClick}
      ></Button>
    </div>
  );
};

export default ListProductSingle;
