import { FaGear, FaUser, FaRightFromBracket } from "react-icons/fa6";
import classes from "./FloatingProfilePage.module.css";
import MenuItem from "../../components/components/menu/MenuItem";
import MenuSeparator from "../../components/components/menu/MenuSeparator";
import Avatar from "../../components/components/other/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth-slice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProfileEditorDialog from "./profile-editor/ProfileEditorDialog";

const FloatingProfilePage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const employee = useSelector((state) => state.auth.employee);

  const [profileEditorShowed, setProfileEditorShowed] = useState(false);

  const handleUserClick = (e) => {
    // console.log("Click User");
  };

  const handleMyProfileClick = (e) => {
    setProfileEditorShowed(true);
  };

  const handleSettingClick = (e) => {
    console.log("Click Setting");
  };

  const handleLogoutClick = (e) => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <section
      className={`${classes.wrapper} col-start-start w-100`}
      style={{ display: props.hidden ? "none" : "block" }}
    >
      <ProfileEditorDialog
        visible={profileEditorShowed}
        onHide={() => setProfileEditorShowed(false)}
      />{" "}
      <MenuItem onClick={handleUserClick}>
        <div
          className={`${classes.profileContainer} row-start-center pb-2 gap-3`}
        >
          <Avatar
            className={`${classes.avatar}`}
            size="lg"
            src={employee ? employee.image : ""}
          />
          <div className="col-center-start">
            <span className={`${classes.userName}`}>{user.name}</span>
            <span className={`${classes.userTitle}`}>
              {employee ? employee.nip : "-"}
            </span>
            {/* <span className={`${classes.userTitle}`}>Programmer</span> */}
          </div>
        </div>
      </MenuItem>
      <MenuSeparator />
      <MenuItem
        onClick={handleMyProfileClick}
        icon={<FaUser />}
        label="Profil Saya"
      ></MenuItem>
      <MenuItem
        onClick={handleSettingClick}
        icon={<FaGear />}
        label="Setting"
      ></MenuItem>
      <MenuSeparator />
      <MenuItem
        onClick={handleLogoutClick}
        icon={<FaRightFromBracket />}
        label="Logout"
      ></MenuItem>
    </section>
  );
};

export default FloatingProfilePage;
