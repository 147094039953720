const extractDataFromMap = ({ key, map }) => {
  let result = "";
  if (key.includes(".")) {
    const keyArr = key.split(".");
    let newMap = map;
    for (let i = 0; i < keyArr.length; i++) {
      if (!newMap || newMap === null) {
        return null;
      }
      const value = newMap[keyArr[i]];
      if (i === keyArr.length - 1) {
        return value;
      }
      newMap = value;
    }
  } else {
    result = map[key];
  }

  return result;
};
export default extractDataFromMap;
