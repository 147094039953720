import { confirmDialog } from "primereact/confirmdialog";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UiContext from "../../store/ui-context";
import { setLayoutDashboardTitle } from "../../store/ui-slice";
import createFilterText from "../../utils/CreateFilterText";
import generateDefaultValue from "../../utils/GenerateDefaultValue";
import useAPIRequest from "../simple/useAPIRequest";

const useCrudControl = ({
  title,
  configs,
  isForm,
  apiName,
  orderBy,
  orderMethod,
  additionalParams,
  addtitionalFilter,
}) => {
  const uiContext = useContext(UiContext);
  const dispatch = useDispatch();
  const [paging, setPaging] = useState({
    page: 1,
    take: 10,
    filter: "",
    total: 0,
    ...additionalParams,
  });

  const [firstLoad, setFirstLoad] = useState(false);
  const [editorShowed, setEditorShowed] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);
  const [editorError, setEditorError] = useState({});
  const [confirmEditorLoading, setConfirmEditorLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const { requestGet, requestGetOne, requestPost, requestDelete, loading } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  const reloadListData = async ({ page, take, filter }) => {
    if (loading) return;

    let newFilter = filter;
    if (addtitionalFilter && addtitionalFilter.length > 0) {
      if (newFilter.length > 0) {
        newFilter += ";";
      } else {
        newFilter += addtitionalFilter;
      }
    }
    requestGet({
      apiUrl: `data`,
      params: {
        page: page,
        take: take,
        filter: newFilter,
        order: orderBy,
        order_method: orderMethod,
        ...additionalParams,
      },
      onSuccess: ({ pagination, message, data }) => {
        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
          filter: newFilter,
        });
        setListData(data);
        setFirstLoad(true);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });

    return;
  };

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    reloadListData({ page: 1, take: 10, filter: "" });
  }, []);

  const resetEditorError = () => {
    if (editorError && Object.keys(editorError).size > 0) {
      setEditorError({});
    }
  };

  const handleReload = async () => {
    setPaging({ ...paging, filter: "" });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: "",
    });
  };

  const handleOnFilter = (filterMap) => {
    const filterString = createFilterText(filterMap);
    setPaging({ ...paging, filter: filterString });
    reloadListData({
      page: paging.page,
      take: paging.take,
      filter: filterString,
    });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadListData({ page: newPage, take: newTake, filter: paging.filter });
  };

  const handleSelectionChange = (event) => {
    const values = event.value;
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleEditorSave = async (e, newValue) => {
    e.preventDefault();
    resetEditorError();
    if (loading) return;
    // setLoading(true);
    setConfirmEditorLoading(true);

    const data = await requestPost({
      apiUrl: "save",
      body: newValue,
      isForm: isForm,
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
        setEditorError(data);
      },
      onSuccess: ({ data, message }) => {
        if ("id" in newValue) {
          const index = listData.findIndex((el) => el.id === newValue.id);
          const newListData = [...listData];
          newListData.splice(index, 1, data);
          setListData(newListData);
        } else {
          setListData([data, ...listData]);
        }
        uiContext.showSuccessMsg("Success", message);
        setEditorShowed(false);
      },
    });

    setConfirmEditorLoading(false);
  };

  const handleAddNew = () => {
    resetEditorError();
    setSelectedValue(generateDefaultValue({ tableConfigs: configs }));
    setEditorShowed(true);
  };

  const handleDeleteMultiple = () => {
    resetEditorError();
    const accept = async () => {
      if (loading) return;
      // setLoading(true);

      const listIds = selectedRows.map((el) => el.id);
      requestDelete({
        apiUrl: "delete",
        ids: listIds,
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => !listIds.includes(el.id));
          setListData(newListData);
          uiContext.showSuccessMsg("Success", message);
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: `Menghapus ${selectedRows.length} data, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleEditRow = async (row) => {
    resetEditorError();
    setEditorLoading(true);
    // //show editor and set default value
    setSelectedValue(
      generateDefaultValue({
        tableConfigs: configs,
      })
    );
    setEditorShowed(true);
    // //fetch data from cloud
    await requestGetOne({
      apiUrl: `one/${row.id}`,
      onSuccess: ({ data }) =>
        setSelectedValue(
          generateDefaultValue({
            tableConfigs: configs,
            editValue: data,
          })
        ),
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
    setEditorLoading(false);
    return;
  };

  const handleDeleteRow = (row) => {
    resetEditorError();
    const accept = async () => {
      if (loading) return;
      // setLoading(true);

      requestDelete({
        apiUrl: "delete",
        ids: [row.id],
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
        onSuccess: ({ message, data }) => {
          const newListData = listData.filter((el) => el.id !== row.id);
          setListData(newListData);
          uiContext.showSuccessMsg("Success", message);
        },
      });
    };
    const reject = () => {};

    confirmDialog({
      message: "Menghapus 1 data, lanjutkan?",
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  return {
    // states
    firstLoad,
    setFirstLoad,
    paging,
    setPaging,
    editorShowed,
    setEditorShowed,
    loading,
    editorLoading,
    setEditorLoading,
    confirmEditorLoading,
    setConfirmEditorLoading,
    editorError,
    setEditorError,
    listData,
    setListData,
    selectedValue,
    setSelectedValue,
    selectedRows,
    setSelectedRows,
    //methods
    reloadListData,
    handleReload,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
    handleEditorSave,
    handleAddNew,
    handleDeleteMultiple,
    handleEditRow,
    handleDeleteRow,
  };
};

export default useCrudControl;
