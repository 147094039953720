import { FaBoxesStacked } from "react-icons/fa6";

const ApplicationButton = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={
        `cursor-pointer group col-center-center gap-2 shadow-md rounded bg-white px-3 pb-2 pt-4 ` +
        ` hover:shadow-xl hover:border-[#666cff] active:bg-blue-200 active:shadow-md` +
        ` transition-all ease-in`
      }
      style={{
        color: "var(--color-text)",
        fontWeight: "var(--font-weight-semibold)",
      }}
    >
      <div
        className={
          `row-center-center border-inherit border-2 p-2 w-100 h-20 rounded-md ` +
          `group-active:text-white group-active:border-white group-active:bg-[#666cff] ` +
          `transition-colors`
        }
      >
        {props.icon}
      </div>
      <span className="group-active:text-black transition-colors">
        {props.label}
      </span>
    </div>
  );
};

export default ApplicationButton;
