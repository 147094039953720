import classes from "./ProductEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";
// add_import_selector_dialog
import ProductDisplaySelectorDialog from "../../../../dialogs/selectors/ProductDisplaySelectorDialog";
import ProductCategorySelectorDialog from "../../../../dialogs/selectors/ProductCategorySelectorDialog";

import configs from "./ProductConfigs";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { TabPanel, TabView } from "primereact/tabview";
import PackageChildSelectorDialog from "../../../../dialogs/selectors/PackageChildSelectorDialog";

const title = configs.dialog.title;

const ProductEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    const newValue = { ...props.value };
    if (newValue.data_product_packages) {
      const newDataProductPackages = [];
      for (const singleProduct of newValue.data_product_packages) {
        const newSingleProduct = { ...singleProduct };
        if (newSingleProduct.id <= 0) {
          delete newSingleProduct.id;
        }
        newDataProductPackages.push(newSingleProduct);
      }
      newValue.data_product_packages = newDataProductPackages;
    }

    if (newValue.data_product_packages.length <= 0) {
      newValue.data_product_packages = [];
    }

    return { ...newValue };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "product_display_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProductDisplaySelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "product_category_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ProductCategorySelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "data_product_packages",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <PackageChildSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      breakpoints={{ "1440px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`h-[42rem] w-[45rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} row-start-start w-100`}>
          <TabView className="w-full">
            <TabPanel header="General">
              <div className="col-start-start w-full gap-3">
                {elements.name}
                <div className="row-start-start w-full gap-3">
                  <div className="col-start-start w-full gap-3">
                    {elements.code}
                    {elements.product_display_id}
                    {elements.price}
                  </div>
                  <div className="col-start-start w-full gap-3">
                    {elements.product_type}
                    {elements.product_category_id}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Paket">{elements.data_product_packages}</TabPanel>
            <TabPanel header="Mobile Apps">
              <div className="col-start-start w-full gap-3">
                <div className="row-start-start w-full gap-3">
                  {elements.redeemable}
                  {elements.level}
                </div>
                <div className="row-start-start w-full gap-3">
                  {elements.point}
                  {elements.duration}
                </div>
                <div className="row-start-start w-full gap-3">
                  {elements.description}
                  {elements.benefit}
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProductEditorDialog;
