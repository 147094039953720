// function to calculate the subtotal discount
// type: 1 = nominal, 2 = percentage

const calculateSubtotalDiscount = ({ type, value, subtotal }) => {
  let discount = 0;

  if (type === 1) {
    discount = value;
  } else if (type === 2) {
    discount = (value / 100) * subtotal;
  }

  return discount;
};
export default calculateSubtotalDiscount;
