const onErrorToast = ({ toast, message, data }) => {
  if (toast) {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  } else {
    console.log("ERROR TOAST", message);
  }
};

export default onErrorToast;
