import {
  FaArrowRightArrowLeft,
  FaBook,
  FaCakeCandles,
  FaCalendarCheck,
  FaChartBar,
  FaChartSimple,
  FaCircleLeft,
  FaCircleRight,
  FaFaceSmile,
  FaHouse,
  FaReceipt,
} from "react-icons/fa6";

const MenuCashier = [
  {
    label: "Home",
    to: "",
    icon: <FaHouse />,
  },
  {
    label: "Transaction",
    type: "menu-title",
  },
  {
    label: "Sales",
    to: "transaction/sales",
    icon: <FaReceipt />,
  },
  {
    label: "Sales Journal",
    to: "transaction/salesjournal",
    icon: <FaBook />,
  },
  {
    label: "Appointment",
    to: "transaction/appointment",
    icon: <FaCalendarCheck />,
  },
  {
    label: "Customer Data",
    to: "transaction/customerdata",
    icon: <FaFaceSmile />,
  },
  {
    label: "Birthday",
    to: "transaction/birthday",
    icon: <FaCakeCandles />,
  },
  {
    label: "Stock",
    type: "menu-title",
  },
  {
    label: "Stock In",
    to: "stock/stockin",
    icon: <FaCircleLeft />,
  },
  {
    label: "Stock Out",
    to: "stock/stockout",
    icon: <FaCircleRight />,
  },
  {
    label: "Stock Transfer",
    to: "stock/stocktransfer",
    icon: <FaArrowRightArrowLeft />,
  },
  {
    label: "Stock Balance",
    to: "stock/stockbalance",
    icon: <FaChartSimple />,
  },
  {
    label: "Stock Movement",
    to: "stock/stockmovement",
    icon: <FaChartSimple />,
  },
  {
    label: "Report",
    type: "menu-title",
  },
  {
    label: "Report",
    to: "report",
    icon: <FaChartBar />,
  },
];

export default MenuCashier;
