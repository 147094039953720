import React from "react";

import { useEffect, useState } from "react";

import TableAutoComponent from "../../components/complex/crud/TableAutoComponent";
import FilterComponent from "../../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../../utils/FilterConfigGenerator";
import generateTableConfigs from "../../utils/TableConfigGenerator";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../../custom_hooks/complex/useSelectorDialogControl";

const title = "Customer";
const apiName = "customer/customer";
const orderBy = "id";
const orderMethod = "ASC";
const additionalParams = { with_bc: 1 };
// const visible = "True";

const customColumns = [];

const configs = [
  // add_configs
  // {
  //   name: "Branch",
  //   field: "branch_id",
  //   type: "object",
  //   object_name: "data_branch",
  //   object_field: "name",
  //   editor: true,
  //   table: true,
  //   filter: true,
  // },
  {
    name: "BC",
    field: "bc_id",
    type: "object",
    object_name: "data_bc",
    object_field: "name",
    editor: true,
    table: true,
    filter: true,
  },
  {
    name: "Code",
    field: "code",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
  },
  {
    name: "Name",
    field: "name",
    type: "text_plain",
    filter_default: true,
    table: true,
    filter: true,
    editor: true,
  },
  // {
  //   name: "Email",
  //   field: "email",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Image",
  //   field: "image",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Occupation",
  //   field: "occupation",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  {
    name: "Phone 1",
    field: "phone_1",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
  },
  {
    name: "Phone 2",
    field: "phone_2",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
  },
  {
    name: "Address",
    field: "address",
    type: "text_plain",
    table: true,
    filter: true,
    editor: true,
  },
  // {
  //   name: "Point",
  //   field: "point",
  //   type: "number_plain",
  //   editor: true,
  //   align: "right",
  //   object_name: "",
  //   object_field: "",
  //   table: true,
  // },
  // {
  //   name: "CRM Level id",
  //   field: "crm_level_id",
  //   type: "object",
  //   object_name: "",
  //   object_field: "",
  //   editor: true,
  //   table: true,
  //   filter: true,
  // },
  // {
  //   name: "Join Date",
  //   field: "join_date",
  //   type: "date",
  //   align: "center",
  //   table: true,
  //   editor: true,
  //   format: "dd/MM/yyyy",
  //   prime_format: "d MM yy",
  //   showTime: 1,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Birth Date",
  //   field: "birth_date",
  //   type: "date",
  //   align: "center",
  //   table: true,
  //   editor: true,
  //   format: "dd/MM/yyyy",
  //   prime_format: "d MM yy",
  //   showTime: 1,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Notes",
  //   field: "notes",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Reference",
  //   field: "reference",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Medical History",
  //   field: "medical_history",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Skin Concern",
  //   field: "skin_concern",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Gender",
  //   field: "gender",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
  // {
  //   name: "Martial Status",
  //   field: "martial_status",
  //   type: "text_plain",
  //   table: true,
  //   filter: true,
  //   editor: true,
  //   object_name: "",
  //   object_field: "",
  // },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const CustomerSelectorDialog = (props) => {
  const { data, onConfirm, selected, setSelected } = props;
  const [selectedData, setSelectedData] = useState(null);

  const single = props.single ?? true;

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    visible: props.visible,
  });

  useEffect(() => {
    // onConfirm(selectedData);
  }, [selectedData]);

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Pilih"
          icon="pi pi-check"
          onClick={(e) => {
            const refinedValue = refineValue(selectedRows);
            props.onConfirm(e, refinedValue);
            setSelectedData(refinedValue);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[70rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-3`}>
        {filterConfigs && filterConfigs.length > 0 && (
          <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
        )}
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CustomerSelectorDialog;
