import { subDays, addDays } from "date-fns";

const CRMNewsConfigs = {
  master: {
    title: "CRM News",
    apiName: "crm/crmnews",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
    },
  },
  dialog: {
    title: "CRM News",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Home",
      field: "show_on_promotions",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Description",
      field: "description",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Image",
      field: "image",
      default_value: null,
      type: "zirvu_file_manager",
      table: true,
      editor: true,
    },
    {
      name: "Youtube",
      field: "youtube",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
  ],
};

export default CRMNewsConfigs;
