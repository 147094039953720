import { useState } from "react";
import ListProductSingle from "./ListProductSingle";

const ListProductSection = (props) => {
  const { data, onChange } = props;
  const [selectedData, setSelectedData] = useState(null);

  return (
    <div className={`row-center-center gap-4 flex-wrap`}>
      {data &&
        data.length > 0 &&
        data.map((product, index) => {
          return (
            <ListProductSingle
              key={product.id}
              {...product}
              onClick={(event) => {
                setSelectedData(product);
                onChange(product);
              }}
            />
          );
        })}
    </div>
  );
};

export default ListProductSection;
