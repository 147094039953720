import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isToday,
  startOfMonth,
} from "date-fns";

import { motion } from "framer-motion";
import { useEffect } from "react";

const shadowTailWind = "shadow-[0px_0px_4px_0px_#00000024]";

const EventCalendarEventItem = (props) => {
  const { date, title, backgroundColor, textColor, animate, stale } = props;
  // const controls = useAnimation();

  const isPast = date < new Date().setHours(0, 0, 0, 0);
  const shaking = true;

  useEffect(() => {
    // console.log("SHAKING");
    // // controls.start("start");
  }, []);

  return (
    <motion.div
      className={`row-start-start ${
        isPast ? " bg-slate-100 " : shadowTailWind
      } ${stale ? " !bg-slate-50 " : ""} w-full gap-2`}
      animate={
        animate
          ? {
              rotate: [0, -5, 5, 0],
              translateY: [0, -4, 0, 0],
            }
          : undefined
      }
      transition={
        animate
          ? {
              duration: 1,
              ease: "easeInOut",
              times: [0, 0.33, 0.677, 1],
              repeat: Infinity,
              repeatDelay: 0,
            }
          : undefined
      }
    >
      <div
        className={`h-full w-[3px] ${
          isPast || stale ? "bg-slate-300" : backgroundColor
        }`}
      ></div>
      <div className={`col-center-start pt-1 `}>
        <span
          className={`text-xs ${
            isPast || stale ? "text-slate-400" : textColor
          } font-semibold text-left`}
        >
          {title}
        </span>
        <span className="text-sm text-slate-400">
          {format(date, "hh:mm aa")}
        </span>
      </div>
    </motion.div>
  );
};
export default EventCalendarEventItem;
