import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { format as dateFnsFormat } from "date-fns";

import UiContext from "../../../store/ui-context";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import { Divider } from "primereact/divider";
// import { process.env.REACT_APP_PRINTER__HOST_URL } from "../../../configs/constants";
import { Calendar } from "primereact/calendar";

const title = "Summary Sales Report (POS)";

const ReportDailySales = (props) => {
  const {} = props;
  const uiContext = useContext(UiContext);

  const { requestGet, loading } = useAPIRequest();

  const [fromDate, setFromDate] = useState(new Date());
  const [loadedFromDate, setLoadedFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loadedToDate, setLoadedToDate] = useState(new Date());
  const [data, setData] = useState({});

  useEffect(() => {
    if (props.visible) {
    }
  }, [props.visible]);

  const reloadData = async () => {
    const from = getFromDatetime();
    const to = getToDatetime();

    setLoadedFromDate(from);
    setLoadedToDate(to);

    const fromDateTxt = dateFnsFormat(from, "yyyy-MM-dd HH:mm:00");
    const toDateTxt = dateFnsFormat(to, "yyyy-MM-dd HH:mm:53");

    await requestGet({
      fullUrl: `/api/report`,
      params: {
        prefix: "daily",
        filter: `transaction_date:>=:${fromDateTxt};transaction_date:<=:${toDateTxt}`,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          setData(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const print = async () => {
    const printData = generatePrintData();
    await sendPrintRequest(printData);
  };

  const generatePrintData = () => {
    const lines = [];

    // header
    lines.push({ text: "JELIM", font: 3, align: "center" });
    lines.push({ text: "SUMMARY SALES REPORT", font: 2, align: "center" });
    lines.push({ text: "LINE" });

    // date param
    lines.push({
      text_1: `From`,
      text_2: `${dateFnsFormat(loadedFromDate, "dd/MM/yyyy HH:mm")}`,
    });
    lines.push({
      text_1: `To`,
      text_2: `${dateFnsFormat(loadedToDate, "dd/MM/yyyy HH:mm")}`,
    });
    lines.push({ text: "LINE" });

    // sales detail
    const subtotal = data.sub_total ?? 0;
    const dicountSubtotal = data.discount ?? 0;
    const discountItem = data.discount_item ?? 0;
    const grandtotal = subtotal - dicountSubtotal - discountItem;

    lines.push({ text: "SALES DETAIL", font: 2, align: "center" });
    lines.push({
      text: "",
    });
    lines.push({
      text_1: `Subtotal`,
      text_2: subtotal.toLocaleString("id-ID"),
    });
    lines.push({
      text_1: `Discount Item`,
      text_2: discountItem.toLocaleString("id-ID"),
    });
    lines.push({
      text_1: `Discount Subtotal`,
      text_2: dicountSubtotal.toLocaleString("id-ID"),
    });
    lines.push({ text: "LINEPLUS" });
    lines.push({
      text_1: `Grandtotal`,
      text_2: grandtotal.toLocaleString("id-ID"),
    });
    lines.push({
      text: "",
    });
    lines.push({ text: "LINE" });

    // payment detail
    const listPayments = data.list_payments ?? [];
    const totalPayment = listPayments.reduce((acc, payment) => {
      return acc + payment.total;
    }, 0);

    lines.push({ text: "PAYMENT", font: 2, align: "center" });
    lines.push({
      text: "",
    });
    listPayments.forEach((payment) => {
      lines.push({
        text_1: `${payment.count.toLocaleString("id-ID")}`,
        text_2: `${payment.name}`,
        text_3: payment.total.toLocaleString("id-ID"),
        digit: 3,
        leading: 1,
      });
    });
    lines.push({ text: "LINEPLUS" });
    lines.push({
      text_1: `Total Payment`,
      text_2: totalPayment.toLocaleString("id-ID"),
    });
    lines.push({
      text: "",
    });
    lines.push({ text: "LINE" });

    // category detail
    const listCategories = data.list_product_categories ?? [];
    const totalCategories = listCategories.reduce((acc, category) => {
      return acc + category.total;
    }, 0);

    lines.push({ text: "CATEGORY DETAIL", font: 2, align: "center" });
    lines.push({
      text: "",
    });
    listCategories.forEach((category) => {
      lines.push({
        text_1: `${category.count.toLocaleString("id-ID")}`,
        text_2: `${category.name}`,
        text_3: category.total.toLocaleString("id-ID"),
        digit: 3,
        leading: 1,
      });
    });
    lines.push({ text: "LINEPLUS" });
    lines.push({
      text_1: `Total Sales`,
      text_2: totalCategories.toLocaleString("id-ID"),
    });
    lines.push({
      text: "",
    });
    lines.push({ text: "LINE" });

    // activity detail
    const salesCount = data.sales_total ?? 0;
    const usageCount = data.usage_total ?? 0;

    lines.push({ text: "ACTIVITY", font: 2, align: "center" });
    lines.push({
      text: "",
    });
    if (salesCount > 0) {
      lines.push({
        text_1: `Sales Invoice Count`,
        text_2: salesCount.toLocaleString("id-ID"),
      });
    }
    if (usageCount > 0) {
      lines.push({
        text_1: `Stock Usage Count`,
        text_2: usageCount.toLocaleString("id-ID"),
      });
    }
    lines.push({
      text: "",
    });
    lines.push({ text: "LINE" });

    lines.push({
      text: `Printed on ${dateFnsFormat(new Date(), "dd/MM/yyyy HH:mm:ss")}`,
      align: "right",
    });

    return lines;
  };

  const sendPrintRequest = async (lines) => {
    const response = await fetch(
      `${process.env.REACT_APP_PRINTER__HOST_URL}/api/print`,
      {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: lines }),
      }
    );
    return response.json();
  };

  const handlePrint = (event) => {
    event.preventDefault();
    print();
  };

  const getFromDatetime = () => {
    let result = new Date();
    if (fromDate) {
      result = fromDate;
    }
    result.setHours(0, 0, 0, 0);
    return result;
  };

  const getToDatetime = () => {
    let result = new Date();
    if (toDate) {
      result = toDate;
    }
    result.setHours(23, 59, 59, 999);
    return result;
  };

  const dialogFooter = (
    <div className="row-between-center w-100">
      <Button
        className="w-full"
        label="Close"
        icon="pi pi-arrow-left"
        onClick={(e) => {
          e.preventDefault();
          props.onHide();
        }}
      />
      <Button
        className="w-full"
        severity="success"
        label="Print"
        icon="pi pi-print"
        onClick={handlePrint}
      />
    </div>
  );

  const PlusLine = () => {
    return (
      <div className="row-end-center w-full py-1">
        <div className="w-full h-0.5 bg-gray-300"></div>
      </div>
    );
  };

  const DateParamSection = () => {
    return (
      <div className="row-between-center w-full">
        <span className="font-semibold">Date </span>
        <div className="row-end-center gap-2">
          <Calendar
            className="w-[8rem]"
            value={fromDate}
            showButtonBar
            onChange={(e) => {
              setFromDate(e.value);
            }}
            locale="id"
          />
          <span className="font-semibold">To </span>
          <Calendar
            className="w-[8rem]"
            value={toDate}
            showButtonBar
            onChange={(e) => {
              setToDate(e.value);
            }}
            locale="id"
          />
          <Button
            rounded
            loading={loading}
            icon="pi pi-refresh"
            onClick={() => {
              reloadData();
            }}
          />
        </div>
      </div>
    );
  };

  const CompanyHeaderSection = () => {
    return (
      <div className="col-start-center w-full">
        <span className="font-semibold text-lg">JELIM</span>
        <span>SUMMARY SALES REPORT</span>
      </div>
    );
  };

  const DateHeaderSection = () => {
    return (
      <div className="col-start-center w-full gap-1">
        <div className="row-between-center w-full">
          <span className="font-semibold">FROM</span>
          <span>{dateFnsFormat(loadedFromDate, "dd/MM/yyyy HH:mm")}</span>
        </div>
        <div className="row-between-center w-full">
          <span className="font-semibold">TO</span>
          {dateFnsFormat(loadedToDate, "dd/MM/yyyy HH:mm")}
        </div>
      </div>
    );
  };

  const SalesDetailSection = () => {
    const subtotal = data.sub_total ?? 0;
    const dicountSubtotal = data.discount ?? 0;
    const discountItem = data.discount_item ?? 0;
    const grandtotal = subtotal - dicountSubtotal - discountItem;

    return (
      <div className="col-center-center w-full gap-4">
        <span className="font-semibold text-lg"> SALES DETAIL </span>
        <div className="col-center-center w-full gap-1">
          <div className="row-between-center w-full">
            <span className="">Subtotal</span>
            <span>{subtotal.toLocaleString("id-ID")}</span>
          </div>
          <div className="row-between-center w-full">
            <span className="">Discount Item</span>
            <span>{discountItem.toLocaleString("id-ID")}</span>
          </div>
          <div className="row-between-center w-full">
            <span className="">Discount Subtotal</span>
            <span>{dicountSubtotal.toLocaleString("id-ID")}</span>
          </div>
          <PlusLine />
          <div className="row-between-center w-full">
            <span className="font-semibold">Grandtotal</span>
            <span className="font-semibold">
              {grandtotal.toLocaleString("id-ID")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const PaymentSection = () => {
    const listPayments = data.list_payments ?? [];
    const totalPayment = listPayments.reduce((acc, payment) => {
      return acc + payment.total;
    }, 0);

    return (
      <div className="col-center-center w-full gap-4">
        <span className="font-semibold text-lg"> PAYMENT </span>
        <div className="col-center-center w-full gap-1">
          {listPayments.map((payment) => {
            return (
              <div key={payment.id} className="row-between-center w-full">
                <div className="row-start-center gap-3">
                  <div className="row-end-center w-[2rem]">
                    <span className="">
                      {payment.count.toLocaleString("id-ID")}
                    </span>
                  </div>
                  <span className="">{payment.name}</span>
                </div>{" "}
                <span>{payment.total.toLocaleString("id-ID")}</span>
              </div>
            );
          })}
          <PlusLine />
          <div className="row-between-center w-full">
            <span className="font-semibold">Total Payment</span>
            <span className="font-semibold">
              {totalPayment.toLocaleString("id-ID")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const CategorySection = () => {
    const listCategories = data.list_product_categories ?? [];
    const totalCategories = listCategories.reduce((acc, category) => {
      return acc + category.total;
    }, 0);

    return (
      <div className="col-center-center w-full gap-4">
        <span className="font-semibold text-lg"> CATEGORY DETAIL </span>
        <div className="col-center-center w-full gap-1">
          {listCategories.map((category) => {
            return (
              <div key={category.id} className="row-between-center w-full">
                <div className="row-start-center gap-3">
                  <div className="row-end-center w-[2rem]">
                    <span className="">
                      {category.count.toLocaleString("id-ID")}
                    </span>
                  </div>
                  <span className="">{category.name}</span>
                </div>
                <span>{category.total.toLocaleString("id-ID")}</span>
              </div>
            );
          })}
          <PlusLine />
          <div className="row-between-center w-full">
            <span className="font-semibold">Total Sales</span>
            <span className="font-semibold">
              {totalCategories.toLocaleString("id-ID")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ActivitySection = () => {
    const salesCount = data.sales_total ?? 0;
    const usageCount = data.usage_total ?? 0;

    return (
      <div className="col-center-center w-full gap-4">
        <span className="font-semibold text-lg"> ACTIVITY </span>
        <div className="col-center-center w-full gap-1">
          {salesCount > 0 && (
            <div className="row-between-center w-full">
              <span className="">Sales Invoice Count</span>
              <span>{salesCount.toLocaleString("id-ID")}</span>
            </div>
          )}
          {usageCount > 0 && (
            <div className="row-between-center w-full">
              <span className="">Stock Usage Count</span>
              <span>{usageCount.toLocaleString("id-ID")}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[30rem] min-h-[33rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="col-start-center w-full gap-4">
        <Divider className="w-full !my-0" />
        <DateParamSection />
        <Divider className="w-full !my-0" />
        <CompanyHeaderSection />
        <Divider className="w-full !my-0" />
        <DateHeaderSection />
        <Divider className="w-full !my-0" />
        <SalesDetailSection />
        <Divider className="w-full !my-0" />
        <PaymentSection />
        <Divider className="w-full !my-0" />
        <CategorySection />
        <Divider className="w-full !my-0" />
        <ActivitySection />
        <Divider className="w-full !my-0" />
        <div className="h-[4rem]"></div>
      </div>
    </Dialog>
  );
};

export default ReportDailySales;
