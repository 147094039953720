import { InputText } from "primereact/inputtext";
import { useState } from "react";

const FilterComponentText = (props) => {
  const { onChangeFilterValue, onEnter, field, or, and } = props;
  const [value, setValue] = useState("");

  const handleInputChange = (event) => {
    setValue(event.target.value);
    if (or) {
      onChangeFilterValue({ value: [event.target.value], operator: "none" });
    } else if (and) {
      onChangeFilterValue({
        field,
        value: [event.target.value],
        operator: "none",
      });
    }
  };

  return (
    <InputText
      className="w-100"
      placeholder="Search text ..."
      onChange={handleInputChange}
      onKeyDown={onEnter}
      value={value}
    />
  );
};

export default FilterComponentText;
