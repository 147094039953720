const generateTableConfigs = (configs) => {
  const tableConfigs = [];
  for (const config of configs) {
    // const { name, field, table } = config;
    const { table } = config;
    if (table) {
      tableConfigs.push({
        ...config,
        // name: name,
        // value: field,
      });
    }
  }
  return tableConfigs;
};

export default generateTableConfigs;
