import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import useEditorControl from "../custom_hooks/complex/useEditorControl";
import UiContext from "../store/ui-context";
import EmployeeSelectorDialog from "./selectors/EmployeeSelectorDialog";
import BranchSelectorDialog from "./selectors/BranchSelectorDialog";

const title = "New Customer";

const configs = [
  {
    name: "Gambar",
    field: "image",
    default_value: null,
    type: "zirvu_file_manager",
    table: true,
    editor: true,
  },
  {
    name: "Code",
    field: "code",
    type: "text_plain",
  },
  {
    name: "Name",
    field: "name",
    type: "text_plain",
  },
  {
    name: "Beauty Consultant",
    field: "bc_id",
    type: "object",
    object_name: "data_bc",
    object_field: "name",
  },
  {
    name: "Phone 1",
    field: "phone_1",
    type: "text_plain",
  },
  {
    name: "Phone 2",
    field: "phone_2",
    type: "text_plain",
  },
  {
    name: "Birth Date",
    field: "birth_date",
    type: "date",
    align: "left",
    format: "dd/MM/yyyy",
    prime_format: "d MM yy",
  },
  {
    name: "Gender",
    field: "gender",
    type: "text_dropdown",
    default_value: "female",
    options: [
      { name: "Girl", value: "female" },
      { name: "Boy", value: "male" },
    ],
  },
  {
    name: "Address",
    field: "address",
    type: "text_plain",
  },
  {
    name: "Occupation",
    field: "occupation",
    type: "text_plain",
  },
  {
    name: "Reference",
    field: "reference",
    type: "text_plain",
  },
  {
    name: "Medical History",
    field: "medical_history",
    type: "text_plain",
  },
  {
    name: "Skin Concern",
    field: "skin_concern",
    type: "text_plain",
  },
  {
    name: "Martial Status",
    field: "martial_status",
    type: "text_plain",
  },
  {
    name: "Email",
    field: "email",
    type: "text_plain",
  },
  {
    name: "Note",
    field: "note",
    type: "text_plain",
  },
  {
    name: "User Name",
    field: "data_user.username",
    type: "text_plain",
    editor: true,
  },
  {
    name: "Password",
    field: "data_user.password",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
    editor: true,
  },
  {
    name: "Konfirmasi Password",
    field: "data_user.cpassword",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
    editor: true,
  },
  {
    name: "Branch",
    field: "branch_id",
    type: "object",
    object_name: "data_branch",
    object_field: "name",
    editor: true,
    table: false,
    filter: true,
  },
  {
    name: "Join Date",
    field: "join_date",
    type: "date",
    align: "center",
    table: false,
    editor: true,
    format: "dd/MM/yyyy",
    prime_format: "d MM yy",
    showTime: 1,
  },
];

const apiName = "masterdata/employee";
let apiSuffix;
const customElements = [];
const isForm = false;

const NewCustomerDialog = (props) => {
  const { visible, onSave, loading } = props;
  const uiContext = useContext(UiContext);
  const user = useSelector((state) => state.auth.user);

  const {
    // methods
    generateSelectorDialog,
    // onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    // states
    value,
    setValue,
    setError,
    // loading,
    elements,
    //utils
    validator,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  generateSelectorDialog({
    field: "bc_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <EmployeeSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  generateSelectorDialog({
    field: "branch_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <BranchSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });

  const resetValue = () => {
    setValue({
      image: null,
      code: "",
      name: "",
      bc_id: "",
      phone_1: "",
      phone_2: "",
      birth_date: null,
      gender: "female",
      address: "",
      occupation: "",
      reference: "",
      medical_history: "",
      skin_concern: "",
      martial_status: "",
      email: "",
      note: "",
      data_user: {
        username: "",
        password: "",
        cpassword: "",
      },
      branch_id: null,
      join_date: null,
      data_branch: null,
    });
  };

  useEffect(() => {
    if (visible) {
      resetValue();
    }
  }, [visible]);

  //   const fetchData = async () => {
  //     onFetchData({
  //       id: user.employee_id,
  //       onError: ({ message, data }) => {
  //         uiContext.showErrorMsg("Error", message);
  //       },
  //       onSuccess: ({ message, data }) => {
  //         if (data.data_user) {
  //           data.data_user.password = "";
  //           data.data_user.cpassword = "";
  //         }
  //         setValue(data);
  //       },
  //     });
  //   };

  const convertValue = () => {
    const newValue = {
      ...value,
      // id: user.employee_id,
      // data_positions: [],
    };
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configs,
      value: value,
      onError: (error) => setError(error),
      isEmpty: "date_of_birth nip",
      mustEqual: ["data_user.password data_user.cpassword"],
    });
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();
      if (onSave) {
        onSave(newValue);
      }
      // console.log(newValue);
      // const data = await onSave({
      //   newValue,
      //   onError: ({ message, data }) => {
      //     uiContext.showErrorMsg("Error", message);
      //   },
      //   onSuccess: ({ data, message }) => {
      //     uiContext.showSuccessMsg("Success", message);
      //     props.onHide();
      //   },
      // });
    }
    // props.onHide();
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Simpan"
          icon="pi pi-save"
          loading={loading}
          onClick={(e) => {
            handleOnSave(e);
          }}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "68rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="col-start-start w-100 gap-3">
          <div className={`row-start-start w-100`}>
            <div className="col-start-start w-1/2 gap-3">
              <div className={`row-start-start w-100`}>
                <div className="col-start-start w-100 gap-3">
                  {elements.branch_id}
                  {elements.bc_id}
                  {elements.image}
                </div>
                <Divider className="h-[23.5rem]" layout="vertical" />
                <div className="col-start-start w-100 gap-3">
                  {elements.code}
                  {elements.name}
                  {elements["data_user.username"]}
                  {elements["data_user.password"]}
                  {elements["data_user.cpassword"]}
                </div>
              </div>
              {elements.address}
            </div>

            <Divider className="h-[28.5rem]" layout="vertical" />
            <div className="col-start-start w-1/4 gap-3">
              {elements.email}
              {elements.phone_1}
              {elements.phone_2}
              {elements.join_date}
              {elements.birth_date}
              {elements.note}
            </div>
            <Divider className="h-[28.5rem]" layout="vertical" />
            <div className="col-start-start w-1/4 gap-3">
              {elements.occupation}
              {elements.reference}
              {elements.medical_history}
              {elements.skin_concern}
              {elements.gender}
              {elements.martial_status}
            </div>
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default NewCustomerDialog;
