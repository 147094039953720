import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaQrcode } from "react-icons/fa6";
import { add, format as dateFnsFormat, parse } from "date-fns";
import UiContext from "../../../../store/ui-context";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";

const FreeProductEditorDialog = (props) => {
  const uiContext = useContext(UiContext);
  const { visible, data, onHide, onConfirm, loading, onRemove } = props;

  const [title, setTitle] = useState("");
  const [visibleProductSelectorDialog, setVisibleProductSelectorDialog] =
    useState(false);
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    if (visible) {
      if (data) {
        // edit procedure
        const description = data.description ? data.description : "";
        setTitle("Edit Free Product");
        setInputData({ ...data, description });
      } else {
        // add procedure
        setInputData({
          quantity: 1,
          expired_date: add(new Date(), { months: 1 }),
          data_product: null,
          description: "",
        });
      }
    }
  }, [visible]);

  const handleOnConfirm = () => {
    // validate input data
    if (!inputData.data_product) {
      uiContext.showErrorMsg("Error", "Product must be selected");
      return;
    }

    if (!inputData.quantity || inputData.quantity <= 0) {
      uiContext.showErrorMsg("Error", "Quantity must be greater than 0");
      return;
    }

    if (!inputData.expired_date) {
      uiContext.showErrorMsg("Error", "Expired Date must be selected");
      return;
    }

    if (!inputData.description) {
      uiContext.showErrorMsg("Error", "Description must be filled");
      return;
    }

    if (onConfirm) {
      onConfirm(inputData);
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-between-end">
        <Button
          label="Remove"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            if (onRemove) onRemove(data);
          }}
        />
        <div className="row-end-end">
          <Button
            label="Cancel"
            icon="pi pi-times"
            outlined
            onClick={props.onHide}
          />
          <Button
            label="Save"
            icon="pi pi-save"
            loading={loading}
            onClick={handleOnConfirm}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "30rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100">
        <ProductSelectorDialog
          visible={visibleProductSelectorDialog}
          onHide={() => setVisibleProductSelectorDialog(false)}
          onConfirm={(e, value) => {
            if (value && value.length > 0) {
              const selectedProduct = value[0];
              setInputData({ ...inputData, data_product: selectedProduct });
              setVisibleProductSelectorDialog(false);
            }
          }}
        />
        <div className="col-start-start w-100 gap-4 pt-2">
          <div className="col-start-start w-full gap-1">
            <span className="font-bold text-sm text-slate-400"> Product</span>
            <Button
              className="w-full text-left"
              label={inputData.data_product ? inputData.data_product.name : "-"}
              outlined
              onClick={(e) => setVisibleProductSelectorDialog(true)}
            />
          </div>
          <div className="col-start-start w-full gap-1">
            <span className="font-bold text-sm text-slate-400"> Quantity</span>
            <InputNumber
              className="w-full"
              showButtons
              buttonLayout="horizontal"
              value={inputData.quantity}
              onChange={(e) =>
                setInputData({ ...inputData, quantity: e.value })
              }
            />
          </div>
          <div className="col-start-start w-full gap-1">
            <span className="font-bold text-sm text-slate-400">
              {" "}
              Expired Date
            </span>
            <Calendar
              className="w-full"
              showIcon
              value={inputData.expired_date}
              dateFormat="dd/mm/yy"
              onChange={(e) =>
                setInputData({ ...inputData, expired_date: e.value })
              }
            />
          </div>
          <div className="col-start-start w-full gap-1">
            <span className="font-bold text-sm text-slate-400">
              {" "}
              Description
            </span>
            <InputText
              className="w-full"
              value={inputData.description}
              onChange={(e) =>
                setInputData({ ...inputData, description: e.target.value })
              }
            />
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default FreeProductEditorDialog;
