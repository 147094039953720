import { useEffect, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import generateDefaultValue from "../../utils/GenerateDefaultValue";
import assignDataToMap from "../../utils/AssignDataToMap";
import generateEditorElement from "../../utils/GenerateEditorElement";
import useAPIRequest from "../simple/useAPIRequest";
import { format as dateFnsFormat, parse } from "date-fns";
import onErrorToast from "../../utils/OnErrorToast";
import onSuccessToast from "../../utils/OnSuccessToast";
import validatorUtil from "../../utils/InputValidator";
import generateEditorConfigs from "../../utils/EditorConfigGenerator";
import generateRandomMinusId from "../../utils/GenerateRandomMinusId";
import { useNavigate, useParams } from "react-router-dom";

const useMDEditorPageControl = ({
  apiName,
  apiDetailName,
  generateDefaultValue,
  configs,
  generateNumbering,
  id,
  detailField,
  removeSelectedEditor,
  changeLabeEditor,
  generateTabLabel,
  requestFocus,
  generateAddDialog,
  convertAddDetailData,
  afterReloadData,
  manuallyAddDetail,
}) => {
  const headerConfigs = generateEditorConfigs(configs.columns);
  const navigate = useNavigate();

  const toast = useRef(null);
  const [showAddSelector, setShowAddSelector] = useState(false);
  const [showDetailEditorDialog, setShowDetailEditorDialog] = useState(false);
  const [detailEditorValue, setDetailEditorValue] = useState({});
  const [detailEditorError, setDetailEditorError] = useState({});
  const [headerError, setHeaderError] = useState({});
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [headerValue, setHeaderValue] = useState(
    generateDefaultValue({ tableConfigs: configs.columns })
  );
  const [edited, setEdited] = useState(false);

  const { requestGet, requestGetOne, requestPost, loading, requestDelete } =
    useAPIRequest({
      url: `api/${apiName}`,
    });

  useEffect(() => {
    if (!isNaN(Number(id)) && id > 0) {
      handleReload();
    }
    // if (id > 0) handleReload();
    // requestFocus();
    setEdited(false);
  }, []);

  // header control
  const handleHeaderChange = (field, newValue) => {
    const newMap = { ...headerValue };
    if (!field.includes(".")) {
      newMap[field] = newValue;
    } else {
      assignDataToMap({ key: field, map: newMap, value: newValue });
    }
    setHeaderValue(newMap);
    setEdited(true);
  };

  const handleMultipleHeaderChange = (mapsNewValue) => {
    const newMap = { ...headerValue };
    for (const mapNewValue of mapsNewValue) {
      newMap[mapNewValue.field] = mapNewValue.newValue;
    }
    setHeaderValue(newMap);
    setEdited(true);
  };

  // auto generate header element
  const configHeaders = generateEditorConfigs(headerConfigs);

  const generateSelectorDialog = ({ field, componentFunction }) => {
    let fieldArr = [];
    if (field.includes(" ")) {
      fieldArr = field.split(" ");
    } else if (field.includes(",")) {
      fieldArr = field.split(",");
    } else {
      fieldArr.push(field);
    }
    for (const fieldSingle of fieldArr) {
      const config = configHeaders.find(
        (element) => element.field === fieldSingle
      );
      if (config) {
        config.selectorGenerator = componentFunction;
      }
    }
  };

  const defaultProps = {
    value: headerValue,
    error: headerError,
    handleInputChange: handleHeaderChange,
    handleMultipleInputChange: handleMultipleHeaderChange,
  };

  const mapGeneratedElement = {};
  let firstElement = true;
  for (const config of configHeaders) {
    let foundEl;
    for (const custEl of configHeaders) {
      const { field, element } = custEl;
      if (config.field === field) {
        foundEl = element;
      }
    }
    if (foundEl) {
      mapGeneratedElement[config.field] = foundEl({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
    } else {
      const generatedElement = generateEditorElement({
        ...defaultProps,
        autoFocus: firstElement,
        config: config,
      });
      if (generatedElement) {
        mapGeneratedElement[config.field] = generatedElement;
      }
    }
    if (firstElement) {
      firstElement = false;
    }
  }

  const elements = mapGeneratedElement;

  const handleDelete = (row) => {
    const accept = async () => {
      const newListData = [...listData];
      const indexToDelete = newListData.findIndex((el) => el.id === row.id);
      if (indexToDelete >= 0) newListData.splice(indexToDelete, 1);
      setListData(generateNumbering(newListData));
      setEdited(true);
    };
    const reject = () => {};

    confirmDialog({
      message: "Menghapus 1 data, lanjutkan?",
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleDeleteMultiple = () => {
    const accept = async () => {
      const listIds = selectedRows.map((el) => el.id);
      const newListData = listData.filter((el) => !listIds.includes(el.id));
      setListData(generateNumbering(newListData));
      setEdited(true);
    };
    const reject = () => {};

    confirmDialog({
      message: `Menghapus ${selectedRows.length} data, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const handleEdit = (row) => {
    setDetailEditorError({});
    setDetailEditorValue({ ...row });
    setShowAddSelector(false);
    setShowDetailEditorDialog(true);
    setEdited(true);
  };

  const handleSelectionChange = (values) => {
    if (values) {
      setSelectedRows([...values]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    const accept = async () => {
      removeSelectedEditor();
    };
    const reject = () => {};

    confirmDialog({
      message: `Anda akan menutup tab ini, lanjutkan?`,
      header: "Konfirmasi",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const reloadData = () => {
    if (id > 0) {
      requestGetOne({
        apiUrl: `one/${id}`,
        onSuccess: ({ message, data }) => {
          let newHeaderValue = { ...data };
          const newListData = [...newHeaderValue[detailField]];
          delete newHeaderValue[detailField];
          if (afterReloadData) {
            newHeaderValue = afterReloadData({
              headerValue: newHeaderValue,
              data,
            });
          }
          setHeaderValue(newHeaderValue);
          setListData(newListData);
          setEdited(false);
        },
        onError: ({ message, data }) => {
          onErrorToast({ toast: toast, message: message, data: data });
        },
      });
    }
  };

  const generateSaveBody = () => {
    const newBody = { ...headerValue };
    newBody[detailField] = [...listData].map((el) => {
      if (el.id <= 0) {
        const newEl = { ...el };
        delete newEl.id;
        return newEl;
      } else {
        return el;
      }
    });
    return newBody;
  };

  const saveData = (body) => {
    requestPost({
      apiUrl: "save",
      isForm: false,
      body,
      onSuccess: ({ message, data }) => {
        const newHeaderValue = { ...data };
        setListData(data[detailField]);
        delete newHeaderValue[detailField];
        setHeaderValue(newHeaderValue);

        // changeLabeEditor(generateTabLabel(headerValue));
        setEdited(false);
        onSuccessToast({ toast: toast, message: message });

        if (id === "new") {
          navigate("../" + data.id);
        }
      },
      onError: ({ message, data }) => {
        onErrorToast({ toast: toast, message: message, data: data });
      },
    });
  };

  const validator = ({
    configs,
    value,
    onError,
    isEmpty,
    isEmptyMessage,
    mustEqual,
    mustEqualMessage,
  }) => {
    return validatorUtil({
      configs,
      value,
      onError,
      isEmpty,
      isEmptyMessage,
      mustEqual,
      mustEqualMessage,
    });
  };

  const handleReload = () => {
    reloadData();
  };

  const handleSave = (body) => {
    saveData(body);
  };

  const handleAdd = () => {
    setShowAddSelector(true);
  };

  const handleEditorSave = (event, newValue) => {
    const newListData = [...listData];
    const index = newListData.findIndex((el) => el.id === newValue.id);
    newListData.splice(index, 1, newValue);

    setListData(newListData);
    setShowDetailEditorDialog(false);
  };

  const addDialogSelector = generateAddDialog({
    visible: showAddSelector,
    setVisible: setShowAddSelector,
    // onConfirm: ({ event, data, field, dataField, defaultData }) => {
    onConfirm: ({ event, data, field, dataField }) => {
      if (event.preventDefault) event.preventDefault();

      let newDataArray = [];
      if (data.length > 0) {
        setShowAddSelector(false);

        for (const selectedData of data) {
          const newData = generateDefaultValue({
            tableConfigs: configs.detail.columns,
          });
          newData.id = generateRandomMinusId();
          newData[field] = selectedData.id;
          newData[dataField] = selectedData;
          newDataArray.push(newData);
        }

        // refine numbering
        let oldDataArray = [...listData];
        oldDataArray = generateNumbering(oldDataArray);
        newDataArray = generateNumbering(newDataArray, oldDataArray.length + 1);
        for (let i = 0; i < newDataArray.length; i++) {
          const el = newDataArray[i];
          newDataArray[i] = convertAddDetailData(el, headerValue);
        }

        if (!manuallyAddDetail) {
          setListData(oldDataArray.concat(newDataArray));
        }
      }
      setEdited(true);

      return newDataArray;
    },
  });

  return {
    // UIs
    toast,
    elements,
    addDialogSelector,
    // states
    showDetailEditorDialog,
    setShowDetailEditorDialog,
    detailEditorValue,
    setDetailEditorValue,
    detailEditorError,
    setDetailEditorError,
    setHeaderError,
    listData,
    setListData,
    selectedRows,
    headerValue,
    setHeaderValue,
    edited,
    setEdited,
    loading,
    //methods
    generateSelectorDialog,
    handleDelete,
    handleDeleteMultiple,
    handleEdit,
    handleSelectionChange,
    handleClose,
    generateSaveBody,
    validator,
    handleReload,
    handleSave,
    handleAdd,
    handleEditorSave,
    //others
    configHeaders,
    requestGet,
    requestGetOne,
    requestPost,
    requestDelete,
  };
};

export default useMDEditorPageControl;
