import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import useEditorControl from "../../../custom_hooks/complex/useEditorControl";
import UiContext from "../../../store/ui-context";
import configs from "./ProfileEditorConfigs";

const title = "Profil Saya";

const editorConfigs = configs;
const apiName = "masterdata/employee";
let apiSuffix;
const customElements = [];
const isForm = false;

const ProfileEditorDialog = (props) => {
  const uiContext = useContext(UiContext);
  const user = useSelector((state) => state.auth.user);

  const {
    // methods
    onSave,
    onFetchData,
    handleInputChange,
    handleMultipleInputChange,
    // states
    value,
    setValue,
    setError,
    loading,
    elements,
    //utils
    validator,
  } = useEditorControl({
    configs,
    isForm,
    apiName,
    apiSuffix,
    customElements,
  });

  const fetchData = async () => {
    onFetchData({
      id: user.employee_id,
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
      onSuccess: ({ message, data }) => {
        if (data.data_user) {
          data.data_user.password = "";
          data.data_user.cpassword = "";
        }
        setValue(data);
      },
    });
  };

  useEffect(() => {
    if (props.visible) {
      fetchData();
    }
  }, [props.visible]);

  const convertValue = () => {
    const newValue = {
      ...value,
      id: user.employee_id,
      // data_positions: [],
    };
    return newValue;
  };

  const validation = () => {
    return validator({
      configs: configs,
      value: value,
      onError: (error) => setError(error),
      isEmpty: "name data_user.username",
      mustEqual: ["data_user.password data_user.cpassword"],
    });
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    const valid = validation();
    if (valid) {
      const newValue = convertValue();

      const data = await onSave({
        newValue,
        onError: ({ message, data }) => {
          uiContext.showErrorMsg("Error", message);
        },
        onSuccess: ({ data, message }) => {
          uiContext.showSuccessMsg("Success", message);
          props.onHide();
        },
      });
    }
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Simpan"
          icon="pi pi-save"
          loading={loading}
          onClick={(e) => {
            handleOnSave(e);
          }}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "25rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="col-start-start w-100 gap-3">
          <div className="col-start-start w-100 gap-3">
            {elements.image}
            {elements.nip}
            {elements.name}
            {elements["data_user.username"]}
            {elements["data_user.email"]}
            {elements["data_user.password"]}
            {elements["data_user.cpassword"]}
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProfileEditorDialog;
