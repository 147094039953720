import { subDays, addDays } from "date-fns";

const ProductCategoryConfigs = {
  master: {
    title: "Product Category",
    apiName: "masterdata/productcategory",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
    },
  },
  dialog: {
    title: "Product Category",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Active",
      field: "active",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default ProductCategoryConfigs;
