// function to calculate the subtotal discount
// type: 1 = nominal, 2 = percentage

const calculateUnpayedAmount = (sales) => {
  if (!sales || !sales.data_sales_payments) return 0;

  const totalPayed = sales.data_sales_payments.reduce(
    (total, payment) => total + payment.amount,
    0
  );
  return sales.grand_total - totalPayed;
};
export default calculateUnpayedAmount;
