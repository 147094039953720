const EventCalendarHeaderItem = (props) => {
  const { day, index } = props;
  return (
    <div
      className={`row-start-center w-full border p-0 ${
        index === 0 ? "rounded-tl-lg" : ""
      } ${index === 6 ? "rounded-tr-lg" : ""} bg-slate-50`}
    >
      <span className="text-sm font-semibold text-slate-500 p-2">
        {day.toUpperCase()}
      </span>
    </div>
  );
};
export default EventCalendarHeaderItem;
