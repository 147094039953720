import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import classes from "./MenuItemComponent.module.css";
import { FaCircle } from "react-icons/fa6";
import { motion } from "framer-motion";

//https://demos.pixinvent.com/materialize-html-admin-template/html/vertical-menu-template/dashboards-crm.html

const MenuItemComponent = (props) => {
  const { label, icon, isChild, to } = props;
  const location = useLocation();

  const pathSegments = location.pathname.split("/");
  const lastPathSegment = pathSegments[pathSegments.length - 1];

  const toSegments = to.split("/");
  const lastToSegment = toSegments[toSegments.length - 1];

  const active = lastPathSegment === lastToSegment;

  return (
    <motion.li
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      className={` row-start-center w-100 px-2 relative z-10`}
      transition={{ delay: 0.05 * props.index ?? 0 }}
    >
      {active && (
        <motion.div
          layoutId="active-menu-indicator"
          className={`${classes.activeBackground} absolute`}
        ></motion.div>
      )}
      <Link
        className={`${
          active ? classes.wrapperActive : classes.wrapper
        } row-start-center gap-2 w-100 z-50`}
        to={to}
      >
        <div className={`${classes.iconContainer} row-center-center`}>
          {icon && icon}
          {!icon && isChild && (
            <FaCircle style={{ fontSize: "5px", marginRight: "4px" }} />
          )}
        </div>
        {label}
      </Link>
    </motion.li>
  );
};

export default MenuItemComponent;
