import { Button } from "primereact/button";
import { useContext, useState } from "react";
import PaymentDialog from "../../../../../dialogs/PaymentDialog";
import UiContext from "../../../../../store/ui-context";

const PaymentControlSection = (props) => {
  const { width, loading, onReload, onPay, data } = props;

  const uiContext = useContext(UiContext);
  const [showPaymentSelector, setShowPaymentSelector] = useState(false);

  const handlePay = () => {
    if (data.customer_id) {
      setShowPaymentSelector(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleReload = (event) => {
    if (data.customer_id) {
      event.preventDefault();
      onReload();
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  return (
    <div className={`row-start-center ${width} gap-1`}>
      <PaymentDialog
        data={data}
        visible={showPaymentSelector}
        onHide={() => setShowPaymentSelector(false)}
        onConfirm={onPay}
      />
      <Button
        loading={loading}
        className="min-w-[3rem]"
        icon="pi pi-refresh"
        onClick={handleReload}
      />
      {/* <Button
        className="min-w-[6rem]"
        icon="pi pi-save"
        label="SAVE"
        onClick={(e) => {
          props.onConfirm(e, "True");
        }}
      /> */}
      <Button
        loading={loading}
        className="w-100 bg-green-500 border-green-600"
        icon="pi pi-wallet"
        label="PAY"
        onClick={(e) => {
          e.preventDefault();
          handlePay();
        }}
      />
    </div>
  );
};

export default PaymentControlSection;
