import React, { useEffect, useState } from "react";
import TableAutoComponent from "../components/complex/crud/TableAutoComponent";
import FilterComponent from "../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../utils/FilterConfigGenerator";
import generateTableConfigs from "../utils/TableConfigGenerator";
import { format as dateFnsFormat, parse } from "date-fns";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";
import { Calendar } from "primereact/calendar";
import { FaUser, FaUserDoctor, FaUserNurse } from "react-icons/fa6";

const title = "Customer Sales History";
const apiName = "sales/salesitem";
const orderBy = "created_at";
const orderMethod = "DESC";

const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const additionalParams = {
  with_sales: 1,
  with_product: 1,
  with_doctor: 1,
  with_nurse_1: 1,
  with_nurse_2: 1,
  with_nurse_3: 1,
  with_therapist_1: 1,
  with_therapist_2: 1,
  with_therapist_3: 1,
};

const configs = [
  // add_configs
  // {
  //   name: "Date",
  //   field: "data_sales.transaction_date",
  //   type: "date_date",
  //   table: true,
  // },
  {
    name: "Sales Invoice",
    field: "data_sales.number",
    type: "text_plain",
    table: true,
  },
  {
    name: "Quantity",
    field: "quantity",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Product",
    field: "data_product.name",
    type: "text_plain",
    table: true,
    filter: true,
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const CustomerSalesHistoryDialog = (props) => {
  const single = props.single ?? true;
  const { data } = props;
  let additionalFilter = "";

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    // additionalFilter,
    // visible: props.visible,
  });

  const handleLoadByDate = () => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;

    if (fromDate && !toDate) {
      toDate = fromDate;
    }

    if (fromDate && toDate && data.id) {
      const filterFromDate = `data_sales.transaction_date:>=:${dateFnsFormat(
        fromDate,
        "yyyy-MM-dd"
      )}`;
      const filterToDate = `data_sales.transaction_date:<=:${dateFnsFormat(
        toDate,
        "yyyy-MM-dd"
      )}`;
      additionalFilter = `data_sales.customer_id:=:${data.id};${filterFromDate};${filterToDate}`;
      reloadListData({
        page: 1,
        take: paging.take ?? 10,
        filter: additionalFilter,
      });
    }
  };

  useEffect(() => {
    if (props.visible) {
      handleLoadByDate();
    }
  }, [props.visible]);

  const customColumns = [
    {
      field: "data_sales.number",
      component: (rowData) => {
        if (rowData && rowData.data_sales) {
          return (
            <div className="col-start-start leading-loose">
              <span>{rowData.data_sales.number}</span>
              <span className="text-xs font-bold text-slate-400">
                {rowData.data_sales.transaction_date}
              </span>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "data_product.name",

      component: (rowData) => {
        const {
          data_product,
          data_doctor,
          data_nurse_1,
          data_nurse_2,
          data_nurse_3,
          data_therapist_1,
          data_therapist_2,
          data_therapist_3,
        } = rowData;
        if (rowData && rowData.data_product) {
          return (
            <div className="col-start-start leading-snug">
              <span>{data_product.name}</span>
              {(data_doctor ||
                data_nurse_1 ||
                data_nurse_2 ||
                data_therapist_1 ||
                data_therapist_2) && (
                <div className="row-start-center gap-1 py-1 flex-wrap">
                  {/* Doctor section */}
                  {data_doctor && (
                    <div className="row-start-center gap-2 pr-2">
                      <FaUserDoctor className="text-slate-500 text-sm" />
                      <span className="text-xs text-slate-500 pt-1">
                        {data_doctor ? data_doctor.name : "-"}
                      </span>
                    </div>
                  )}
                  {/* Nurse section */}
                  {(data_nurse_1 || data_nurse_2) && (
                    <div className="row-start-center gap-2 pr-2">
                      <FaUserNurse className="text-slate-500 text-sm" />
                      <span className="text-xs text-slate-500  pt-1">
                        {data_nurse_1.name}
                      </span>
                      {data_nurse_2 && (
                        <span className="text-xs text-slate-500 pt-1">
                          {data_nurse_2.name}
                        </span>
                      )}
                    </div>
                  )}
                  {/* Therapist section */}
                  {(data_therapist_1 || data_therapist_2) && (
                    <div className="row-start-center gap-2">
                      <FaUser className="text-slate-500 text-sm" />
                      <span className="text-xs text-slate-500 pt-1">
                        {data_therapist_1.name}
                      </span>
                      {data_therapist_2 && (
                        <span className="text-xs text-slate-500 pt-1">
                          {data_therapist_2.name}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        } else {
          return "";
        }
      },
    },
  ];

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Close"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[64rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-4`}>
        <div className="row-start-end gap-2 w-full">
          <div className="col-start-start">
            <span className="font-semibold text-sm text-zinc-400">
              Transaction Date
            </span>
            <Calendar
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.value)}
              selectionMode="range"
              readOnlyInput
              showIcon
            />
          </div>
          <Button
            className="w-[8rem] min-h-[2.6rem]"
            icon="pi pi-refresh"
            label="Load Data"
            onClick={handleLoadByDate}
          />
        </div>
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CustomerSalesHistoryDialog;
