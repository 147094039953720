import classes from "./LoginPage.module.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./../../store/auth-slice";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
// import { process.env.REACT_APP_APP_NAME, process.env.REACT_APP_MY_URL, SLC_LOGIN_URL } from "../../configs/constants";
import { Divider } from "primereact/divider";
import UiContext from "../../store/ui-context";

const title = process.env.REACT_APP_APP_NAME;
const backgroundSmallUrl = `${process.env.REACT_APP_MY_URL}/small-background.png`;
const backgroundUrl = `${process.env.REACT_APP_MY_URL}/background.png`;

const LoginPage = () => {
  const uiContext = useContext(UiContext);

  const userNameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useRef(null);

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const [errorDetail, setErrorDetail] = useState({});

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.slc_data) {
        const { access_token, data } = event.data.slc_data;
        const { name, username, email, nip } = data;
        dispatch(login({ loginToken: access_token })).then((result) => {
          if (!result || result === null) {
            navigate(
              `/sign-up?name=${name.replace(
                " ",
                "%20"
              )}&username=${username}&email=${email}&nip=${nip}&token=${access_token}`
            );
          } else if (result && result !== null) {
            navigate("/");
          }
        });
      }
    });
  }, []);

  const handleLogin = async () => {
    const username = inputUsername.trim();
    const password = inputPassword.trim();
    const responseData = await dispatch(
      login({ username: username, password: password })
    );
    if (responseData) {
      const { redirect_url } = responseData;
      // console.log("RESPONSE DATA", responseData);
      uiContext.showSuccessMsg("Success", "Login Success");
      navigate(redirect_url);
    } else {
      uiContext.showErrorMsg("Error", "Login Failed");
    }
  };

  const handleOnKeyDownUsernameInput = (event) => {
    if (event.keyCode === 13) {
      passwordInputRef.current.focus();
    }
  };

  const handleOnKeyDownPasswordInput = (event) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div
      className={`${classes.wrapper} row-center-center w-100 `}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <Toast ref={toast} />

      <div className={`row-center-center bg-white rounded-md shadow-md`}>
        <div className={`${classes.bgSection} p-3`}>
          <img className="rounded w-[30rem]" src={backgroundSmallUrl} />
        </div>
        <section className={`col-start-start h-full pl-8 pr-9`}>
          <div className="row-start-center w-100">
            <span className={`text-4xl font-bold tracking-[0.3rem]`}>
              {title}
            </span>
          </div>
          <Divider className="" />

          <div className="col-center-start gap-1">
            <span className={`text-lg font-semibold`}>Hey, welcome back !</span>
            <span className={`text-sm font-semibold text-zinc-400`}>
              Sign in to continue
            </span>
          </div>

          <Divider className="" />

          <div className="col-center-center w-full py-4 gap-6">
            <div className="col-center-center gap-4 w-full">
              <div className="col-start-start gap-1 w-full">
                <InputText
                  ref={userNameInputRef}
                  className={`${classes.customInput} !w-[20rem]`}
                  id="username"
                  placeholder="Username"
                  aria-describedby="username-help"
                  onChange={(e) => setInputUsername(e.target.value)}
                  onKeyDown={handleOnKeyDownUsernameInput}
                />
                {errorDetail && errorDetail.username && (
                  <span className={`${classes.errorLabel} `}>
                    {errorDetail.username}
                  </span>
                )}
              </div>
              <div className="col-start-start gap-1 w-100">
                <Password
                  ref={passwordInputRef}
                  className={`${classes.customInput}`}
                  id="password"
                  placeholder="Password"
                  aria-describedby="password-help"
                  toggleMask
                  onChange={(e) => setInputPassword(e.target.value)}
                  onKeyDown={handleOnKeyDownPasswordInput}
                />
                {errorDetail && errorDetail.password && (
                  <span className={`${classes.errorLabel} `}>
                    {errorDetail.password}
                  </span>
                )}
              </div>
              {error && (
                <span className={`${classes.errorLabelSingle} `}>{error}</span>
              )}
            </div>
            <div className="col-center-center gap-2 w-full">
              <Button
                className={`${classes.loginButton} w-100`}
                label="LOGIN"
                loading={loading}
                onClick={handleLogin}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LoginPage;
