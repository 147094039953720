import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

const SearchProductSection = (props) => {
  const { loading, onFilter } = props;
  const [filterText, setFilterText] = useState("");

  return (
    <div className="row-center-center gap-1">
      <InputText
        placeholder="Search Product ..."
        value={filterText}
        onChange={(event) => setFilterText(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onFilter(filterText);
          }
        }}
      />
      <Button
        className="max-w-[2.43rem] max-h-[2.43rem]"
        icon="pi pi-search"
        outlined
        loading={loading}
        onClick={(event) => {
          event.preventDefault();
          onFilter(filterText);
        }}
      />
    </div>
  );
};

export default SearchProductSection;
