import classes from "./ZirvuFileManagerInput.module.css";
import { Button } from "primereact/button";
import { useCallback, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import ZirvuFileManagerInputItem from "./ZirvuFileManagerInputItem";
import { get, getOne, post, del } from "./ZirvuFileManagerAPIRequest";
import { ProgressSpinner } from "primereact/progressspinner";
import { FileUpload } from "primereact/fileupload";
import { ContextMenu } from "primereact/contextmenu";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ZirvuFileManagerDialogInputText from "./ZirvuFileManagerDialogInputText";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

const ZirvuFileManagerInput = (props) => {
  const cm = useRef(null);
  const fileUploadRef = useRef(null);
  const dialogInputTextRef = useRef(null);

  const [value, setValue] = useState(props.value);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogInputTextNewFolderVisible, setDialogInputTextNewFolderVisible] =
    useState(false);
  const [dialogInputTextRenameVisible, setDialogInputTextRenameVisible] =
    useState(false);
  const [listItemSource, setListItemSource] = useState([]);
  const [listItem, setListItem] = useState([]);

  const [clipboard, setClipboard] = useState({ type: "", id: -1, name: "" });
  const [loading, setLoading] = useState(false);
  const [folderId, setFolderId] = useState(-1);
  const [currentPath, setCurrentPath] = useState("");
  const [backFolderId, setBackFolderId] = useState(-1);
  const [order, setOrder] = useState("");
  const [orderMethod, setOrderMethod] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedType, setSelectedType] = useState("");
  const [selectedElement, setSelectedElement] = useState({});
  const [showDropableEffect, setShowDropableEffect] = useState(false);
  // const [dialogContentType, setDialogContentType] = useState(""); // '', 'explorer', 'dropable-notif', 'file-on-top'

  const tokenFileManager = useSelector((state) => state.auth.tokenFileManager);

  useEffect(() => {
    if (dialogVisible) {
      loadFolder({ token: tokenFileManager, folderIdFilter: folderId });
    }
  }, [dialogVisible]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const loadFolder = async ({ token, folderId }) => {
    try {
      setLoading(true);
      const folderIdFilter = folderId && folderId > 0 ? folderId : "Null";
      setFolderId(folderIdFilter === "Null" ? -1 : folderId);
      const searchFilter = searchText;

      const response = await get({
        token: token,
        url: "/api/dir/data",
        params: {
          filter: `folder_id:=:${folderIdFilter};name:contains:${searchFilter}`,
          order: order.length === 0 ? "name" : order,
          order_method: orderMethod.length === 0 ? "ASC" : orderMethod,
        },
      });
      const { success, message, data, pagination } = response.data;
      if (success) {
        const { back_id, contents, current_path } = data;
        setBackFolderId(back_id);
        setCurrentPath(current_path);
        setListItem([...contents]);
        setListItemSource([...contents]);
        setSearchText("");
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR LOAD", errorResponse);
    }
    setLoading(false);
  };

  const saveFile = async ({ token, file }) => {
    try {
      setLoading(true);

      const body = { file: file };
      if (folderId > 0) {
        body["folder_id"] = folderId;
      }
      console.log("BODY", body);
      const response = await post({
        isForm: true,
        token: token,
        url: "/api/file/addfile",
        body,
      });
      const { success, data } = response.data;
      if (success) {
        const { id } = data;
        setSelectedId(id);
        setSelectedType("file");
        setSelectedElement(data);
        setClipboard({ id: -1, type: "", name: "" });
        loadFolder({ token: tokenFileManager, folderId });
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR SAVE", errorResponse);
    }
    setLoading(false);
  };

  const renameFile = async ({ token, id, name }) => {
    try {
      setLoading(true);

      const body = { name: name };
      if (folderId > 0) {
        body["folder_id"] = folderId;
      }
      if (id && id !== null && id > 0) {
        body["id"] = id;
      }
      const response = await post({
        isForm: true,
        token: token,
        url: "/api/file/renamefile",
        body,
      });
      const { success, data } = response.data;
      if (success) {
        const { id } = data;
        setSelectedId(id);
        setSelectedType("file");
        setSelectedElement(data);
        setClipboard({ id: -1, type: "", name: "" });
        loadFolder({ token: tokenFileManager, folderId });
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR SAVE", errorResponse);
    }
    setLoading(false);
  };

  const deleteFile = async ({ token, id }) => {
    try {
      setLoading(true);

      const body = { data: [id] };
      const response = await del({
        token: token,
        url: "/api/file/delete",
        body,
      });
      const { success, data } = response.data;
      if (success) {
        setSelectedId(-1);
        setSelectedType("");
        setSelectedElement(null);
        loadFolder({ token: tokenFileManager, folderId });
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR SAVE", errorResponse);
    }
    setLoading(false);
  };

  const saveFolder = async ({ token, id, name }) => {
    try {
      setLoading(true);

      const body = {};
      if (name && name.length > 0) {
        body["name"] = name;
      }
      if (folderId > 0) {
        body["folder_id"] = folderId;
      }
      if (id && id !== null && id > 0) {
        body["id"] = id;
      }
      const response = await post({
        isForm: true,
        token: token,
        url: "/api/folder/save",
        body,
      });
      const { success, data } = response.data;
      if (success) {
        const { id } = data;
        setSelectedId(id);
        setSelectedType("folder");
        setSelectedElement(data);
        setClipboard({ id: -1, type: "", name: "" });
        loadFolder({ token: tokenFileManager, folderId });
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR SAVE", errorResponse);
    }
    setLoading(false);
  };

  const deleteFolder = async ({ token, id }) => {
    try {
      setLoading(true);

      const body = { data: [id] };
      const response = await del({
        token: token,
        url: "/api/folder/delete",
        body,
      });
      const { success, data } = response.data;
      if (success) {
        setSelectedId(-1);
        setSelectedType("");
        setSelectedElement(null);
        loadFolder({ token: tokenFileManager, folderId });
      }
    } catch (error) {
      const errorResponse = error.response;
      console.log("ERROR SAVE", errorResponse);
    }
    setLoading(false);
  };

  const filter = (event) => {
    const newText = event.target.value;
    setSearchText(newText);

    if (!newText || newText.length === 0) {
      setListItem([...listItemSource]);
    } else {
      const newList = listItemSource.filter((el) =>
        el.name.toLowerCase().includes(newText.toLowerCase())
      );
      setListItem(newList);
    }
  };

  const back = () => {
    loadFolder({ token: tokenFileManager, folderId: backFolderId });
  };

  const upload = (event) => {
    const file = event.files[0];
    saveFile({ token: tokenFileManager, file });
    fileUploadRef.current.clear();
  };

  const newFolder = (event) => {
    setDialogInputTextNewFolderVisible(true);
  };

  const newFolderAction = (event, value) => {
    saveFolder({ token: tokenFileManager, id: null, name: value });
  };

  const rename = (event) => {
    if (selectedId && selectedId !== null && selectedId > 0) {
      setDialogInputTextRenameVisible(true);
    }
  };

  const renameAction = (event, value) => {
    if (selectedElement.type === "file") {
      renameFile({ token: tokenFileManager, id: selectedId, name: value });
    } else if (selectedElement.type === "folder") {
      saveFolder({ token: tokenFileManager, id: selectedId, name: value });
    }
  };

  const cut = (event) => {
    setClipboard({
      id: selectedId,
      type: selectedType,
      name: selectedElement.name,
    });
  };

  const paste = (event) => {
    if (clipboard.type !== "" && clipboard.id > 0) {
      if (clipboard.type === "file") {
        renameFile({
          token: tokenFileManager,
          id: clipboard.id,
          name: clipboard.name,
        });
      } else if (clipboard.type === "folder") {
        saveFolder({
          token: tokenFileManager,
          id: clipboard.id,
          name: clipboard.name,
        });
      }
    }
  };

  const remove = (event) => {
    const type = selectedElement.type === "folder" ? "Folder" : "File";

    const accept = () => {
      removeAction();
    };

    const reject = () => {};

    confirmDialog({
      message: `Hapus ${type} terseleksi, lanjutkan ?`,
      header: "Konfirmasi Hapus",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Hapus",
      rejectLabel: "Batal",
      accept,
      reject,
    });
  };

  const removeAction = (event) => {
    if (selectedElement.type === "file") {
      deleteFile({ token: tokenFileManager, id: selectedId });
    } else if (selectedElement.type === "folder") {
      deleteFolder({ token: tokenFileManager, id: selectedId });
    }
  };

  const onDrop = (item) => {
    if (item) {
      // console.log("FOLDER ID", folderId);
      const files = item.files;
      if (files && files.length > 0) {
        const file = files[0];
        saveFile({ token: tokenFileManager, file });
      }
    }
  };
  // const onDragOver = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   console.log("DRAG OVER", event);
  // };

  // const onDrop = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   // const files = event.target.files;
  //   console.log("DROP", event);
  // };

  const contextMenuItems = [
    { label: "New Folder", icon: "pi pi-fw pi-plus", command: newFolder },
    {
      label: "Rename",
      icon: "pi pi-fw pi-pencil",
      command: rename,
      disabled: !selectedId || selectedId === null || selectedId <= 0,
    },
    { separator: true },
    {
      label: "Cut",
      icon: "pi pi-fw pi-download",
      command: cut,
      disabled: !selectedId || selectedId === null || selectedId <= 0,
    },
    {
      label: "Paste",
      icon: "pi pi-fw pi-clone",
      command: paste,
      disabled: clipboard.type === "" || clipboard.id <= 0,
    },
    { separator: true },
    {
      label: "Delete",
      icon: "pi pi-fw pi-trash",
      command: remove,
      disabled: !selectedId || selectedId === null || selectedId <= 0,
    },
  ];

  const width = props.width ?? "100px";
  const height = props.height ?? "100px";
  // const title = props.title ?? "Zirvu File Manager";

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        // setDialogContentType("dropable-notif");
        // console.log("canDrop", item.files, item.items);
        // console.log("canDrop");
        return true;
      },
      hover(item) {
        // console.log("hover", item.files, item.items);
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        if (item) {
          // console.log("collect", item.files, item.items);
        }
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [folderId]
  );

  const isActive = canDrop && isOver;
  useEffect(() => {
    if (isActive) {
      setShowDropableEffect(true);
    } else {
      setShowDropableEffect(false);
    }
  }, [isActive]);

  const generateDialogContent = () => {
    if (loading) {
      return (
        <div
          className={`${classes.overlay} col-center-center w-100 h-100 gap-3`}
        >
          <ProgressSpinner />
          <h3> Loading data ... </h3>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative" }}>
          <div className={`row-start-start wrap gap-2`}>
            <ContextMenu
              global
              model={contextMenuItems}
              ref={cm}
              breakpoint="767px"
            />
            {listItem.map((element) => {
              return (
                <ZirvuFileManagerInputItem
                  opacity={
                    clipboard.type === element.type &&
                    clipboard.id === element.id
                      ? 0.3
                      : 1
                  }
                  key={`${element.type}-${element.id}`}
                  {...element}
                  selected={
                    element.id === selectedId && element.type === selectedType
                  }
                  // onContextMenu={(event) => {
                  //   event.preventDefault();
                  //   setSelectedId(element.id);
                  // }}
                  onRightClick={(event) => {
                    event.preventDefault();
                    setSelectedId(element.id);
                    setSelectedType(element.type);
                    setSelectedElement(element);
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    if (event.detail < 2) {
                      setSelectedId(element.id);
                      setSelectedType(element.type);
                      setSelectedElement(element);
                    } else {
                      if (element.type === "folder") {
                        loadFolder({
                          token: tokenFileManager,
                          folderId: element.id,
                        });
                      } else {
                        props.onSelect(event, element);
                        setValue(element.url);
                        setDialogVisible(false);
                      }
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${classes.wrapper} ${props.className} row-center-center`}
      style={{ width: width, height: height }}
    >
      <Dialog
        visible={dialogVisible}
        style={{ height: "44rem", width: "50rem" }}
        header={`/${currentPath}`}
        modal
        className={`p-fluid`}
        // footer={dialogFooter}
        onHide={(e) => setDialogVisible(false)}
      >
        <div className={`col-start-start w-100 gap-3 h-100`}>
          <div className={`${classes.dialogHeader} row-between-center w-100`}>
            <div className={"row-start-center gap-3 py-1 w-80"}>
              <Button
                icon="pi pi-arrow-left"
                label={"Up a folder"}
                text
                onClick={(event) => {
                  back();
                }}
                style={{ width: "120px" }}
              />
              <FileUpload
                ref={fileUploadRef}
                auto
                mode="basic"
                name="file[]"
                accept="*/*"
                chooseOptions={{
                  label: "Upload File",
                  icon: "pi pi-upload",
                  // style: { width: "130px" },
                }}
                maxFileSize={1000000}
                customUpload
                uploadHandler={(event) => upload(event)}
              />
            </div>
            <span className="p-input-icon-left" style={{ width: "12rem" }}>
              <i className="pi pi-search" />
              <InputText
                placeholder="Search"
                value={searchText}
                onChange={filter}
              />
            </span>
          </div>
          <div
            className={`${classes.dialogContent} row-start-start w-100 h-100 p-2`}
            onContextMenu={(e) => {
              cm.current.show(e);
            }}
            // onDrop={handleFileDrop}
            ref={drop}
          >
            {generateDialogContent()}
            {showDropableEffect && (
              <div
                className="col-center-center w-100 h-100 gap-3"
                style={{
                  backgroundColor: "#cce8ffDD",
                  zIndex: 100,
                  top: 0,
                  left: 0,
                  position: "absolute",
                  borderRadius: "var(--border-radius-0)",
                }}
              >
                <i
                  className="pi pi-image mt-1 p-4"
                  style={{
                    fontSize: "7em",
                    borderRadius: "50%",
                    backgroundColor: "var(--surface-b)",
                    color: "#cce8ff",
                  }}
                ></i>
                <h3>Drop your image here</h3>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <ZirvuFileManagerDialogInputText
        title={"New Folder"}
        value={""}
        prompt={"Nama Folder Baru"}
        visible={dialogInputTextNewFolderVisible}
        onConfirm={(e, newValue) => {
          newFolderAction(e, newValue);
          setDialogInputTextNewFolderVisible(false);
        }}
        onHide={(e) => setDialogInputTextNewFolderVisible(false)}
      />

      <ZirvuFileManagerDialogInputText
        title={
          selectedElement && selectedElement.type === "file"
            ? "Rename File"
            : "Rename Folder"
        }
        value={selectedElement ? selectedElement.name : ""}
        prompt={
          selectedElement && selectedElement.type === "file"
            ? "Nama File Baru"
            : "Nama Folder Baru"
        }
        visible={dialogInputTextRenameVisible}
        onConfirm={(e, newValue) => {
          renameAction(e, newValue);
          setDialogInputTextRenameVisible(false);
        }}
        onHide={(e) => setDialogInputTextRenameVisible(false)}
      />

      <div
        className={`col-center-center ${classes.inputContainer}`}
        onClick={async (e) => {
          setDialogVisible(true);
        }}
      >
        {value && (
          <img
            width={width}
            src={value}
            style={{
              padding: "5px",
              width: "auto",
              maxWidth: "100%",
              height: "auto",
              maxHeight: "100%",
            }}
          ></img>
        )}
        <Button
          icon="pi p-fw pi-search"
          className={`${
            value && value.length > 0
              ? classes.inputContainerButtonFilled
              : classes.inputContainerButton
          }`}
          style={{ maxWidth: "120px" }}
          label="Browse"
          outlined={value && value.length > 0 ? false : true}
          onClick={async (e) => {
            setDialogVisible(true);
          }}
        />
      </div>
    </div>
  );
};

export default ZirvuFileManagerInput;
