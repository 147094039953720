import { subDays, addDays } from "date-fns";

const DiscountConfigs = {
  master: {
    title: "Discount",
    apiName: "masterdata/discount",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
    },
  },
  dialog: {
    title: "Discount",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Value",
      field: "value",
      type: "number_plain",
      editor: true,
      align: "right",
      table: true,
    },
    {
      name: "Type",
      field: "discount_type",
      table: true,
      filter: true,
      editor: true,
      type: "number_dropdown",
      align: "center",
      default_value: 1,
      options: [
        { name: "Rupiah", value: 1 },
        { name: "Percent %", value: 2 },
        { name: "Quantity", value: 3 },
      ],
    },
    {
      name: "Category",
      field: "discount_category",
      table: true,
      filter: true,
      editor: true,
      type: "number_dropdown",
      align: "center",
      default_value: 1,
      options: [
        { name: "Disc. Item", value: 1 },
        { name: "Disc. Subtotal", value: 2 },
      ],
    },
    {
      name: "Active",
      field: "active",
      align: "center",
      table: false,
      editor: true,
      type: "boolean_plain",
      filter: true,
    },
  ],
};

export default DiscountConfigs;
