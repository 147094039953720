import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isToday,
  startOfMonth,
} from "date-fns";
import EventCalendarEventItem from "./EventCalendarEventItem";

const bgColors = [
  "bg-green-300",
  "bg-yellow-300",
  "bg-purple-300",
  "bg-blue-300",
  "bg-red-300",
  "bg-pink-300",
  "bg-indigo-300",
  "bg-cyan-300",
  "bg-rose-300",
  "bg-emerald-300",
];

const textColors = [
  "text-green-500",
  "text-yellow-500",
  "text-purple-500",
  "text-blue-500",
  "text-red-500",
  "text-pink-500",
  "text-indigo-500",
  "text-cyan-500",
  "text-rose-500",
  "text-emerald-500",
];

const EventCalendarItem = (props) => {
  const { day, events, onClick } = props;
  let today = isToday(day);

  const isPast = day < new Date().setHours(0, 0, 0, 0);

  return (
    <div
      className={`col-start-start cursor-pointer border p-0 text-center ${
        isPast ? "bg-gray-50 " : " hover:shadow-md hover:-translate-y-0.5 "
      } w-full transition duration-150 ease-in-out active:translate-y-0 active:shadow-none`}
      onClick={(event) => {
        event.preventDefault();
        onClick(day);
      }}
    >
      <div className={`col-start-start py-1 px-2 w-full gap-3 hover:bg-red`}>
        <div
          className={`row-center-center ${
            today ? "bg-[var(--color-primary)]" : ""
          } rounded-full w-[1.5rem] h-[1.5rem]`}
        >
          <span
            className={`text-xs font-semibold ${
              today ? "text-white" : "text-slate-400"
            }`}
          >
            {format(day, "d")}
          </span>
        </div>
        <div className={`col-start-start gap-2 w-full pb-2`}>
          {events.map((event, index) => {
            return (
              <EventCalendarEventItem
                key={index}
                backgroundColor={bgColors[index % bgColors.length]}
                textColor={textColors[index % textColors.length]}
                date={event.date}
                title={event.title}
                animate={isPast ? false : event.animate}
                stale={isPast ? false : event.stale}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default EventCalendarItem;
