import { subDays, addDays } from "date-fns";

const PaymentConfigs = {
  master: {
    title: "Payment",
    apiName: "masterdata/payment",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
    },
  },
  dialog: {
    title: "Payment",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
    {
      name: "Type",
      field: "type",
      type: "text_dropdown",
      table: true,
      filter: true,
      editor: true,
      default_value: 1,
      options: [
        { name: "CASH", value: 1 },
        { name: "CHECKS", value: 2 },
        { name: "DEBIT", value: 3 },
        { name: "CREDIT", value: 4 },
        { name: "MOBILE", value: 5 },
        { name: "TRANSFER", value: 6 },
      ],
    },
    {
      name: "Amount",
      field: "amount",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
  ],
};

export default PaymentConfigs;
