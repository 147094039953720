import { subDays, addDays } from "date-fns";

const CRMLevelConfigs = {
  master: {
    title: "CRM Level",
    apiName: "crm/crmlevel",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
    },
  },
  dialog: {
    title: "CRM Level",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Level",
      field: "level",
      table: true,
      // filter: true,
      editor: true,
      type: "number_plain",
      align: "right",
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Threshold",
      field: "threshold",
      type: "number_plain",
      editor: true,
      align: "right",
      table: true,
    },
    {
      name: "Next Threshold",
      field: "next_threshold",
      type: "number_plain",
      editor: true,
      align: "right",
      table: true,
    },
    {
      name: "Is Max",
      field: "is_max",
      align: "center",
      table: true,
      editor: true,
      type: "boolean_plain",
      filter: true,
    },
  ],
};

export default CRMLevelConfigs;
