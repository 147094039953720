import classes from "./UserHomePage.module.css";
import { FaBoxesStacked } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApplicationButton from "./components/ApplicationButton";
import { useEffect } from "react";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";

const title = "Home";

const UserHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const handleHasilJadiPerJam = (event) => {
    navigate("/user/hasil-jadi-per-jam");
  };

  return (
    <div className="col-start-start">
      <div className="row-start-start p-4 ">
        <ApplicationButton
          icon={
            <FaBoxesStacked
              className="group-hover:text-[#666cff] group-active:text-white transition-colors"
              size={40}
            />
          }
          label="Hasil Jadi Per Jam"
          onClick={handleHasilJadiPerJam}
        />
      </div>
    </div>
  );
};

export default UserHomePage;
