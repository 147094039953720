import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, parse } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import UiContext from "../../../../store/ui-context";
import WarehouseSelectorDialog from "../../../../dialogs/selectors/WarehouseSelectorDialog";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { InputText } from "primereact/inputtext";

const defaultDate = new Date();
defaultDate.setDate(1);

const title = "Stock Movement";
const additionalParams = {
  with_product: 1,
  prefix: "stock_movement",
};
const order = "product_name";
const order_method = "ASC";

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number_plain",
    align: "center",
    table: true,
  },
  {
    name: "Product Name",
    field: "data_product.name",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Start",
    field: "start",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "In",
    field: "total_in",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Out",
    field: "total_out",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "End",
    field: "end",
    type: "number_plain",
    align: "right",
    table: true,
    filter: true,
  },
];

const StockMovementPage = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const refFilter = useRef(null);

  const [paging, setPaging] = useState({
    page: 1,
    take: 10,
    filter: "",
    total: 0,
    ...additionalParams,
  });
  const [listData, setListData] = useState([]);

  const firstDateOfMonth = new Date();
  firstDateOfMonth.setDate(1);
  const lastDateOfMonth = new Date();

  const [selectedTransactionDate, setTransactionDate] = useState([
    firstDateOfMonth,
    lastDateOfMonth,
  ]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const [visibleWarehouseSelector, setVisibleWarehouseSelector] =
    useState(false);

  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const generateFilter = () => {
    let filter = "";

    const filterText = refFilter.current.value.trim();

    if (filterText && filterText.length > 0) {
      filter += `data_product.name:contains:%${filterText}%`;
    }

    let dateStart = selectedTransactionDate[0];
    let dateEnd = selectedTransactionDate[1];

    if (dateStart) {
      if (filter.length > 0) filter += ";";
      filter += `start_date:=:${dateFnsFormat(dateStart, "yyyy-MM-dd")}`;
    }

    if (dateEnd) {
      if (filter.length > 0) filter += ";";
      filter += `end_date:=:${dateFnsFormat(dateEnd, "yyyy-MM-dd")}`;
    }

    if (selectedWarehouse) {
      if (filter.length > 0) filter += ";";
      filter += `warehouse_id:=:${selectedWarehouse.id}`;
    }

    if (filter.length > 0) filter += ";";
    filter += `data_product.product_type:=:2`;

    return filter;
  };

  const reloadData = async ({ page, take }) => {
    if (loading) return;

    setListData([]);

    if (selectedWarehouse === null) {
      uiContext.showErrorMsg("Error", "Warehouse is required");
      return;
    }

    const filter = generateFilter();

    const params = {
      page: page,
      take: take,
      filter,
      ...additionalParams,
      order,
      order_method,
    };

    return requestGet({
      fullUrl: "api/report",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        data.forEach((item, index) => {
          newList.push({
            id: item.data_product.id,
            no: startNumber + index + 1,
            data_product: item.data_product,
            product_id: item.product_id,
            start: item.start,
            total_in: item.total_in,
            total_out: item.total_out,
            end: item.end,
          });
        });

        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
        });
        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleReload = () => {
    reloadData({ page: 1, take: 10 });
  };

  const handleConfirmWarehouseSelectorBox = (e, value) => {
    e.preventDefault();
    if (value && value.length > 0) {
      setSelectedWarehouse(value[0]);
      setVisibleWarehouseSelector(false);
    }
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake });
  };

  const handleEnterFilter = (e) => {
    const key = e.key;
    if (key === "Enter") {
      reloadData({ page: 1, take: paging.take });
    }
  };

  const generateSelector = () => {
    return (
      <WarehouseSelectorDialog
        visible={visibleWarehouseSelector}
        onHide={() => setVisibleWarehouseSelector(false)}
        onConfirm={handleConfirmWarehouseSelectorBox}
      />
    );
  };

  const customColumns = [];

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      {generateSelector()}
      <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]">
          {/* field selector selectedTransactionDate */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"selectedTransactionDate"}
              className="font-bold text-sm text-zinc-500"
            >
              Date
            </label>
            <Calendar
              id="selectedTransactionDate"
              value={selectedTransactionDate}
              showButtonBar
              selectionMode="range"
              hourFormat="24"
              showIcon
              onChange={(e) => {
                setTransactionDate(e.value);
              }}
              className={`dateInput w-[17rem]`}
              locale="id"
            />
          </div>
          {/* field selector gudang */}
          <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"wilayah"}
              className="font-bold text-sm text-zinc-500"
            >
              Warehouse
            </label>
            <Button
              className={`w-[14rem]`}
              outlined
              onClick={(e) => {
                e.preventDefault();
                setVisibleWarehouseSelector(true);
              }}
            >
              <div className="row-between-center w-100">
                {selectedWarehouse ? selectedWarehouse.name : "-"}{" "}
                <i className="pi pi-search" />
              </div>
            </Button>
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center">
          <div className="row-start-center gap-2">
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <i className="pi pi-search"></i>
              </span>
              <InputText
                ref={refFilter}
                placeholder="Find Product"
                onKeyDown={handleEnterFilter}
              />
            </div>
          </div>
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          />
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default StockMovementPage;
