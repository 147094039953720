import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { useEffect, useState } from "react";
import { Knob } from "primereact/knob";
import { Button } from "primereact/button";
// import { process.env.REACT_APP_HOST_URL } from "../../../configs/constants";

const title = "Home";

const HomePage = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [eventSource, setEventSource] = useState(null);

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  // function to start sse request and listen to the server
  // to get the json data
  const startRequest = () => {
    if (eventSource) eventSource.close();

    setValue(0);
    const newEventSource = new EventSource(
      `${process.env.REACT_APP_HOST_URL}/api/test`
    );
    setEventSource(newEventSource);

    newEventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.success) {
        console.log("RESPONSE : " + JSON.stringify(data));
        const knobValue = (100.0 / 20.0) * data.data;
        setValue(knobValue);
        if (data.data === 20) newEventSource.close();
      }
    };
    newEventSource.onerror = (error) => {
      console.log("error", error);
      newEventSource.close();
    };

    return () => {
      newEventSource.close();
    };
  };

  const handleStartRequest = (event) => {
    event.preventDefault();
    startRequest();
  };

  return (
    <div className={`row-start-start p-4 gap-4 w-100`}>
      <div
        className={`elevated-card-tight-vertical col-center-center w-full !px-4 gap-5 grow`}
      >
        <div className={`col-center-center gap-4`}>
          <Knob value={value} step={10} onChange={(e) => setValue(e.value)} />
          <Button
            label={
              eventSource
                ? eventSource.readyState === EventSource.OPEN
                  ? "Stop Request"
                  : "Start Request"
                : "Start Request"
            }
            severity={
              eventSource
                ? eventSource.readyState === EventSource.OPEN
                  ? "danger"
                  : "primary"
                : "primary"
            }
            className="p-button-raised"
            onClick={handleStartRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
