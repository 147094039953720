const SignUpConfigs = [
  {
    name: "Profile Picture",
    field: "image",
    default_value: null,
    type: "zirvu_file_manager",
    table: true,
    editor: true,
  },
  {
    name: "Name",
    field: "name",
    type: "text_plain",
  },
  {
    name: "User Name",
    field: "data_user.username",
    type: "text_plain",
  },
  {
    name: "Password",
    field: "data_user.password",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
  {
    name: "Password Confirmation",
    field: "data_user.cpassword",
    default_value: "",
    default_value_on_edit: true,
    type: "text_password",
  },
];

export default SignUpConfigs;
