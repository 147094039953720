import { subDays, addDays } from "date-fns";

const ExpenseConfigs = {
  master: {
    title: "Expense",
    apiName: "masterdata/expense",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: { 
      // add_params
      with_expense_group: 1,
    },
  },
  dialog: {
    title: "Expense",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Expense Group",
      field: "expense_group_id",
      type: "object",
      object_name: "data_expense_group",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Transaction Date",
      field: "transaction_date",
      type: "date",
      align: "center",
      table: true,
      editor: true,
      format: "dd/MM/yyyy",
      prime_format: "d MM yy",
      showTime: 1,
      object_name: "",
      object_field: "",
    },
    {
      name: "Amount",
      field: "amount",
      type: "number_plain",
      editor: true,
      align: "right",
      object_name: "",
      object_field: "",
      table: true,
    },
    {
      name: "Description",
      field: "description",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
      object_name: "",
      object_field: "",
    },
  ],
};

export default ExpenseConfigs;
