import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const title = "Change Back";

const ChangeDialog = (props) => {
  const { onConfirm, value } = props;

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (props.visible) {
    }
  }, [props.visible]);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Button
        className="w-full"
        label="Okay"
        onClick={(e) => {
          e.preventDefault();
          props.onHide();
        }}
      />
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[21rem] min-h-[15rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="col-center-center w-full gap-3">
        <span className="font-semibold text-zinc-400 text-4xl py-4">
          {amount ? amount.toLocaleString("id-ID") : "0"}
        </span>
      </div>
    </Dialog>
  );
};

export default ChangeDialog;
