import React, { useEffect } from "react";
import TableAutoComponent from "../components/complex/crud/TableAutoComponent";
import FilterComponent from "../components/complex/crud/FilterComponent";
import generateFilterConfigs from "../utils/FilterConfigGenerator";
import generateTableConfigs from "../utils/TableConfigGenerator";
import { format as dateFnsFormat, parse } from "date-fns";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";

import useSelectorDialogControl from "../custom_hooks/complex/useSelectorDialogControl";

const title = "Customer Package";
const apiName = "customer/customerfreeproduct";
const orderBy = "product_id";
const orderMethod = "ASC";
const additionalParams = { with_source_sales_item: 1, with_product: 1 };

const customColumns = [];

const configs = [
  // add_configs
  {
    name: "Date",
    field: "data_source_sales_item.data_sales.transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Expired",
    field: "expired_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Qty",
    field: "quantity",
    type: "number_plain",
    align: "right",
    table: true,
  },
  {
    name: "Product",
    field: "data_product.name",
    type: "text_plain",
    table: true,
    filter: true,
  },
];

const filterConfigs = generateFilterConfigs(configs);
const tableConfigs = generateTableConfigs(configs);

const AddCustomerPackageDialog = (props) => {
  const { visible, data } = props;
  const single = props.single ?? true;
  let additionalFilter =
    `type:=:2;usage_date:=:Null;expired_date:>=:${dateFnsFormat(
      new Date(),
      "yyyy-MM-dd"
    )}` + (data && data.id ? `;customer_id:=:${data.id}` : "");

  const {
    toast,
    listData,
    paging,
    loading,
    setListData,
    selectedRows,
    setSelectedRows,
    refineValue,
    reloadListData,
    handleOnFilter,
    handleOnPage,
    handleSelectionChange,
  } = useSelectorDialogControl({
    apiName,
    orderBy,
    orderMethod,
    additionalParams,
    additionalFilter,
    visible: props.visible,
  });

  const dialogFooter = (
    <div className="col-center-center w-100">
      <Paginator
        first={paging.take * (paging.page - 1)}
        rows={paging.take}
        totalRecords={paging.total}
        rowsPerPageOptions={[5, 8, 10, 20, 50]}
        onPageChange={handleOnPage}
      />
      <div className="row-end-end w-100">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Pilih"
          icon="pi pi-check"
          onClick={(e) => {
            const refinedValue = refineValue(selectedRows);
            props.onConfirm(e, refinedValue);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[23rem] sm:w-[64rem] min-h-[40rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className={`col-start-start w-100 gap-3`}>
        {filterConfigs && filterConfigs.length > 0 && (
          <FilterComponent onFilter={handleOnFilter} configs={filterConfigs} />
        )}
        <div className={`col-center-center w-100 gap-2`}>
          <TableAutoComponent
            singleMode={single}
            selection={selectedRows}
            onSelectionChange={handleSelectionChange}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            customColumns={customColumns}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AddCustomerPackageDialog;
