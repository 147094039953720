import { Button } from "primereact/button";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { useContext, useEffect, useState } from "react";
import UiContext from "../../../../store/ui-context";
import { confirmDialog } from "primereact/confirmdialog";

const RegistrationRewardSetting = (props) => {
  const { setting, setSetting } = props;

  const uiContext = useContext(UiContext);
  const [registerRewards, setRegisterRewards] = useState([]);
  const [showRewardSelectorDialog, setShowRewardSelectorDialog] =
    useState(false);
  const { loading, requestGet, requestPost } = useAPIRequest();

  useEffect(() => {
    if (setting) {
      loadBirthDayRewards(setting);
    }
  }, [setting]);

  const loadBirthDayRewards = async (setting) => {
    if (setting) {
      const rewardIds = setting.value_text.split(",");
      const filter = rewardIds.map((id) => `id:=:${id}`).join("||");
      await requestGet({
        fullUrl: `/api/masterdata/product/data`,
        params: {
          filter,
          page: 1,
          take: 100,
          order: "name",
          order_method: "ASC",
        },
        onSuccess: ({ pagination, message, data }) => {
          setSetting(setting);
          const mapProduct = [];
          const _products = [];
          for (const product of data) {
            mapProduct[product.id] = product;
          }
          for (const productId of rewardIds) {
            _products.push(mapProduct[parseInt(productId)]);
          }
          setRegisterRewards(_products);
        },
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
      });
    }
  };

  const saveRegisterReward = async (rewards) => {
    const _registerRewardIdText = rewards.map((reward) => reward.id).join(",");
    const _registerRewardSetting = {
      ...setting,
      value_text: _registerRewardIdText,
    };

    return requestPost({
      fullUrl: `/api/setting/save`,
      body: _registerRewardSetting,
      onSuccess: ({ pagination, message, data }) => {
        setSetting(data);
        setRegisterRewards(rewards);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteRegisterReward = (product) => {
    const newRewards = registerRewards.filter(
      (reward) => reward.id !== product.id
    );
    saveRegisterReward(newRewards);
  };

  const handleClickAddRegisterReward = async (e, data) => {
    if (data && data.length > 0) {
      const product = data[0];

      const newRewards = [...registerRewards, product];
      const res = await saveRegisterReward(newRewards);
      if (res) {
        setShowRewardSelectorDialog(false);
      }
    }
  };

  const handleClickRemoveRegisterReward = (e, product) => {
    confirmDialog({
      message: `Are you sure you want to remove "${product.name}"?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        deleteRegisterReward(product);
      },
    });
  };

  return (
    <>
      <ProductSelectorDialog
        visible={showRewardSelectorDialog}
        onHide={() => setShowRewardSelectorDialog(false)}
        onConfirm={handleClickAddRegisterReward}
      />
      <div className="col-start-start w-[25rem] hover:bg-indigo-50 p-3">
        <h1 className="font-bold text-slate-600">Registration Reward</h1>
        <h2 className="font-semibold text-sm text-slate-600">
          Free reward for customer first time registration
        </h2>
        <div className="col-start-start w-full py-2">
          {registerRewards.map((reward, index) => (
            <div
              key={index}
              className="row-between-center gap-4 w-full px-2 py-1 hover:bg-indigo-100 cursor-default"
            >
              <span className="text-sm font-semibold text-slate-500">
                {reward.name}
              </span>
              <Button
                icon="pi pi-times"
                text
                rounded
                className="p-button-secondary !p-0 !w-[1.5rem] !h-[1.5rem]"
                pt={{
                  icon: { className: "!text-[0.7rem]" },
                }}
                onClick={(e) => handleClickRemoveRegisterReward(e, reward)}
              />
            </div>
          ))}
        </div>
        <Button
          className="!rounded-none !text-sm !h-[2.1rem]"
          loading={loading}
          label="Add Reward"
          icon="pi pi-plus"
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={() => setShowRewardSelectorDialog(true)}
        />
      </div>
    </>
  );
};
export default RegistrationRewardSetting;
