import { Button } from "primereact/button";

const ListDisplaySingle = (props) => {
  const { name, selected, onClick, width } = props;
  return (
    <div>
      <Button
        className={`leading-tight ${
          selected ? "" : "bg-slate-200 border-slate-400 text-slate-400"
        } w-[${width ?? "8"}rem] min-h-[4rem]`}
        label={name}
        onClick={onClick}
        raised={selected}
      ></Button>
    </div>
  );
};

export default ListDisplaySingle;
