import { Outlet } from "react-router-dom";
import NavBarComponent from "../../components/nav-bar/NavBarComponent";
import SideMenuComponent from "../../components/side-menu/SideMenuComponent";
import FloatingProfilePage from "./FloatingProfilePage";
import MenuAdmin from "./MenuAdmin";
import MenuBC from "./MenuBC";
import MenuCashier from "./MenuCashier";
import MenuSuperAdmin from "./MenuSuperAdmin";

import { useSelector, useDispatch } from "react-redux";
import classes from "./LayoutDashboardPage.module.css";
// import { process.env.REACT_APP_APP_NAME } from "../../configs/constants";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import {
  isAdmin,
  isBC,
  isCashier,
  isDoctor,
  isOwner,
  isSuperAdmin,
} from "../../utils/jelim/CheckUserType";

const title = process.env.REACT_APP_APP_NAME;
const logoUrl = `${process.env.REACT_APP_MY_URL}/logo128.png`;

const handleNotifClick = (e) => {
  console.log("New Notif Click");
};

const LayoutDashboardPage = () => {
  const employee = useSelector((state) => state.auth.employee);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [menu, setMenu] = useState([]);
  const sideMenuIsVisible = useSelector((state) => state.ui.sideMenuIsVisible);

  const isSmallScreen = window.innerWidth < 640;

  const handleAvatarClick = (e) => {
    setShowProfileDialog((state) => !state);
  };

  const MenuOwner = [];
  const MenuDoctor = [];

  useEffect(() => {
    const userType = employee ? employee.user_type : -1;
    if (isCashier(userType)) {
      setMenu(MenuCashier);
    } else if (isOwner(userType)) {
      setMenu(MenuOwner);
    } else if (isDoctor(userType)) {
      setMenu(MenuDoctor);
    } else if (isBC(userType)) {
      setMenu(MenuBC);
    } else if (isAdmin(userType)) {
      setMenu(MenuAdmin);
    } else if (isSuperAdmin(userType)) {
      setMenu(MenuSuperAdmin);
    }
  }, [employee]);

  return (
    <>
      <div className="row-start-start w-100">
        <AnimatePresence>
          {sideMenuIsVisible && (
            <SideMenuComponent menus={menu} title={title} logoUrl={logoUrl} />
          )}
        </AnimatePresence>
        <Dialog
          className="mt-6"
          showHeader={false}
          contentStyle={{ padding: "0", borderRadius: "0.5rem" }}
          position={"top-right"}
          visible={showProfileDialog}
          modal={false}
          style={{
            width: "50vw",
            marginTop: "calc(var(--height-nav-bar) + 12px )",
          }}
          draggable={false}
          resizable={false}
          onHide={() => setShowProfileDialog(false)}
        >
          <FloatingProfilePage hidden={false} />
        </Dialog>
        <motion.div
          initial={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? 0 : "var(--width-side-menu)",
                  width: "calc(100% - var(--width-side-menu))",
                }
          }
          animate={
            isSmallScreen
              ? {}
              : {
                  marginLeft: sideMenuIsVisible ? "var(--width-side-menu)" : 0,
                  width: sideMenuIsVisible
                    ? "calc(100% - var(--width-side-menu))"
                    : "100%",
                }
          }
          className={`col-start-start w-100 `}
        >
          <NavBarComponent
            className="w-100"
            showNotif={true}
            onCLickAvatar={handleAvatarClick}
            onClickNotif={handleNotifClick}
            avatarSrc={employee ? employee.image : ""}
          />
          <main className={`row-start-start ${classes.mainContainer} w-full`}>
            <Outlet />
          </main>
        </motion.div>
      </div>
    </>
  );
};

export default LayoutDashboardPage;
