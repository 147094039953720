import classes from "./MenuSeparator.module.css";

const MenuSeparator = (props, { className }) => {
  return (
    <div className={`${classes.wrapper} ${className} row-start-center w-100`}>
      <div className={`${classes.line} w-100`} ></div>
    </div>
  );
};

export default MenuSeparator;
