import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import UiContext from "../../../../store/ui-context";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../../store/ui-slice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { format as dateFnsFormat } from "date-fns";
import CustomerFollowUpNoteDialog from "./CustomerFollowUpNoteDialog";
import { Paginator } from "primereact/paginator";
// import BirthdayNoteDialog from "./BirthdayNoteDialog";

const defaultDate = new Date();
defaultDate.setDate(1);

const title = "Customer Follow Up";
const additionalParams = {
  with_customer: 1,
};
const order = "date";
const order_method = "ASC";

const CustomerFollowUpPage = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    take: 10,
    filter: "",
    total: 0,
  });

  const [listData, setListData] = useState([]);
  const [listBC, setListBC] = useState([]);
  const [listLevel, setListLevel] = useState([]);

  const [selectedCustomerFollowUp, setSelectedCustomerFollowUp] =
    useState(null);

  const [showCustomerNoteDialog, setShowCustomerNoteDialog] = useState(false);

  const firstDateOfMonth = new Date();
  firstDateOfMonth.setDate(1);
  const lastDateOfMonth = new Date();
  lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
  lastDateOfMonth.setDate(0);

  const [selectedDate, setDate] = useState(new Date());
  const [selectedBC, setSelectedBC] = useState(null);

  const { requestGet, requestPost, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    reloadDataBC();
    reloadDataLevel();
  }, []);

  const generateFilter = () => {
    let filter = "";

    if (selectedBC && selectedBC.id > 0) {
      if (filter.length > 0) filter += ";";
      filter += `data_customer.bc_id:=:${selectedBC.id}`;
    }

    if (selectedDate) {
      if (filter.length > 0) filter += ";";
      filter += `month:=:${
        selectedDate.getMonth() + 1
      };year:=:${selectedDate.getFullYear()}`;
    }

    return filter;
  };

  const reloadDataBC = async () => {
    setListBC([]);
    const params = {
      page: 1,
      take: 99,
      order: "name",
      order_method: "ASC",
      filter: "user_type:=:3;retire_date:=:Null",
    };

    return requestGet({
      fullUrl: "api/masterdata/employee/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const defaultBCOptions = { id: 0, name: "* All BC *" };
        setListBC([defaultBCOptions, ...data]);
        setSelectedBC(defaultBCOptions);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const reloadDataLevel = async () => {
    setListLevel([]);
    const params = {
      page: 1,
      take: 99,
      order: "level",
      order_method: "ASC",
    };

    return requestGet({
      fullUrl: "api/crm/crmlevel/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        setListLevel(data);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const reloadData = async ({ page, take }) => {
    if (loading) return;

    setListData([]);

    const filter = generateFilter();

    const params = {
      page: page,
      take: take,
      filter,
      ...additionalParams,
      order,
      order_method,
    };

    return requestGet({
      fullUrl: "api/customer/customerfollowup/data",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        const mapIdToBC = {};
        listBC.forEach((item) => {
          mapIdToBC[item.id] = item;
        });

        const mapIdToLevel = {};
        listLevel.forEach((item) => {
          mapIdToLevel[item.id] = item;
        });

        data.forEach((item, index) => {
          const newItem = { ...item };

          newItem["no"] = startNumber + index + 1;
          newItem["code"] = item.data_customer ? item.data_customer.code : "-";
          newItem["name"] = item.data_customer ? item.data_customer.name : "-";
          const bcId = item.data_customer ? item.data_customer.bc_id : null;
          newItem["bc_name"] = mapIdToBC[bcId] ? mapIdToBC[bcId].name : "-";
          newItem["level_name"] = mapIdToLevel[item.data_customer.crm_level_id]
            ? mapIdToLevel[item.data_customer.crm_level_id].name
            : "-";
          newItem["level_id"] = item.data_customer
            ? item.data_customer.crm_level_id
            : 0;

          let lastTransactionDateTxt = "-";
          if (item.last_transaction_date) {
            lastTransactionDateTxt = dateFnsFormat(
              new Date(item.last_transaction_date),
              "dd/MM/yyyy"
            );
          }
          newItem["last_transaction"] = lastTransactionDateTxt;
          newItem["description"] = item.description;

          newList.push(newItem);
        });

        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
        });
        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake });
  };

  const handleChangeFollowUpNote = (note) => {
    if (loading) return;

    const body = { ...selectedCustomerFollowUp, description: note };
    requestPost({
      fullUrl: "api/customer/customerfollowup/save",
      body,
      onSuccess: ({ message, data }) => {
        uiContext.showSuccessMsg("Success", message);
        const newList = [...listData];
        const index = newList.findIndex(
          (el) => el.id === selectedCustomerFollowUp.id
        );
        if (index > -1) {
          newList[index] = { ...newList[index], ...data };
          setListData(newList);
        }
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
    setShowCustomerNoteDialog(false);
  };

  const handleReload = () => {
    reloadData({ page: 1, take: 20 });
  };

  const handleClickCustomerDetail = (row) => {
    const url = `${process.env.REACT_APP_MY_URL}/transaction/customerdata/${row.customer_id}`;
    window.open(url, "_blank").focus();
  };

  const handleClickCustomerNote = (row) => {
    setSelectedCustomerFollowUp(row);
    setShowCustomerNoteDialog(true);
  };

  const calculateCustomerCount = (birthday) => {
    return listData.filter((el) => el.birthday === birthday).length;
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="row-center-center gap-2">
        <Button
          icon="pi pi-ellipsis-v"
          className="max-h-[1.7rem] max-w-[1.7rem] p-0"
          outlined
          rounded
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClickCustomerDetail(row);
          }}
        />
        <Button
          icon="pi pi-pencil"
          className="max-h-[1.7rem] max-w-[1.7rem] p-0"
          outlined={row.description ? false : true}
          rounded
          pt={{
            icon: { className: "!text-[0.7rem]" },
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClickCustomerNote(row);
          }}
        />
      </div>
    );
  };

  const customerBodyTemplate = (row) => {
    const { code, name, level_name, level_id } = row;
    let levelColor = "";
    switch (level_id) {
      case 1:
        levelColor = "text-emerald-600";
        break;
      case 2:
        levelColor = "text-zinc-400";
        break;
      case 3:
        levelColor = "text-orange-500";
        break;
    }
    return (
      <div className="col-start-start gap-0 ">
        <div className="row-start-center gap-3">
          <span className="text-xs font-bold text-slate-400 tracking-wider">
            {code}
          </span>
          <span className={`text-xs font-bold ${levelColor} tracking-wider`}>
            {level_name.toUpperCase()}
          </span>
        </div>
        <span>{name}</span>
      </div>
    );
  };

  const descriptionBodyTemplate = (row) => {
    const { edit_by, description, updated_at } = row;
    return (
      <div className="col-start-start gap-0 ">
        <span>{description}</span>
        {edit_by && updated_at && (
          <span className="text-xs text-slate-400 tracking-wide italic">
            by {edit_by.toUpperCase()} at{" "}
            {dateFnsFormat(new Date(updated_at), "yyyy/MM/dd HH:mm")}
          </span>
        )}
      </div>
    );
  };

  const spendingBodyTemplate = (row) => {
    return row.grand_total.toLocaleString("id-ID");
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <CustomerFollowUpNoteDialog
        visible={showCustomerNoteDialog}
        loading={loading}
        data={selectedCustomerFollowUp}
        onHide={() => {
          setShowCustomerNoteDialog(false);
        }}
        onConfirm={handleChangeFollowUpNote}
      />
      <Toast ref={toast} />
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center gap-4">
          <Calendar
            id="selectedDate"
            disabled={loading}
            value={selectedDate}
            showButtonBar
            showIcon
            view="month"
            dateFormat="mm/yy"
            onChange={(e) => {
              setDate(e.value);
            }}
            className={`dateInput w-[9rem]`}
            locale="id"
          />
          <Dropdown
            className="w-[12rem]"
            loading={loading}
            value={selectedBC}
            onChange={(e) => setSelectedBC(e.value)}
            options={listBC}
            optionLabel="name"
            placeholder="Select BC"
          />{" "}
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <DataTable
            value={listData}
            className="w-full"
            showGridlines
            stripedRows
            cellClassName="!py-2"
            // paginator
            // rows={20}
            // rowsPerPageOptions={[
            //   5, 10, 20, 30, 50, 100, 200, 500, 1000, 2000, 3000,
            // ]}
          >
            <Column field="no" sortable header="No"></Column>
            <Column field="#" header="" body={actionBodyTemplate}></Column>
            <Column field="bc_name" sortable header="BC"></Column>
            <Column
              field="code"
              header="Customer"
              body={customerBodyTemplate}
            ></Column>
            {/* <Column field="code" sortable header="Code"></Column>
            <Column field="name" sortable header="Name"></Column> */}
            {/* <Column field="level_name" sortable header="Level"></Column> */}
            <Column
              field="last_transaction"
              sortable
              header="Last Visit"
            ></Column>
            <Column
              className="text-right"
              field="grand_total"
              sortable
              body={spendingBodyTemplate}
              align="right"
              header="Last 3 Months"
            ></Column>
            <Column
              field="description"
              header="Description"
              body={descriptionBodyTemplate}
            ></Column>
          </DataTable>
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default CustomerFollowUpPage;
