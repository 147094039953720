const OutstandingSalesSingle = (props) => {
  const {
    transaction_date,
    number,
    point,
    subtotal,
    discount_total,
    grandtotal,
    data_customer,
    selected,
    onClick,
  } = props;
  return (
    <div
      className={`col-start-start rounded-md pl-4 pr-2 py-2 cursor-pointer min-w-[13rem] ${
        selected
          ? "bg-indigo-500 hover:bg-indigo-600"
          : "bg-slate-100 hover:bg-slate-200"
      }`}
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {/* <span className="text-xs font-semibold">{number}</span> */}
      <span className={`font-semibold ${selected ? "text-white" : ""}`}>
        {data_customer.name}
      </span>
      <div className="row-start-center gap-5 pt-3">
        <div className="row-start-center gap-2">
          <i
            className={`pi pi-star ${
              selected ? "text-white" : "text-zinc-400"
            }  text-xs`}
          />
          <span
            className={`text-xs font-semibold ${
              selected ? "text-white" : "text-zinc-400"
            }`}
          >
            {data_customer.code}
          </span>
        </div>
        <div className="row-start-center gap-2">
          <i
            className={`pi pi-user ${
              selected ? "text-white" : "text-zinc-400"
            } text-xs`}
          />
          <span
            className={`text-xs font-semibold ${
              selected ? "text-white" : "text-zinc-400"
            }`}
          >
            {data_customer && data_customer.data_bc
              ? data_customer.data_bc.name
              : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OutstandingSalesSingle;
