import { Button } from "primereact/button";
import {
  FaAt,
  FaCakeCandles,
  FaHashtag,
  FaPhone,
  FaRoad,
  FaStar,
  FaUserTie,
} from "react-icons/fa6";
import { useContext, useState } from "react";
import CustomerSelectorDialog from "../../../../../dialogs/selectors/CustomerSelectorDialog";
import NewCustomerDialog from "../../../../../dialogs/NewCustomerDialog";
import CustomerSalesHistoryDialog from "../../../../../dialogs/CustomerSalesHistoryDialog";
import CustomerPointHistoryDialog from "../../../../../dialogs/CustomerPointHistoryDialog";
import CustomerPackageHistoryDialog from "../../../../../dialogs/CustomerPackageHistoryDialog";
import UiContext from "../../../../../store/ui-context";

const CustomerSection = (props) => {
  const {
    sales,
    width,
    data = {},
    onSaveNewCustomer,
    onChangeCustomer,
    selected,
    setSelected,
    loading,
  } = props;
  const {
    name = "-",
    code = "",
    address = "",
    phone_1 = "",
    email = "",
    birthdate = "",
    data_bc,
    membership = "",
    point = 0,
  } = data;

  const uiContext = useContext(UiContext);
  const [expanded, setExpanded] = useState(false);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [showNewCustomerDialog, setShowNewCustomerDialog] = useState(false);
  const [showCustomerSalesHistoryDialog, setShowCustomerSalesHistoryDialog] =
    useState(false);
  const [showCustomerPointHistoryDialog, setShowCustomerPointHistoryDialog] =
    useState(false);
  const [
    showCustomerPackageHistoryDialog,
    setShowCustomerPackageHistoryDialog,
  ] = useState(false);

  const handleChangeExpanded = () => {
    setExpanded(!expanded);
  };

  const handleShowCustomerSelector = () => {
    setShowCustomerSelector(true);
  };

  const handleSaveNewCustomer = async (data) => {
    const res = await onSaveNewCustomer(data);
    if (res) {
      setShowNewCustomerDialog(false);
    }
  };

  const handleShowNewCustomer = () => {
    setShowNewCustomerDialog(true);
  };

  const handleRemoveCustomer = () => {
    onChangeCustomer(null);
  };

  const handleShowCustomerSalesHistory = () => {
    if (sales.customer_id) {
      setShowCustomerSalesHistoryDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleShowCustomerPackageHistory = () => {
    if (sales.customer_id) {
      setShowCustomerPackageHistoryDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleShowCustomerPointHistory = () => {
    if (sales.customer_id) {
      setShowCustomerPointHistoryDialog(true);
    } else {
      uiContext.showErrorMsg("Error", "Please select a customer first");
    }
  };

  const handleChangeCustomer = (event, refinedValue) => {
    onChangeCustomer(refinedValue[0]);
    setShowCustomerSelector(false);
  };

  return (
    <div className={`row-between-center ${width} min-h-[5rem] gap-3`}>
      <CustomerSelectorDialog
        visible={showCustomerSelector}
        onHide={() => setShowCustomerSelector(false)}
        onConfirm={handleChangeCustomer}
      />
      <NewCustomerDialog
        visible={showNewCustomerDialog}
        onSave={handleSaveNewCustomer}
        loading={loading}
        onHide={() => setShowNewCustomerDialog(false)}
      />
      <CustomerSalesHistoryDialog
        visible={showCustomerSalesHistoryDialog}
        data={data}
        onHide={() => setShowCustomerSalesHistoryDialog(false)}
      />
      <CustomerPointHistoryDialog
        visible={showCustomerPointHistoryDialog}
        data={data}
        onHide={() => setShowCustomerPointHistoryDialog(false)}
      />
      <CustomerPackageHistoryDialog
        visible={showCustomerPackageHistoryDialog}
        data={data}
        onHide={() => setShowCustomerPackageHistoryDialog(false)}
      />

      <div className={`col-start-start gap-2`}>
        <div className={`row-start-center w-100 font-semibold`}>{name}</div>
        <div className={`row-start-center w-100 gap-2`}>
          <Button
            className="max-w-[1.6rem] max-h-[1.6rem]"
            icon="pi pi-history"
            rounded
            outlined
            onClick={(e) => {
              e.preventDefault();
              handleShowCustomerSalesHistory();
            }}
            loading={loading}
          />
          <Button
            className="max-h-[1.6rem] text-sm"
            label={`${point.toLocaleString("id-ID")} Point`}
            style={{ fontSize: "0.1rem !important" }}
            onClick={(e) => {
              e.preventDefault();
              handleShowCustomerPointHistory();
            }}
            loading={loading}
          />
          <Button
            className="max-h-[1.6rem] text-sm"
            label={`Package `}
            style={{ fontSize: "0.1rem !important" }}
            onClick={(e) => {
              e.preventDefault();
              handleShowCustomerPackageHistory();
            }}
            loading={loading}
          />
        </div>
        {expanded && (
          <div className={`col-start-start gap-2`}>
            <div className={`row-start-center w-100 gap-6`}>
              <div className={`col-start-start w-100 gap-2`}>
                {/* code */}
                <div className={`row-start-center w-100 gap-2`}>
                  <FaHashtag className="text-zinc-400 text-sm w-[1.5rem]" />
                  <span className="text-zinc-400 text-sm font-semibold">
                    {code ?? "-"}
                  </span>
                </div>
                {/* membership */}
                <div className={`row-start-center w-100 gap-2`}>
                  <FaStar className="text-zinc-400 text-sm w-[1.5rem]" />
                  <span className="text-zinc-400 text-sm font-semibold">
                    {membership ?? "-"}
                  </span>
                </div>
                {/* bc */}
                <div className={`row-start-center w-100 gap-2`}>
                  <FaUserTie className="text-zinc-400 text-sm w-[1.5rem]" />
                  <span className="text-zinc-400 text-sm font-semibold">
                    {data_bc ? data_bc.name : "-"}
                  </span>
                </div>
              </div>
              <div className={`col-start-start w-100 gap-2`}>
                {/* phone */}
                <div className={`row-start-center w-100 gap-2`}>
                  <FaPhone className="text-zinc-400 text-sm w-[1.5rem]" />
                  <span className="text-zinc-400 text-sm font-semibold">
                    {phone_1 ?? "-"}
                  </span>
                </div>
                {/* birthdate */}
                <div className={`row-start-center w-100 gap-2`}>
                  <FaCakeCandles className="text-zinc-400 text-sm w-[1.5rem]" />
                  <span className="text-zinc-400 text-sm font-semibold">
                    {birthdate ?? "-"}
                  </span>
                </div>
              </div>
            </div>
            {/* email */}
            <div className={`row-start-center w-100 gap-2`}>
              <FaAt className="text-zinc-400 text-sm w-[1.5rem]" />
              <span className="text-zinc-400 text-sm font-semibold">
                {email ?? "-"}
              </span>
            </div>
            {/* address */}
            <div className={`row-start-center w-100 gap-2`}>
              <FaRoad className="text-zinc-400 text-sm w-[1.5rem]" />
              <span className="text-zinc-400 text-sm font-semibold">
                {address ?? "-"}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={`col-center-center gap-1`}>
        {expanded && (
          <Button
            className="max-w-[2rem] max-h-[2rem]"
            icon="pi pi-plus"
            onClick={(e) => {
              e.preventDefault();
              handleShowNewCustomer();
            }}
            rounded
            outlined
          />
        )}
        <Button
          className="max-w-[2rem] max-h-[2rem]"
          icon="pi pi-search"
          onClick={(e) => {
            e.preventDefault();
            handleShowCustomerSelector();
          }}
          rounded
          outlined
          loading={loading}
        />
        {expanded && (
          <Button
            className="max-w-[2rem] max-h-[2rem]"
            icon="pi pi-times"
            severity="danger"
            rounded
            outlined
            onClick={(e) => {
              e.preventDefault();
              handleRemoveCustomer();
            }}
            loading={loading}
          />
        )}
        <Button
          className={`max-w-[2rem] max-h-[2rem] ${expanded ? `mt-2` : ``}`}
          icon={`pi ${expanded ? `pi-chevron-up` : `pi-chevron-down`}`}
          rounded
          outlined
          onClick={(e) => {
            e.preventDefault();
            handleChangeExpanded();
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CustomerSection;
