import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaQrcode } from "react-icons/fa6";
import { add, format as dateFnsFormat, parse } from "date-fns";
import UiContext from "../../../../store/ui-context";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import ProductSelectorDialog from "../../../../dialogs/selectors/ProductSelectorDialog";
import { da } from "date-fns/locale";

const BirthdayNoteDialog = (props) => {
  const uiContext = useContext(UiContext);
  const { visible, data, onHide, onConfirm, loading } = props;

  const [title, setTitle] = useState("Birthday Note");

  const [note, setNote] = useState("");

  useEffect(() => {
    if (visible) {
      setNote(data && data.description ? data.description : "");
    }
  }, [visible]);

  const handleOnConfirm = () => {
    // validate input data
    if (!note) {
      uiContext.showErrorMsg("Error", "Note must be filled");
      return;
    }
    if (onConfirm) onConfirm(note);
  };

  const dialogFooter = (
    <React.Fragment>
      <div className="row-end-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          outlined
          onClick={props.onHide}
        />
        <Button
          label="Save"
          icon="pi pi-save"
          loading={loading}
          onClick={handleOnConfirm}
        />
      </div>
    </React.Fragment>
  );

  return (
    <Dialog
      visible={visible}
      style={{ width: "30rem" }}
      header={title}
      modal
      footer={dialogFooter}
      onHide={onHide}
    >
      <div className="col-start-center relative h-100">
        <div className="col-start-start w-100 gap-4 pt-2">
          <div className={`col-start-start w-full gap-1`}>
            <label
              htmlFor={"selectedDate"}
              className="font-bold text-sm text-zinc-500"
            >
              Note
            </label>
            <InputText
              className="w-full"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>{" "}
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default BirthdayNoteDialog;
