import { subDays, addDays } from "date-fns";

const ProductConfigs = {
  master: {
    title: "Product",
    apiName: "masterdata/product",
    orderBy: "id",
    orderMethod: "ASC",
    additionalParams: {
      // add_params
      with_product_display: 1,
      with_product_category: 1,
    },
  },
  dialog: {
    title: "Product",
    isForm: false,
  },

  columns: [
    // add_configs
    {
      name: "Product Display",
      field: "product_display_id",
      type: "object",
      object_name: "data_product_display",
      object_field: "name",
      editor: true,
      table: false,
      filter: true,
    },
    {
      name: "Product Category",
      field: "product_category_id",
      type: "object",
      object_name: "data_product_category",
      object_field: "name",
      editor: true,
      table: true,
      filter: true,
    },
    {
      name: "Code",
      field: "code",
      type: "text_plain",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Name",
      field: "name",
      type: "text_plain",
      table: true,
      filter: true,
      editor: true,
    },
    {
      name: "Price",
      field: "price",
      type: "number_plain",
      editor: true,
      align: "right",
      table: false,
    },
    {
      name: "Product Type",
      field: "product_type",
      table: true,
      filter: true,
      editor: true,
      type: "number_dropdown",
      align: "center",
      default_value: 1,
      options: [
        { name: "Service", value: 1 },
        { name: "Product", value: 2 },
      ],
    },
    {
      name: "Point",
      field: "point",
      type: "number_plain",
      editor: true,
      align: "right",
      table: false,
    },
    {
      name: "Level",
      field: "level",
      table: false,
      filter: true,
      editor: true,
      type: "text_plain",
      align: "right",
    },
    {
      name: "Redeemable",
      field: "redeemable",
      align: "center",
      table: false,
      editor: true,
      type: "boolean_plain",
      filter: true,
    },
    {
      name: "Description",
      field: "description",
      type: "text_long",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Benefit",
      field: "benefit",
      type: "text_long",
      table: false,
      filter: true,
      editor: true,
    },
    {
      name: "Duration",
      field: "duration",
      type: "number_plain",
      editor: true,
      align: "right",
      table: false,
    },
    {
      name: "Packages",
      field: "data_product_packages",
      type: "list_object",
      columns: [
        {
          name: "Package Content",
          field: "child_id",
          type: "object",
          object_name: "data_child",
          object_field: "name",
          editable: false,
        },
        {
          name: "Quantity",
          field: "quantity",
          type: "number_plain",
          editable: true,
        },
        {
          name: "Expired Days",
          field: "expired_days",
          type: "number_plain",
          editable: true,
        },
      ],
      width: "15rem",
      editor: true,
    },
  ],
};

export default ProductConfigs;
