import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import TableAutoComponent from "../../../../components/complex/crud/TableAutoComponent";
import generateTableConfigs from "../../../../utils/TableConfigGenerator";
import { format } from "date-fns";

const firstDateOfMonth = new Date();
firstDateOfMonth.setDate(1);
firstDateOfMonth.setHours(0, 0, 0, 0);

const today = new Date();

const customColumns = [
  {
    field: "data_source_sales_item.data_sales.transaction_date",
    component: (rowData) => {
      let boughtDate;
      if (rowData && rowData.data_source_sales_item) {
        if (rowData.data_source_sales_item.data_sales) {
          boughtDate =
            rowData.data_source_sales_item.data_sales.transaction_date;
        } else {
          boughtDate = rowData.data_source_sales_item.created_at;
        }
      } else if (rowData && rowData.created_at) {
        boughtDate = rowData.created_at;
      }
      if (boughtDate) {
        return format(new Date(boughtDate), "dd/MM/yyyy");
      } else {
        return "-";
      }
    },
  },
];

const configs = [
  // add_configs
  {
    name: "Bought Date",
    field: "data_source_sales_item.data_sales.transaction_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Bought Invoice",
    field: "data_source_sales_item.data_sales.number",
    type: "text_plain",
    table: true,
  },
  {
    name: "Expired Date",
    field: "expired_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Product",
    field: "data_product.name",
    type: "text_plain",
    table: true,
    filter: true,
  },
  {
    name: "Quantity",
    field: "quantity",
    align: "right",
    type: "number_plain",
    table: true,
  },
  {
    name: "Usage Date",
    field: "usage_date",
    type: "date_date",
    table: true,
  },
  {
    name: "Usage Invoice",
    field: "data_usage_sales_item.data_sales.number",
    type: "text_plain",
    table: true,
  },
];

const tableConfigs = generateTableConfigs(configs);

const PackageHistory = (props) => {
  const { onLoad, data, loading } = props;

  const [selectedDate, setSelectedDate] = useState([firstDateOfMonth, today]);

  const handleOnClickLoad = (loadAll) => {
    let fromDate = selectedDate.length > 0 ? selectedDate[0] : null;
    let toDate = selectedDate.length > 1 ? selectedDate[1] : null;

    if (loadAll) onLoad({ fromDate: null, toDate: null });
    else onLoad({ fromDate, toDate });
  };

  return (
    <div className="col-start-start gap-4">
      <div className="row-start-end gap-2 w-full">
        <div className="col-start-start">
          <span className="font-semibold text-sm text-zinc-400">
            Transaction Date
          </span>
          <Calendar
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            showButtonBar
          />
        </div>
        <Button
          className="w-[6rem] min-h-[2.6rem]"
          icon="pi pi-refresh"
          label="Load"
          onClick={() => handleOnClickLoad(false)}
        />
        <Button
          className="w-[8rem] min-h-[2.6rem]"
          icon="pi pi-refresh"
          label="Load All"
          onClick={() => handleOnClickLoad(true)}
        />
      </div>
      <div className={`col-center-center w-100 gap-2`}>
        <TableAutoComponent
          singleMode={false}
          selection={[]}
          onSelectionChange={() => {}}
          configs={tableConfigs}
          loading={loading}
          value={data}
          customColumns={customColumns}
          // auto pagination
          rows={10}
          paginator={true}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        />
      </div>
    </div>
  );
};

export default PackageHistory;
