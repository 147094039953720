import {
  EditorElementTextPlain,
  EditorElementTextLong,
  EditorElementTextPassword,
  EditorElementTextDropDown,
  EditorElementTextRadioButton,
  EditorElementNumberPlain,
  EditorElementNumberDropDown,
  EditorElementNumberRadiobutton,
  EditorElementBooleanPlain,
  EditorElementDate,
  EditorElementObject,
  EditorElementObjectDropdownLocal,
  EditorElementObjectDropdownAPI,
  EditorElementList,
  EditorElementListObject,
  EditorElementImage,
  EditorElementZirvuFileManager,
  EditorElementDateRange,
} from "../components/complex/crud/EditorElementComponent";

const generateEditorElement = ({
  config,
  value,
  error,
  handleInputChange,
  handleMultipleInputChange,
  autoFocus,
}) => {
  const propMap = {
    config,
    value,
    error,
    handleInputChange,
    handleMultipleInputChange,
    autoFocus,
  };
  let newElement;

  switch (config.type) {
    case "text_plain":
      newElement = <EditorElementTextPlain {...propMap} />;
      break;
    case "text_long":
      newElement = <EditorElementTextLong {...propMap} />;
      break;
    case "text_password":
      newElement = <EditorElementTextPassword {...propMap} />;
      break;
    case "text_dropdown":
      newElement = <EditorElementTextDropDown {...propMap} />;
      break;
    case "text_radiobutton":
      newElement = <EditorElementTextRadioButton {...propMap} />;
      break;
    case "number_plain":
      newElement = <EditorElementNumberPlain {...propMap} />;
      break;
    case "number_dropdown":
      newElement = <EditorElementNumberDropDown {...propMap} />;
      break;
    case "number_radiobutton":
      newElement = <EditorElementNumberRadiobutton {...propMap} />;
      break;
    case "boolean_plain":
      newElement = <EditorElementBooleanPlain {...propMap} />;
      break;
    case "date":
      newElement = <EditorElementDate {...propMap} />;
      break;
    case "date_range":
      newElement = <EditorElementDateRange {...propMap} />;
      break;
    case "date_time":
      propMap.config.time = true;
      newElement = <EditorElementDate {...propMap} />;
      break;
    case "date_month":
      propMap.config.month = true;
      newElement = <EditorElementDate {...propMap} />;
      break;
    case "date_year":
      propMap.config.year = true;
      newElement = <EditorElementDate {...propMap} />;
      break;
    case "object":
      newElement = <EditorElementObject {...propMap} />;
      break;
    case "object_dropdown_local":
      newElement = <EditorElementObjectDropdownLocal {...propMap} />;
      break;
    case "object_dropdown_api":
      newElement = <EditorElementObjectDropdownAPI {...propMap} />;
      break;
    case "list":
      newElement = <EditorElementList {...propMap} />;
      break;
    case "list_object":
      newElement = <EditorElementListObject {...propMap} />;
      break;
    case "image":
      newElement = <EditorElementImage {...propMap} />;
      break;
    case "zirvu_file_manager":
      newElement = <EditorElementZirvuFileManager {...propMap} />;
      break;
  }

  return newElement;
};

export default generateEditorElement;
