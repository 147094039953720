import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import ListSalesDetailSingle from "../sales/sales-components/ListSalesDetailSingle";
import { Divider } from "primereact/divider";

const title = "Sales Preview";

const SalesPreviewDialog = (props) => {
  const { data, onClickChangeDate } = props;
  const {
    transaction_date,
    number,
    data_customer,
    sub_total,
    data_sales_discounts,
    grand_total,
    data_sales_payments,
    data_sales_items,
    notes,
  } = data;

  let customerCode = data_customer ? data_customer.code ?? "" : "";
  let customerName = data_customer ? data_customer.name ?? "" : "";
  let customerBC =
    data_customer && data_customer.data_bc
      ? data_customer.data_bc.name ?? ""
      : "";
  let customerPhone1 = data_customer ? data_customer.phone_1 ?? "" : "";
  let customerPhone2 = data_customer ? data_customer.phone_2 ?? "" : "";
  let customerMembership = data_customer
    ? data_customer.crm_level_id ?? ""
    : "";
  let customerEmail = data_customer ? data_customer.email ?? "" : "";
  let customerBirthday = data_customer ? data_customer.birthday ?? "" : "";
  let customerAddress = data_customer ? data_customer.address ?? "" : "";

  useEffect(() => {
    if (props.visible) {
    }
  }, [props.visible]);

  const Section = (props) => {
    const { title } = props;
    return (
      <div className="col-start-start bg-slate-100 p-3 rounded-md gap-1 w-full">
        <span className="text-sm text-left text-slate-600 font-bold pb-1 underline w-full">
          {title}
        </span>
        {props.children}
      </div>
    );
  };

  const SectionDetail = (props) => {
    const { prompt, value, isTotal, valueColor, valueSize, grow, onClick } =
      props;

    return (
      <div
        className={`${
          isTotal ? "row-between-center" : "row-start-center"
        }  gap-3 w-full`}
        onDoubleClick={() => {
          if (onClick) onClick();
        }}
      >
        <span
          className={`text-sm text-slate-400 font-semibold ${
            isTotal ? "" : "text-right"
          } min-w-[5rem] ${grow ? "" : "w-[5rem]"} `}
        >
          {prompt}
        </span>
        <span
          className={`font-semibold max-w-[17rem] ${valueColor ?? ""} ${
            valueSize ?? ""
          }`}
        >
          {value}
        </span>
      </div>
    );
  };

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[48.5rem]`}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="row-start-start w-full gap-3 pb-6">
        <div className="col-start-start gap-4">
          <Section title="SALES">
            <SectionDetail prompt={"Number:"} value={number} />
            <SectionDetail
              prompt={"Time:"}
              value={transaction_date}
              onClick={() => onClickChangeDate()}
            />
          </Section>
          {data_customer && (
            <Section title="CUSTOMER">
              <SectionDetail prompt={"Code:"} value={customerCode} />
              <SectionDetail prompt={"Name:"} value={customerName} />
              <SectionDetail prompt={"BC:"} value={customerBC} />
              <SectionDetail prompt={"Phone 1:"} value={customerPhone1} />
              <SectionDetail prompt={"Phone 2:"} value={customerPhone2} />
              <SectionDetail prompt={"Birthday:"} value={customerBirthday} />
              <SectionDetail prompt={"Member:"} value={customerMembership} />
              <SectionDetail prompt={"Email:"} value={customerEmail} />
              <SectionDetail prompt={"Address:"} value={customerAddress} />
            </Section>
          )}
          <Section title="NOTE">
            <span className={`font-semibold max-w-[17rem] `}>
              {" "}
              {notes ?? "-"}{" "}
            </span>
          </Section>
        </div>
        <div className="col-start-start gap-3">
          <Section title="SALES DETAIL">
            {data_sales_items &&
              data_sales_items.map((salesItem, index) => {
                return (
                  <div key={salesItem.id} className="col-start-start w-full">
                    <ListSalesDetailSingle
                      className=" min-w-[25rem]"
                      key={salesItem.id}
                      {...salesItem}
                      editable={false}
                    />
                    <Divider className="w-full !m-0" />
                  </div>
                );
              })}
          </Section>
          <Section title="SUMMARY">
            <SectionDetail
              isTotal={true}
              prompt={"Subtotal:"}
              value={(sub_total ?? 0).toLocaleString("id-ID")}
            />
            <div className="col-start-start w-full pl-4">
              {data_sales_discounts &&
                data_sales_discounts.map((discount, index) => (
                  <SectionDetail
                    key={index}
                    grow
                    isTotal={true}
                    prompt={discount.name}
                    value={(discount.value * -1).toLocaleString("id-ID")}
                    valueColor={"text-red-400"}
                    valueSize={"text-sm"}
                  />
                ))}
            </div>
            <SectionDetail
              isTotal={true}
              grow
              prompt={"Grandtotal:"}
              value={(grand_total ?? 0).toLocaleString("id-ID")}
              valueSize={"text-lg"}
            />
            <div className="col-start-start w-full pl-4">
              {data_sales_payments &&
                data_sales_payments.map((payment, index) => (
                  <SectionDetail
                    key={index}
                    grow
                    isTotal={true}
                    prompt={payment.data_payment.name}
                    value={(payment.amount ?? 0).toLocaleString("id-ID")}
                    valueColor={"text-slate-400"}
                    valueSize={"text-sm"}
                  />
                ))}
            </div>
          </Section>
        </div>
      </div>
    </Dialog>
  );
};

export default SalesPreviewDialog;
