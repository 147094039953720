import classes from "./ExpenseEditorDialog.module.css";

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import useCrudEditorControl from "../../../../custom_hooks/complex/useCrudEditorControl";
// add_import_selector_dialog
import ExpenseGroupSelectorDialog from "../../../../dialogs/selectors/ExpenseGroupSelectorDialog";

import configs from "./ExpenseConfigs";

const title = configs.dialog.title;

const ExpenseEditorDialog = (props) => {
  const {
    generateSelectorDialog,
    handleInputChange,
    handleMultipleInputChange,
    elements,
    validator,
  } = useCrudEditorControl({
    value: props.value,
    setValue: props.setValue,
    configs: props.configs,
    error: props.error,
    customElements: props.customElements,
  });

  const convertValue = () => {
    return { ...props.value };
  };

  const validation = () => {
    return validator({
      configs: props.configs,
      value: props.value,
      onError: (error) => props.setError(error),
      isEmpty: "expense_group_id ",
    });
  };

  // add_generate_selector_dialog
  generateSelectorDialog({
    field: "expense_group_id",
    componentFunction: ({ visible, setVisible, onConfirm }) => {
      return (
        <ExpenseGroupSelectorDialog
          single={true}
          visible={visible}
          onHide={() => setVisible(false)}
          onConfirm={onConfirm}
        />
      );
    },
  });


  const dialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        outlined
        onClick={props.onHide}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={(e) => {
          const valid = validation();
          if (valid) {
            const newValue = convertValue();
            props.onSave(e, newValue);
          }
        }}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={title}
      modal
      className={`p-fluid`}
      footer={dialogFooter}
      onHide={props.onHide}
    >
      <div className="col-start-center relative h-100">
        <div className={`${classes.wrapper} row-start-start w-100`}>
          <div className="col-start-start w-100 gap-3">
            {/* {elements.example_fields} */}
            {/* add_fields */}
            {elements.expense_group_id}
            {elements.transaction_date}
            {elements.amount}
            {elements.description}
          </div>
          <div className="col-start-start"></div>
        </div>
        {props.loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ExpenseEditorDialog;
