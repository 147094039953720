import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import ListDisplaySingle from "./ListDisplaySingle";
import CustomDisplaySelector from "../../../../../dialogs/CustomDisplaySelectorDialog";

const manualScrollAmount = 25;

const ListDisplaySection = (props) => {
  const { data, onChange, loading } = props;

  const [scrollIntervalId, setScrollIntervalId] = useState(null);
  const scrollableRef = useRef(null);
  const [selectedData, setSelectedData] = useState(null);
  const [showCustomDisplay, setShowCustomDisplay] = useState(false);

  useEffect(() => {
    onChange(selectedData);
  }, [selectedData]);

  const scroll = (amount) => {
    scrollableRef.current.scrollLeft += amount;
  };

  const startScroll = (amount) => {
    setScrollIntervalId(setInterval(() => scroll(amount), 100));
  };

  const stopScroll = () => {
    clearInterval(scrollIntervalId);
    setScrollIntervalId(null);
  };

  const handleScrollRight = () => {
    startScroll(manualScrollAmount);
  };

  const handleScrollLeft = () => {
    startScroll(-manualScrollAmount);
  };

  const handleMouseWheel = (event) => {
    scroll(-event.deltaY / 2);
  };

  return (
    <div className="col-between-center w-full gap-5">
      <CustomDisplaySelector
        visible={showCustomDisplay}
        data={data}
        onHide={(e) => {
          setShowCustomDisplay(false);
        }}
        onConfirm={(display) => {
          setSelectedData(display);
          setShowCustomDisplay(false);
        }}
      />
      <div
        className={`row-between-center w-full gap-1`}
        onWheel={(event) => handleMouseWheel(event)}
      >
        <Button
          className="border-slate-200 min-h-[4.5rem] max-w-[2rem] "
          icon="pi pi-chevron-left"
          outlined
          onMouseDown={() => handleScrollLeft()}
          onMouseUp={() => stopScroll()}
          loading={loading}
        />
        <div
          ref={scrollableRef}
          className="horizontal-scrollbar-wrapper row-start-center flex-grow overflow-x-auto w-[10rem] pt-1 pb-2 gap-3 bg-slate-50 px-2 rounded-md"
        >
          {data &&
            data.length > 0 &&
            data.map((display, index) => {
              return (
                <ListDisplaySingle
                  key={display.id}
                  {...display}
                  selected={selectedData && selectedData.id === display.id}
                  onClick={(event) => {
                    setSelectedData(display);
                  }}
                />
              );
            })}
        </div>
        <Button
          className="border-slate-200 min-h-[4.5rem] max-w-[2rem] "
          icon="pi pi-chevron-right"
          outlined
          onMouseDown={() => handleScrollRight()}
          onMouseUp={() => stopScroll()}
          loading={loading}
        />
      </div>
      <div className="row-between-center w-full ">
        <Button
          className="max-h-[2.43rem]"
          icon="pi pi-caret-down"
          outlined
          label="More Display"
          loading={loading}
          onClick={(event) => {
            event.preventDefault();
            setShowCustomDisplay(true);
            // onFilter(filterText);
          }}
        />
        {props.children}
      </div>
    </div>
  );
};

export default ListDisplaySection;
